import { VariableTab, VariableData, VariableDataFields, VariableField } from '../../../models/ProcessInstance'
import { InstanceVariable, Variable } from '../../../models/Variables'
import { useVariables } from '../../settings'
import { useParams } from 'react-router-dom'
import { useProcessInstance } from '../process-instances-incidents.repository'

const useFormatVariableData = () => {
  const { org, type } = useParams()

  const { data: instanceRowData } = useProcessInstance()

  const instanceVariables: InstanceVariable[] = instanceRowData?.processInstance.variables

  const { data: variables = [], isLoading: loading } = useVariables({
    organization: org,
    key: type,
    variableOption: 'variables',
  })

  const sectionTabs: VariableTab[] = variables
    .filter((v) => v.itemType === 'ProcessVariableFieldSection')
    .map((variable: Variable) => {
      return {
        key: variable.id,
        label: variable.name,
      }
    })

  const areSectionsFieldsEmpty = variables
    .filter((v) => v.itemType === 'ProcessVariableFieldSection')
    .every((v) => !v.children?.length)

  const nonEmptySectionTabs = variables
    .filter((v) => v.itemType === 'ProcessVariableFieldSection' && v.children?.length)
    .map((variable: Variable) => {
      return {
        key: variable.id,
        label: variable.name,
      }
    })

  const hasVariablesFields = variables.some((v) => v.itemType === 'ProcessVariableField')

  const appendOtherTab = (sectionTabs: VariableTab[]): VariableTab[] => {
    if (areSectionsFieldsEmpty) {
      return [{ key: 'other', label: 'Other' }]
    }
    if (hasVariablesFields) {
      return [...nonEmptySectionTabs, { key: 'other', label: 'Other' }]
    } else {
      return sectionTabs
    }
  }

  const getVariableValue = (variable: InstanceVariable) => {
    switch (variable.type) {
      case 'File':
        return variable.value.fileName
      case 'Json':
        if (Array.isArray(variable.value) && typeof variable.value[0] === 'string') return variable.value.join(', ')
        // else if (Array.isArray(variable.value) && typeof variable.value[0] === 'object')
        else return JSON.stringify(variable.value, undefined, 4)
      // else return JSON.stringify(variable.value).toString()
      default:
        return variable.value
    }
  }

  const variableData: VariableData[] = appendOtherTab(sectionTabs).map((tab: VariableTab): VariableData => {
    return {
      tabKey: tab.key,
      data: variables
        .filter((variable) => variable.id === tab.key || 'other' === tab.key)
        .map((variable): VariableDataFields => {
          if (variable.itemType === 'ProcessVariableFieldSection' && 'other' !== tab.key) {
            const instanceVariableWithChildren: InstanceVariable | undefined = instanceVariables?.find(
              (v) => v.id === variable.id,
            )
            if (!instanceVariableWithChildren?.children?.length) {
              return {
                fields:
                  variable.children?.map((c): VariableField => {
                    return {
                      key: c.id,
                      label: c.name ?? '',
                      value: 'N/A',
                      type: c.type,
                      imageUrl: c.name,
                    }
                  }) ?? [],
              }
            } else {
              return {
                fields:
                  instanceVariableWithChildren?.children?.map((c): VariableField => {
                    return {
                      key: c.id,
                      label: c.name ?? '',
                      type: c.type,
                      value: getVariableValue(c) ?? 'N/A',
                      imageUrl: c.name,
                    }
                  }) ?? [],
              }
            }
          } else if (variable.itemType === 'ProcessVariableField' && 'other' === tab.key) {
            const instanceVariable: InstanceVariable | undefined = instanceVariables?.find((v) => v.id === variable.id)
            return {
              fields: [
                {
                  key: variable.id,
                  label: variable.name,
                  value: instanceVariable && instanceVariable.value ? getVariableValue(instanceVariable) : 'N/A',
                  type: variable.type,
                  imageUrl: variable.name,
                },
              ],
            }
          } else {
            return {
              fields: [],
            }
          }
        }),
    }
  })

  const variableTabs = appendOtherTab(sectionTabs)

  return { variableTabs, variableData, loading }
}

export default useFormatVariableData
