import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ProcessInstancesService from '../../../services/ProcessInstances.service'

type ProcessInstanceEditPayload = {
  date: Date
}

export const useProcessInstanceUpdateDueDate = (processInstanceId: string) => {
  const queryClient = useQueryClient()
  const { org } = useParams()

  return useMutation<any, Error, ProcessInstanceEditPayload>({
    mutationKey: ['due-date', processInstanceId],
    mutationFn: ({ date }) =>
      ProcessInstancesService.setProcessInstanceDueDate(org!, processInstanceId, { dueDate: date }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['process-instances'] })
    },
  })
}
