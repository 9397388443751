import { Variable } from '../models/Variables'
import { axiosBoardInstance } from './http-common'

export enum VariableType {
  'variable',
  'section',
}

class VariablesService {
  getVariables = async (
    organization: string,
    key: string,
    sorted: boolean,
    option: string,
    type?: VariableType,
  ): Promise<Variable[]> => {
    const params = {}
    if (type) {
      params[type] = type === VariableType['section'] ? 'section' : 'variable'
    }
    if (sorted) {
      return (await axiosBoardInstance.get(`/${organization}/processes/key/${key}/${option}`, { params })).data.sort(
        (a, b) => a.position - b.position,
      )
    } else {
      return (await axiosBoardInstance.get(`/${organization}/processes/key/${key}/${option}`, { params })).data
    }
  }

  getAllProcessFields = async (organization: string, processDefinitionKey: string): Promise<any> => {
    return (await axiosBoardInstance.get(`/${organization}/processes/key/${processDefinitionKey}/columns`)).data
  }

  getByScope = async (organization: string, key: string, id: string, params: any): Promise<string[]> => {
    return (await axiosBoardInstance.get(`/${organization}/processes/key/${key}/version/${id}/variables`, { params }))
      .data
  }

  changeVariableVisibility = async (
    organization: string,
    key: string,
    variableId: string,
    body: { isVisible: boolean },
  ): Promise<any> => {
    return (
      await axiosBoardInstance.post(`/${organization}/processes/key/${key}/variables/${variableId}/visibility`, body)
    ).data
  }

  addVariables = async (
    organization: string,
    key: string,
    body: { sectionId: string | null; variables: any },
  ): Promise<any> => {
    return (await axiosBoardInstance.post(`/${organization}/processes/key/${key}/variables`, body)).data
  }

  addVariableSection = async (
    organization: string,
    key: string,
    body: { name: string; variables: any[] },
  ): Promise<any> => {
    return (await axiosBoardInstance.post(`/${organization}/processes/key/${key}/variables/sections`, body)).data
  }

  deleteVariableSection = async (organization, key, sectionId): Promise<any> => {
    return (await axiosBoardInstance.delete(`/${organization}/processes/key/${key}/variables/sections/${sectionId}`))
      .data
  }

  deleteVariable = async (organization, key, variableId): Promise<any> => {
    return (await axiosBoardInstance.delete(`/${organization}/processes/key/${key}/variables/${variableId}`)).data
  }

  changeVariableSectionName = async (organization, key, sectionId, body: { name: string }): Promise<any> => {
    return (await axiosBoardInstance.put(`/${organization}/processes/key/${key}/variables/sections/${sectionId}`, body))
      .data
  }

  changeVariableTreeItemPosition = async (
    organization,
    key,
    id,
    body: { position: number; parentId: string | null },
  ): Promise<any> => {
    return (await axiosBoardInstance.post(`/${organization}/processes/key/${key}/variables/${id}/position`, body)).data
  }
}
export default new VariablesService()
