import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ProcessInstancesService from '../../services/ProcessInstances.service'

export const useProcessInstanceVariables = (processInstanceId: string, selectedTaskId: string) => {
  const { org } = useParams()
  return useQuery<any>({
    queryKey: ['process-instance-variables', processInstanceId, selectedTaskId, org],
    queryFn: () => ProcessInstancesService.getInstanceVariables(org!, processInstanceId),
    enabled: !!org && !!processInstanceId,
  })
}
