import { useQuery } from '@tanstack/react-query'
import UserService from '../../services/UserService'

export const useOrganizations = () => {
  return useQuery({
    queryKey: ['organizations'],
    queryFn: () => UserService.listOrganizations(),
    staleTime: Infinity,
  })
}
