import { useQuery } from '@tanstack/react-query'
import AnalyticsService from '../../services/Analytics.service'
import { useParams } from 'react-router-dom'

export const useDiagramPath = () => {
  const { org, id } = useParams()
  return useQuery({
    queryKey: ['diagram-path', org, id],
    queryFn: () => AnalyticsService.getDiagramPath(org!, id!),
    enabled: !!org && !!id,
    staleTime: 0,
  })
}
