import { PropsWithClassName } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC } from 'react'

const cleanPercentage = (percentage) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0
  const tooHigh = percentage > 100
  return tooLow ? 0 : tooHigh ? 100 : +percentage
}

const Circle: FC<{ color: any; pct?: any }> = ({ color, pct }) => {
  const r = 100
  const circ = 2 * Math.PI * r
  const strokePct = ((100 - pct) * circ) / 100
  return (
    <circle
      r={r}
      cx={80}
      cy={120}
      fill='transparent'
      stroke={strokePct !== circ ? color : ''}
      strokeWidth={'1.25rem'}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap='round'
    ></circle>
  )
}

const Text: FC<{ percentage: any }> = ({ percentage }) => {
  return (
    <>
      <text
        x='50%'
        y='47%'
        dominantBaseline='central'
        textAnchor='middle'
        className='text-5xl fill-gray-500 font-semibold select-none'
      >
        {percentage.toFixed(0)}%
      </text>
      <text
        x='50%'
        y='60%'
        dominantBaseline='central'
        textAnchor='middle'
        className='text-base fill-gray-500 select-none'
      >
        Completed
      </text>
    </>
  )
}

const ProgressCircle: FC<PropsWithClassName<{ percentage: any; color: any }>> = ({ percentage, color, className }) => {
  const pct = cleanPercentage(percentage)
  return (
    <svg className={className}>
      <g transform={`rotate(-90 ${'100 100'})`}>
        <Circle color='#42477A' />
        <Circle color={color} pct={pct} />
      </g>
      <Text percentage={pct} />
    </svg>
  )
}

export default ProgressCircle
