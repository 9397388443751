import { useMutation, useQueryClient } from '@tanstack/react-query'
import LinkTypesService from '../../../../../services/LinkTypes.service'
import { useParams } from 'react-router-dom'

type AddLinkTypeOptions = {
  linkTypeTo: string
  linkTypeFrom: string
}

export const useAddLinkType = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()
  return useMutation<any, Error, AddLinkTypeOptions>({
    mutationKey: ['addLinkType'],
    mutationFn: (payload) => LinkTypesService.add(payload, org),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['link-types', org] })
    },
  })
}
