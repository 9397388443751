import { useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ProcessInstancesService from '../../../services/ProcessInstances.service'

type ProcessInstanceTaskRetryOptions = {
  taskId: string
}

export const useProcessInstanceTaskRetry = () => {
  // const queryClient = useQueryClient()
  const { org } = useParams()
  return useMutation<any, Error, ProcessInstanceTaskRetryOptions>({
    mutationKey: ['retry-task'],
    mutationFn: (payload) => ProcessInstancesService.taskRetry(payload.taskId, org!),
    // onSuccess: () => {
    //   queryClient.invalidateQueries({ queryKey: ['process-instances', org] })
    // },
  })
}
