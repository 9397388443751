import React, { FC, Fragment, useState } from 'react'
import { Button } from '@sistemiv/s-components'
import { PlusIcon } from '@heroicons/react/24/outline'
import { useProfile } from '../../repositories/user/user-profile.repository'
import { useMsal } from '@azure/msal-react'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import CreateProcessInstanceModal from '../process-instances/create-process-instance/CreateProcessInstanceModal'
import { useTranslation } from 'react-i18next'
import TimeSeriesSelect from './TimeSeriesSelect'

type DashboardHeaderProps = {
  //processesLength: number
  showHeaderButtons?: boolean
  showCreate?: boolean
  selectedTimeFilter: string
  setSelectedTimeFilter: DispatchSetAction<string>
}

const DashboardHeader: FC<DashboardHeaderProps> = ({
  showHeaderButtons,
  showCreate,
  selectedTimeFilter,
  setSelectedTimeFilter,
}) => {
  const { instance } = useMsal()
  const { data: profile } = useProfile({ userId: instance.getActiveAccount()?.localAccountId })
  const [createTicketOpen, setCreateTicketOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      <header className='flex flex-items justify-between py-10'>
        <h1 className='text-2xl'>{`${t('Dashboard.welcomeBack')}, ${profile?.data.firstName}!`}</h1>
        {showHeaderButtons && (
          <div className='flex gap-x-10'>
            {showCreate && (
              <Button
                className='border border-sky-500 text-sky-500 !rounded-md'
                onClick={() => setCreateTicketOpen(true)}
              >
                <PlusIcon className='w-4 h-4' />
                {t('Dashboard.create')}
              </Button>
            )}
            <TimeSeriesSelect selectedTimeFilter={selectedTimeFilter} setSelectedTimeFilter={setSelectedTimeFilter} />
          </div>
        )}
      </header>
      {createTicketOpen && (
        <CreateProcessInstanceModal open={createTicketOpen} setOpen={setCreateTicketOpen} selectProcess />
      )}
    </>
  )
}

export default DashboardHeader
