import { EditInput } from '@sistemiv/s-components'
import React, { FC } from 'react'

export interface Translation {
  key: string
  translationSingular: string
  translationPlural: string
  paired?: boolean
}
const TranslationsTable: FC<{ plural?: boolean; translations: Translation[]; paired?: boolean }> = ({
  plural,
  translations,
  paired,
}) => {
  return (
    <table className='w-full max-w-3xl text-sm'>
      <thead>
        <tr className='border-b border-gray-300'>
          <th className='px-3 py-1.5 text-left font-normal text-slate-500'>Name</th>
          {plural ? (
            <>
              <th className='px-3 py-1.5 text-left font-normal text-slate-500'>Translation - Singular</th>
              <th className='px-3 py-1.5 text-left font-normal text-slate-500'>Translation - Plural</th>
            </>
          ) : (
            <th className='px-3 py-1.5 text-left font-normal text-slate-500'>Translation</th>
          )}
        </tr>
      </thead>
      <tbody>
        {translations.map((translation, index) => (
          <tr
            key={`translation-${index}`}
            className={
              paired == true ? 'border-b border-grey-300' : index % 2 == 1 ? 'border-b border-grey-300' : 'border-none'
            }
          >
            <td className='px-3 py-1.5'>{translation.key}</td>
            <td className='px-3 py-1.5'>
              <EditInput value={translation.translationSingular} />
            </td>
            {plural && (
              <td className='px-3 py-1.5'>
                <EditInput value={translation.translationPlural} />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default TranslationsTable
