import { useQuery } from '@tanstack/react-query'
import ProcessInstancesService from '../../../../services/ProcessInstances.service'
import { ProcessField } from '../../../../models/ProcessInstance'

type ProcessFieldsType = {
  organization?: string
  key?: string
  scope?: 'global' | 'activity' | 'variable'
  visibleOnly?: boolean
  hierarchical?: boolean
}

export const useProcessFields = ({ organization, key, ...params }: ProcessFieldsType) => {
  return useQuery<ProcessField[]>({
    queryKey: ['process-fields', organization, key, { params }],
    queryFn: () => ProcessInstancesService.getProcessFields(organization!, key!, params),
    enabled: !!organization && !!key,
    staleTime: 0,
  })
}
