import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import VariablesService from '../../../../../services/Variables.service'

export const useChangeVariableVisibility = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()
  const [searchParams] = useSearchParams()
  const key = searchParams.get('key')

  return useMutation<any, Error, { variableId: string; body: { isVisible: boolean } }>({
    mutationKey: ['change-variable-visibility'],
    mutationFn: (payload) => VariablesService.changeVariableVisibility(org!, key!, payload.variableId, payload.body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['variables'] })
    },
  })
}
