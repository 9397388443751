import { useMutation, useQueryClient } from '@tanstack/react-query'
import FiltersService from '../../../../../services/Filters.service'

type AddFilterDefinitionPayload = {
  organization: string
  key: string
  id: string
  body: any
}

export const useAddFilterDefinition = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, AddFilterDefinitionPayload>({
    mutationKey: ['filters-add-definition'],
    mutationFn: (payload) =>
      FiltersService.createFilterPredicate(payload.organization, payload.key, payload.id, payload.body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['filters'] })
      queryClient.invalidateQueries({ queryKey: ['filters-definition'] })
    },
  })
}
