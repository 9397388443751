import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ProcessInstancesService from '../../services/ProcessInstances.service'

export const useProcessInstance = () => {
  const { org, id } = useParams()
  return useQuery<any>({
    queryKey: ['process-instances', org, id],
    queryFn: () => ProcessInstancesService.listInstance(org!, id!),
    enabled: !!org && !!id,
    staleTime: 0,
  })
}
