import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ProcessInstancesService from '../../../services/ProcessInstances.service'

type ProcessInstanceCompletePayload = {
  num: number
}

export const useProcessInstanceUpdatePriority = (processInstanceId: string) => {
  const queryClient = useQueryClient()
  const { org } = useParams()

  return useMutation<any, Error, ProcessInstanceCompletePayload>({
    mutationKey: ['priority', processInstanceId],
    mutationFn: ({ num }) =>
      ProcessInstancesService.setProcessInstancePriority(org!, processInstanceId, { priority: num }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['process-instances'] })
    },
  })
}
