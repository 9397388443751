import React, { FC, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import { Button, Input } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'
import ModalDialog from '../../../../ModalDialog'

type AddWebhookProps = {
  open: boolean
  name?: string
  creating?: boolean
  mode?: 'edit' | 'create'
  setOpen: DispatchSetAction<boolean>
  onCancel?: () => void
  onCreate?: (name: string) => void
}

const AddWebhookModal: FC<AddWebhookProps> = ({
  open,
  name,
  creating,
  setOpen,
  onCancel,
  onCreate,
  mode = 'create',
}) => {
  const [value, setValue] = useState(name ? name : '')
  const { t } = useTranslation()

  return (
    <ModalDialog isOpen={open} setOpen={setOpen} className='!max-w-sm'>
      <Dialog.Title as='h3' className='leading-6 text-lg flex justify-between'>
        {mode === 'create' ? t('Settings.webhooks.addWebhook') : t('Settings.webhooks.editWebhook')}
        <button onClick={() => setOpen(false)}>
          <XMarkIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </Dialog.Title>
      <div>
        <div className='mt-5 space-y-6'>
          <Input
            id='name'
            placeholder={t('Settings.variables.name') as string}
            type='text'
            removePencil
            classes='!border-solid border border-gray-300 focus:border-sky-100'
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div className='flex items-center justify-end gap-x-3 mt-6'>
          <Button className='hover:bg-gray-100 active:text-blue-500' onClick={() => onCancel?.()}>
            {t('Common.cancel')}
          </Button>
          <Button
            className='text-white bg-blue-500 disabled:bg-slate-200 disabled:text-slate-400'
            onClick={() => onCreate?.(value)}
            disabled={!value}
            loading={creating}
          >
            {mode === 'create' ? t('Common.create') : t('Common.update')}
          </Button>
        </div>
      </div>
    </ModalDialog>
  )
}

export default AddWebhookModal
