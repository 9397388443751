import { useQuery } from '@tanstack/react-query'
import ProcessDefinitionsSettings from '../../../../services/ProcessDefinitionsSettings'
import { ProcessVersions } from '../../../../models/ProcessDefinitionSettings'

type ProcessVersionsType = {
  org?: string
  type?: string
}

export const useProcessVersions = ({ org, type }: ProcessVersionsType) => {
  return useQuery<ProcessVersions[]>({
    queryKey: ['process-versions', org, type],
    queryFn: async () => {
      const data = await ProcessDefinitionsSettings.listProcessVersions(org!, type!)
      return data
    },
    enabled: !!org && !!type,
    staleTime: 0,
  })
}

// export const useProcessVersions = ({ org, type }: ProcessVersionsType) => {
//   return useQuery<ProcessDefinitionVersion[]>({
//     queryKey: ['process-definition-versions', org, type],
//     queryFn: async () => {
//       const data = await ProcessDefinitionsSettings.list(org!, { versions: true })
//       const flattenedItems = flatten(data)
//       const schema = flattenedItems.find((i) => i.processDefinitionKey === type)
//       return schema?.versions ?? []
//     },
//     enabled: !!org && !!type,
//   })
// }

// const flatten = (array?: ProcessDefinitionResponse[]): any => {
//   return array
//     ? array.reduce<ProcessSchema[]>((acc, item) => {
//         return [...acc, { ...item }, ...flatten('leaves' in item ? item.leaves : [])]
//       }, [])
//     : []
// }
