import { TaskItemProps } from '@sistemiv/s-components/dist/esm/components/task-list/TaskItem'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { createContext, FC, PropsWithChildren, useState } from 'react'
import { Task, TaskDetails } from '../models/Task'
import { useTranslation } from 'react-i18next'

interface FilterOption {
  value: string
  label: string
}

interface UserTasksContextValues {
  taskListAll: TaskItemProps[]
  setTaskListAll: DispatchSetAction<TaskItemProps[]>
  taskList: TaskItemProps[]
  setTaskList: DispatchSetAction<TaskItemProps[]>
  tasks: Task[]
  setTasks: DispatchSetAction<Task[]>
  selectedId: string
  setSelectedId: DispatchSetAction<string>
  selectedIndex: number
  setSelectedIndex: DispatchSetAction<number>
  filterOptions: FilterOption[]
  sortValue: FilterOption
  setSortValue: DispatchSetAction<FilterOption>
  sortOrder: boolean
  setSortOrder: DispatchSetAction<boolean>
  query: string
  setQuery: DispatchSetAction<string>
  task: TaskDetails | null
  setTask: DispatchSetAction<TaskDetails | null>
}

const defaultValues: UserTasksContextValues = {
  taskListAll: [],
  setTaskListAll: () => undefined,
  taskList: [],
  setTaskList: () => undefined,
  tasks: [],
  setTasks: () => undefined,
  selectedId: '',
  setSelectedId: () => undefined,
  selectedIndex: 0,
  setSelectedIndex: () => undefined,
  filterOptions: [
    { value: 'dueDate', label: 'Due date' },
    { value: 'assigneeCandidateType', label: 'Assignee Type' },
    { value: 'priority', label: 'Priority' },
    { value: 'name', label: 'Task name' },
    { value: 'processInstanceCreatedIn', label: 'Created' },
    { value: 'owner', label: 'Owner' },
  ],
  sortValue: { value: 'dueDate', label: 'Due date' },
  setSortValue: () => undefined,
  sortOrder: false,
  setSortOrder: () => undefined,
  query: '',
  setQuery: () => undefined,
  task: null,
  setTask: () => undefined,
}
export const UserTasksContext = createContext<UserTasksContextValues>(defaultValues)
const UserTasksContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation()
  const filterOptions = [
    { value: 'dueDate', label: t('UserTasks.filterOptions.dueDate') },
    { value: 'priority', label: t('UserTasks.filterOptions.priority') },
    { value: 'name', label: t('UserTasks.filterOptions.name') },
    { value: 'started', label: t('UserTasks.filterOptions.started') },
  ]
  const [taskListAll, setTaskListAll] = useState<TaskItemProps[]>([])
  const [taskList, setTaskList] = useState<TaskItemProps[]>([])
  const [tasks, setTasks] = useState<Task[]>([])
  const [task, setTask] = useState<TaskDetails | null>(null)
  const [selectedId, setSelectedId] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [sortValue, setSortValue] = useState({ value: 'dueDate', label: t('UserTasks.filterOptions.dueDate') })
  const [sortOrder, setSortOrder] = useState<boolean>(false)
  const [query, setQuery] = useState('')
  return (
    <UserTasksContext.Provider
      value={{
        taskListAll,
        setTaskListAll,
        taskList,
        setTaskList,
        tasks,
        setTasks,
        selectedId,
        setSelectedId,
        selectedIndex,
        setSelectedIndex,
        filterOptions,
        sortValue,
        setSortValue,
        sortOrder,
        setSortOrder,
        query,
        setQuery,
        task,
        setTask,
      }}
    >
      {children}
    </UserTasksContext.Provider>
  )
}

export default UserTasksContextProvider
