import { useMutation, useQueryClient } from '@tanstack/react-query'
import VariablesService from '../../../../../services/Variables.service'
import { useParams, useSearchParams } from 'react-router-dom'

export const useAddVariableSection = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()
  const [searchParams] = useSearchParams()
  const key = searchParams.get('key')

  return useMutation<any, Error, { body: { name: string; variables: any[] } }>({
    mutationKey: ['add-section'],
    mutationFn: (payload) => VariablesService.addVariableSection(org!, key!, payload.body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['variables'] })
    },
  })
}
