import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ProcessInstancesService from '../../services/ProcessInstances.service'

export const useProcessInstanceHistory = (direction: string) => {
  const { org, id } = useParams()
  return useQuery<any>({
    queryKey: ['process-instance-history', org, id, direction],
    queryFn: () => ProcessInstancesService.getInstanceHistory(org!, id!, direction),
    enabled: !!org && !!id,
    staleTime: 0,
  })
}
