import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ProcessDefinitionsSettings from '../../../../../services/ProcessDefinitionsSettings'

export const useRemoveUserFromOrg = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()

  return useMutation<any, Error, { itemId: string; processAccessId: string; userId: string }>({
    mutationKey: ['remove-user-from-org'],
    mutationFn: (payload) =>
      ProcessDefinitionsSettings.removeUserInOrgAccess(org!, payload.itemId, payload.processAccessId, payload.userId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['list-users-in-org'] })
    },
  })
}
