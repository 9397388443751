import { useQuery } from '@tanstack/react-query'
import FiltersService from '../../../../services/Filters.service'
import { FilterOverview } from '../../../../models/Filters'

type FiltersType = {
  organization?: string
  key?: string
}

export const useFilters = ({ organization, key }: FiltersType) => {
  return useQuery<FilterOverview[]>({
    queryKey: ['filters', organization, key],
    queryFn: () => FiltersService.list(key!, organization!),
    enabled: !!key && !!organization,
  })
}
