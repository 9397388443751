import VariablesService, { VariableType } from '../../../../services/Variables.service'
import { useQuery } from '@tanstack/react-query'
import { Variable } from '../../../../models/Variables'

type VariablesOptions = {
  organization?: string
  key?: string
  sorted?: boolean
  variableType?: VariableType
  variableOption: string
}

export const useVariables = ({ organization, key, sorted, variableType, variableOption }: VariablesOptions) => {
  return useQuery<Variable[]>({
    queryKey: ['variables', organization, key, variableOption],
    queryFn: () => VariablesService.getVariables(organization!, key!, sorted ?? true, variableOption, variableType),
    enabled: !!organization && !!key,
    staleTime: 0,
  })
}
