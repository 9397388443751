import { useQuery } from '@tanstack/react-query'
import PrioritiesService from '../../../../services/Priorities.service'
import { useParams } from 'react-router-dom'

export const usePriorities = () => {
  const { org } = useParams()
  return useQuery<any>({
    queryKey: ['priorities', org],
    queryFn: () => PrioritiesService.list(org),
  })
}
