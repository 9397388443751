import React, { FC } from 'react'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import AddProcessCombobox from './AddProcessCombobox'
import { TableField } from '@sistemiv/s-components/dist/esm/components/table/Table'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import IconPicker, { IconOption } from '@sistemiv/s-components/dist/esm/components/icon-picker/IconPicker'
import { Base64SVG, ColorSelect, EditInput } from '@sistemiv/s-components'
import ModalDialog from '../../../ModalDialog'
import { useTranslation } from 'react-i18next'

type EditProcessModalProps = {
  process: any
  value: TableField[]
  open: boolean
  setOpen: DispatchSetAction<boolean>
  onUpdate: (field: string, value: any) => void
  icons: IconOption[]
  colors: string[]
  setColors?(value: string[]): void
}

const EditProcessModal: FC<EditProcessModalProps> = ({
  process,
  value,
  open,
  setOpen,
  onUpdate,
  icons,
  colors,
  setColors,
}) => {
  const { t } = useTranslation()
  return (
    <ModalDialog isOpen={open} setOpen={setOpen}>
      <Dialog.Title as='h3' className='leading-6 text-lg flex justify-between'>
        {t('AddProcessModal.editProcess')}
        <button onClick={() => setOpen(false)}>
          <XMarkIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </Dialog.Title>
      <div className='pt-5'>
        <div className='space-y-7'>
          <div className='space-y-8'>
            <ColorSelect
              value={process.color}
              onChange={(value) => onUpdate('color', value)}
              options={colors}
              onNewColorAdd={(newColor: string) => {
                setColors && setColors([...colors, newColor])
              }}
            />
            <IconPicker
              selected={icons.find((i) => i.base64string === process.icon)}
              setSelected={(value) => onUpdate('icon', value)}
              options={icons}
            >
              <Base64SVG
                base64image={process.icon}
                className='[&>svg]:w-6 [&>svg]:h-6 [&>svg>*]:fill-gray-500 [&>svg>*]:hover:fill-gray-800'
              />
            </IconPicker>
          </div>
          <div className='space-y-6'>
            <EditInput value={process.code} onSave={(value) => onUpdate('code', value)} />
            <EditInput value={process.name} onSave={(value) => onUpdate('name', value)} />
            <AddProcessCombobox
              topMenu
              value={value.find((v) => v.objectId === process.escalationAssignee) ?? null}
              onChange={(value) => onUpdate('assignee', value)}
              options={value}
              placeholder={t('AddProcessModal.selectEscalation') as string}
              inputPlaceholder={t('Common.search') as string}
            />
          </div>
        </div>
      </div>
    </ModalDialog>
  )
}

export default EditProcessModal
