import classNames from 'classnames'
/*
 * Import components and utilities from our extension API. Warning: for demo experiments only.
 */
import { Errors, FormContext, Textfield, Description, Label } from '@bpmn-io/form-js'
import { html, useContext } from 'diagram-js/lib/ui'
import ProcessInstancesService from '../../services/ProcessInstances.service'
import { saveAs } from 'file-saver'
import { useState, useEffect } from 'preact/hooks'
import { Item } from '../../components/process-instance/process-instance-attachments/AttachmentsTab'

export const fileDownloadType = 'file-download'

/*
 * This is the rendering part of the custom field. We use `htm` to
 * to render our components without the need of extra JSX transpilation.
 */
export function FileDownloadRenderer(props) {
  //console.log(props)
  const { errors = [], field, value } = props
  const { description, id, label, validate = {}, organization, key } = field
  const { required } = validate
  const { formId } = useContext(FormContext)
  const [stateItem, setStateItem] = useState<Item | null>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [loading, setIsLoading] = useState<boolean>(false)
  const errorMessageId = errors.length === 0 ? undefined : `${prefixId(id, formId)}-error-message`
  // @ts-ignore
  const formData = localStorage.getItem('formData') && JSON.parse(localStorage.getItem('formData'))
  const jsonValue = value ? (typeof value === 'string' ? JSON.parse(value) : value) : null
  const defaultValue = jsonValue ? jsonValue[0] : formData[key]

  const org = field.organization

  const handleDownloadFile = (e: any, f: any) => {
    ProcessInstancesService.downloadBlobFile(organization, f.id)
      .then((res) => {
        console.log(res)
        saveAs(res.blob, f.fileName)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handlePreviewFile = (id: any, value: any) => {
    if (!org) return
    setOpenModal(true)
    setIsLoading(true)
    ProcessInstancesService.downloadBlobFile(org, id).then((res) => {
      const contentType = value?.mimeType
      const blob = new Blob([res.blob], { type: contentType })
      const fileUrl = URL.createObjectURL(blob)
      setIsLoading(false)
      setStateItem({ id: id, fileName: value, url: fileUrl, mimeType: contentType })
    })
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setOpenModal(false)
      }
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [setOpenModal])

  return html`<div class=${formFieldClasses(fileDownloadType)}>
    <${Label} id=${prefixId(id, formId)} label=${label} required=${required} />
    <div class="pt-3 space-y-3">
      ${!defaultValue && html` <div class="text-[#E2266A]">No file variable available</div>`}
      ${defaultValue &&
      html` <div key=${defaultValue.id} class="flex text-sm text-slate-600 items-center justify-between">
        <div class="flex gap-x-2 items-center">
          <svg
            class="w-6 h-6"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM6 20V4H13V9H18V20H6Z"
              fill="black"
            />
          </svg>

          <div
            class="text-md cursor-pointer hover:text-gray-500"
            onClick=${() => {
              handlePreviewFile(defaultValue.id, defaultValue)
            }}
          >
            ${defaultValue.fileName}
          </div>
        </div>
        <button
          class="px-4"
          onClick=${(e) => {
            handleDownloadFile(e, defaultValue)
          }}
        >
          <svg
            class="w-5 h-5 fill-gray-400 hover:fill-gray-500"
            width="14"
            height="17"
            viewBox="0 0 14 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M14 6H10V0H4V6H0L7 13L14 6ZM0 15V17H14V15H0Z" />
          </svg>
        </button>
      </div>`}
      <${Description} description=${description} />
      <${Errors} errors=${errors} id=${errorMessageId} />
    </div>
    ${openModal &&
    html`
      <div class="fixed left-0 top-0 w-full h-full p-8 overflow-auto z-20 bg-slate-900/80">
        <span
          class="absolute cursor-pointer top-3 right-3 text-white font-bold"
          onClick=${() => {
            setOpenModal(false)
          }}
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
        </span>
        ${loading &&
        html`<div className="w-full flex justify-center items-center py-6 h-full">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin w-6 h-6 text-blue-500"
          >
            <path
              d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
              fill="currentColor"
            />
          </svg>
        </div>`}
        ${stateItem?.mimeType?.includes('image') &&
        !loading &&
        html` <div class="flex items-center justify-center align-middle">
          <img src=${stateItem?.url} alt="Image" class="max-w-full h-3/4" />
        </div>`}
        ${stateItem?.mimeType?.includes('pdf') &&
        !loading &&
        html` <div class="w-9/10 h-full">
          <iframe src=${stateItem?.url} type="application/pdf" class="w-full h-full" />
        </div>`}
        ${!stateItem?.mimeType?.includes('image') &&
        !stateItem?.mimeType?.includes('pdf') &&
        !loading &&
        html` <div className="flex items-center justify-center h-full text-white text-2xl">No preview available</div>`}
      </div>
    `}
  </div>`
}

/*
 * This is the configuration part of the custom field. It defines
 * the schema type, UI label and icon, palette group, properties panel entries
 * and much more.
 */
FileDownloadRenderer.config = {
  /* we can extend the default configuration of existing fields */
  ...Textfield.config,
  type: fileDownloadType,
  label: 'Additional files',
  organization: '',
  taskId: '',
  propertiesPanelEntries: ['key', 'label', 'description', 'disabled', 'readonly'],
}

// helper //////////////////////

function formFieldClasses(type, { errors = [], disabled = false, readonly = false } = {}) {
  if (!type) {
    throw new Error('type required')
  }

  return classNames('fjs-form-field', `fjs-form-field-${type}`, {
    'fjs-has-errors': errors.length > 0,
    'fjs-disabled': disabled,
    'fjs-readonly': readonly,
  })
}

function prefixId(id, formId) {
  if (formId) {
    return `fjs-form-${formId}-${id}`
  }

  return `fjs-form-${id}`
}
