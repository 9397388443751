import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Button, CustomFilterField } from '@sistemiv/s-components'
import React, { FC, useState } from 'react'
import classNames from '../../classNames'
import { useFilters } from '../../repositories'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

const CustomFilters: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { org, type } = useParams()
  const [isListCollapsed, setListCollapsed] = useState(true)
  const { data: filters } = useFilters({ organization: org, key: type })

  const handleClickOnFilter = (filter: string) => {
    setListCollapsed(false)
    const activeFilter = searchParams.get('activeFilter')
    if (activeFilter !== filter) {
      setSearchParams({ activeFilter: filter })
    } else {
      setSearchParams()
    }
  }

  return (
    <div className={`h-full flex flex-col ${!isListCollapsed ? 'w-72' : 'w-9'}`}>
      <div className='flex justify-end items-center pt-1 w-full'>
        <Button className='!p-2' onClick={() => setListCollapsed((old) => !old)}>
          {!isListCollapsed ? (
            <ChevronLeftIcon className='w-3.5 h-3.5 stroke-2 text-gray-500' />
          ) : (
            <ChevronRightIcon className='w-3.5 h-3.5 stroke-2 text-gray-500' />
          )}
        </Button>
      </div>
      <div
        className={classNames(
          'w-full h-full [&>*:first-child]:pt-5 [&>*:last-child]:border-b overflow-y-auto',
          `${isListCollapsed ? 'flex flex-col items-center' : ''}`,
        )}
      >
        {filters?.map((filter) => (
          <CustomFilterField
            active={searchParams.get('activeFilter') === filter.id}
            key={filter.id}
            id={filter.id}
            color={filter.color}
            name={filter.name}
            onClick={(id) => handleClickOnFilter(id)}
            collapsed={isListCollapsed}
          />
        ))}
      </div>
    </div>
  )
}

export default CustomFilters
