import { FileDownloadRenderer, fileDownloadType } from './FileDownload'

class CustomFormFields {
  constructor(formFields) {
    formFields.register(fileDownloadType, FileDownloadRenderer)
  }
  fileDownloadType
}

export default {
  __init__: ['fileDownloadField'],
  fileDownloadField: ['type', CustomFormFields],
}
