import { useQuery } from '@tanstack/react-query'
import ProcessDefinitionsSettings from '../../services/ProcessDefinitionsSettings'
import { ProcessDefinition, ProcessDefinitionResponse } from '../../models/ProcessDefinitionSettings'

type CurrentProcessDefinitionType = {
  org?: string
  processDefinitionKey?: string
  versions?: boolean
}

export const useCurrentProcessDefinition = ({
  org,
  processDefinitionKey,
  versions = false,
}: CurrentProcessDefinitionType) => {
  return useQuery<ProcessDefinition | undefined>({
    queryKey: ['current-process-definition', org, processDefinitionKey],
    queryFn: async () => {
      const data = await ProcessDefinitionsSettings.list(org!, { versions: versions })
      const flattenedItems = flatten(data)
      const schema = flattenedItems.find((i) => i.processDefinitionKey === processDefinitionKey)
      return schema
    },
    enabled: !!org && !!processDefinitionKey,
  })
}
const flatten = (array?: ProcessDefinitionResponse[]): any => {
  return array
    ? array.reduce<ProcessDefinition[]>((acc, item) => {
        return [...acc, { ...item }, ...flatten('leaves' in item ? item.leaves : [])]
      }, [])
    : []
}
