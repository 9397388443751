export const handleError = (error: any): string => {
  let errorMessage = ''
  const response = error.response
  const data = response?.data
  const status = response?.status
  if (status === 404) {
    errorMessage = data?.message ?? 'Resource not found'
  } else if (status === 403) {
    errorMessage = 'Unauthorized request'
  } else {
    errorMessage = data?.errorMessage ?? 'An error occurred!'
  }
  return errorMessage
}
