import React, { FC, SVGProps } from 'react'

const BackArrow: FC<SVGProps<string>> = ({ className }) => {
  return (
    <div className='group p-2 hover:bg-[#0096FE]  hover:bg-opacity-5 active:bg-[#0096FE]  active:bg-opacity-10  rounded-full'>
      <svg className={className} width='18' viewBox='0 0 24 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M17.7349 4.03501L15.9549 2.26501L6.06494 12.165L15.9649 22.065L17.7349 20.295L9.60494 12.165L17.7349 4.03501Z'
          fill='#8087A0'
        />
      </svg>
    </div>
  )
}

export default BackArrow
