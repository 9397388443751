import React, { FC, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import ImportFile from './ImportFile'
import { VersionInfo } from './AddNewVersionOverview'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import { getBase64 } from '../../../../utils/byteUtils'
import { Button, Input } from '@sistemiv/s-components'
import ModalDialog from '../../../ModalDialog'
import { useTranslation } from 'react-i18next'

type AddProcessVersionModalProps = {
  open: boolean
  setOpen: DispatchSetAction<boolean>
  onCheckFile?(base64: string): Promise<any>
  onAdd?(body: any): void
  setAsCurrent?: boolean
}
const AddProcessVersionModal: FC<AddProcessVersionModalProps> = ({
  open,
  setOpen,
  setAsCurrent,
  onCheckFile,
  onAdd,
}) => {
  const [versionTag, setVersionTag] = useState('')
  const [adding, setAdding] = useState(false)
  const [currentVersionSelected, setCurrentVersionSelected] = useState(setAsCurrent)
  const [showDetails, setShowDetails] = useState(false)
  const [processInfo, setProcessInfo] = useState<VersionInfo | any>(undefined)
  const [addingId, setAddingId] = useState('')
  const { t } = useTranslation()

  const handleFileContinue = async (files: File[]) => {
    const base64 = await getBase64(files[0])
    const response = await onCheckFile?.(base64)
    // console.log(response)
    setAddingId(response.id)
    setProcessInfo(response)
    setShowDetails(true)
  }

  const handleAdd = () => {
    setAdding(true)
    const body: any = {
      id: addingId,
      versionName: versionTag,
      currentVersionSelected: currentVersionSelected,
      // TODO: remove sendbox and testing
      sandboxSelected: false,
      testingSelected: false,
      base64File: processInfo?.deploymentPackage,
    }
    onAdd?.(body)
  }
  return (
    <ModalDialog isOpen={open} setOpen={setOpen}>
      <Dialog.Title as='h3' className='leading-6 text-lg flex justify-between'>
        {t('AddProcessVersionModal.title')}
        <button onClick={() => setOpen(false)}>
          <XMarkIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </Dialog.Title>
      {showDetails ? (
        <div className='pt-5 space-y-6'>
          {processInfo?.msg && (
            <div className='py-2 px-3 border-l-4 border-orange-500 bg-orange-200 text-orange-500'>
              {processInfo.msg}
            </div>
          )}
          <p>
            {processInfo?.code} - {processInfo?.name}
          </p>
          <div className='flex items-center gap-x-3'>
            <p className='flex-none text-sm'>V.{processInfo ? processInfo.latestVersionNumber + 1 : 1}</p>
            <Input
              type='text'
              id='version_tag'
              removePencil
              mainClasses='!mt-0'
              placeholder={t('AddProcessVersionModal.name') as string}
              value={versionTag}
              onChange={(e) => setVersionTag(e.target.value)}
              classes='!rounded-md !border-solid border border-gray-300 focus:border-sky-100'
            />
          </div>
          <Input
            type='checkbox'
            id='currentVersion'
            label={t('AddProcessVersionModal.setAsCurrent') as string}
            value={currentVersionSelected}
            checked={currentVersionSelected}
            onChange={(e) => setCurrentVersionSelected(e.target.checked)}
          />
          <div className='flex items-center justify-end gap-x-3'>
            <Button className='hover:bg-gray-100 active:text-blue-500' onClick={() => setOpen(false)}>
              {t('Common.cancel')}
            </Button>
            <Button
              className='text-white bg-blue-500 disabled:bg-slate-200 disabled:text-slate-400'
              onClick={handleAdd}
              loading={adding}
            >
              {t('Common.add')}
            </Button>
          </div>
        </div>
      ) : (
        <div className='pt-5'>
          <ImportFile onCancel={() => setOpen(false)} onContinue={handleFileContinue} />
        </div>
      )}
    </ModalDialog>
  )
}

export default AddProcessVersionModal
