import React, { FC } from 'react'
import WidgetWrapper from './WidgetWrapper'

const InfoWidget: FC<{ title: string; message: string }> = ({ title, message }) => {
  return (
    <WidgetWrapper className='space-y-4 bg-white'>
      <p className='text-slate-500 font-medium'>{title}</p>
      <div className='bg-sky-200 px-3 py-2 rounded-md'>
        <p>{message}</p>
      </div>
    </WidgetWrapper>
  )
}

export default InfoWidget
