import React, { FC } from 'react'

const DownloadIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width='14'
      height='18'
      viewBox='0 0 14 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path d='M14 6.5H10V0.5H4V6.5H0L7 13.5L14 6.5ZM0 15.5V17.5H14V15.5H0Z' />
    </svg>
  )
}

export default DownloadIcon
