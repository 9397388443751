import { useMutation, useQueryClient } from '@tanstack/react-query'
import PrioritiesService, { FieldOption } from '../../../../../services/Priorities.service'
import { useParams } from 'react-router-dom'

type UpdatePriorityOptions = {
  priorityId: string
  field: FieldOption
  payload?: { [key: string]: string | number }
}

export const useUpdatePriority = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()
  return useMutation<any, Error, UpdatePriorityOptions>({
    mutationKey: ['updatePriority'],
    mutationFn: (payload) => PrioritiesService.update(payload.priorityId, payload.field, payload.payload, org),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['priorities', org] })
    },
  })
}
