import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import FiltersService from '../../../../../services/Filters.service'
import { FilterCreation } from '../../../../../models/Filters'

export const useCreateFilter = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()
  const [searchParams] = useSearchParams()
  const key = searchParams.get('key')

  return useMutation<any, Error, FilterCreation>({
    mutationKey: ['filters', 'create'],
    mutationFn: (payload) => FiltersService.createFilter(payload, key!, org!),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['filters', org] })
    },
  })
}
