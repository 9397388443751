import React, { FC } from 'react'

type WidgetWrapperProps = {
  className?: string
  children: React.ReactNode
}

const WidgetWrapper: FC<WidgetWrapperProps> = ({ className, children }) => {
  return <div className={`rounded-md p-5 ${className}`}>{children}</div>
}

export default WidgetWrapper
