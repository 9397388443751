import { GalleryData } from '@sistemiv/s-components/dist/esm/components/process-instance-attachments/AttachmentGalleryModal'
import { TableRow } from '@sistemiv/s-components/dist/esm/components/table/Table'
import { Comment } from '@sistemiv/s-components/dist/esm/components/task-comments/CommentsSection'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { createContext, FC, PropsWithChildren, useState } from 'react'
import { Incident, ProcessInstance, ProcessInstanceDetails, ProcessInstanceVariables } from '../models/ProcessInstance'

interface ProcessInstanceContextValues {
  processInstance: ProcessInstance | null
  setProcessInstance: DispatchSetAction<ProcessInstance | null>
  subprocesses: TableRow[]
  setSubProcesses: DispatchSetAction<TableRow[]>
  attachments: TableRow[]
  setAttachments: DispatchSetAction<TableRow[]>
  galleryData: GalleryData[]
  setGalleryData: DispatchSetAction<GalleryData[]>
  linkedProcesses: TableRow[]
  setLinkedProcesses: DispatchSetAction<TableRow[]>
  comments: Comment[]
  setComments: DispatchSetAction<Comment[]>
  details: ProcessInstanceDetails | null
  setDetails: DispatchSetAction<ProcessInstanceDetails | null>
  processInstanceVariables: ProcessInstanceVariables | null
  setProcessInstanceVariables: DispatchSetAction<ProcessInstanceVariables | null>
  incidents: Incident[]
  setIncidents: DispatchSetAction<Incident[]>
}

const defaultValues: ProcessInstanceContextValues = {
  processInstance: null,
  setProcessInstance: () => undefined,
  subprocesses: [],
  setSubProcesses: () => undefined,
  attachments: [],
  setAttachments: () => undefined,
  galleryData: [],
  setGalleryData: () => undefined,
  linkedProcesses: [],
  setLinkedProcesses: () => undefined,
  comments: [],
  setComments: () => undefined,
  details: null,
  setDetails: () => undefined,
  processInstanceVariables: null,
  setProcessInstanceVariables: () => undefined,
  incidents: [],
  setIncidents: () => undefined,
}

export const ProcessInstanceContext = createContext<ProcessInstanceContextValues>(defaultValues)

const ProcessInstanceContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [processInstance, setProcessInstance] = useState<ProcessInstance | null>(null)
  const [attachments, setAttachments] = useState<TableRow[]>([])
  const [subprocesses, setSubProcesses] = useState<TableRow[]>([])
  const [galleryData, setGalleryData] = useState<GalleryData[]>([])
  const [linkedProcesses, setLinkedProcesses] = useState<TableRow[]>([])
  const [comments, setComments] = useState<Comment[]>([])
  const [details, setDetails] = useState<ProcessInstanceDetails | null>(null)
  const [processInstanceVariables, setProcessInstanceVariables] = useState<ProcessInstanceVariables | null>(null)
  const [incidents, setIncidents] = useState<Incident[]>([])
  return (
    <ProcessInstanceContext.Provider
      value={{
        processInstance,
        setProcessInstance,
        subprocesses,
        setSubProcesses,
        attachments,
        setAttachments,
        galleryData,
        setGalleryData,
        linkedProcesses,
        setLinkedProcesses,
        comments,
        setComments,
        details,
        setDetails,
        processInstanceVariables,
        setProcessInstanceVariables,
        incidents,
        setIncidents,
      }}
    >
      {children}
    </ProcessInstanceContext.Provider>
  )
}

export default ProcessInstanceContextProvider
