import { axiosIamInstance } from './http-common'

export type ToggleType = 'add' | 'remove'
export interface Application {
  code: string
  name: string
  permissions: string[]
}

export interface PermissionsCell {
  rbacId: string
  roleCode: string
  roleId: string
  permissionCode: string
  permissionGroup: string
  permissionId: string
  hasPermission: boolean
}

class PermissionsSettingsService {
  async getApplications(org: string): Promise<Application[]> {
    return (await axiosIamInstance.get(`/${org}/applications/access`)).data
  }

  async list(org?: string, applicationId = 's-process'): Promise<PermissionsCell[] | undefined> {
    if (!org) return
    return (await axiosIamInstance.get(`/${org}/application/${applicationId}/permissions`)).data
  }

  async togglePermission(rbacId: string, fn: ToggleType, org?: string) {
    if (!org) return
    return (await axiosIamInstance.post(`/${org}/permission/${rbacId}/${fn}`)).data
  }
}
export default new PermissionsSettingsService()
