import { axiosAnalyticsInstance } from './http-common'

class AnalyticsService {
  async getTaskTimeSeries(organization: string, startDate: Date, endDate: Date) {
    const params = { start: startDate, end: endDate }
    return (await axiosAnalyticsInstance.get(`/${organization}/dashboard/user-tasks/analytics`, { params })).data
  }

  async getProcessTimeSeries(organization: string, key: string, startDate: Date, endDate: Date) {
    const params = { start: startDate, end: endDate }
    return (await axiosAnalyticsInstance.get(`/${organization}/dashboard/processes/${key}/instances`, { params })).data
  }

  async getDiagramPath(organization: string, processInstanceId: string): Promise<string[]> {
    return (await axiosAnalyticsInstance.get(`/${organization}/process-instances/${processInstanceId}/diagram-path`))
      .data
  }
}

export default new AnalyticsService()
