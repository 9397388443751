import React, { FC, useState, useEffect, useMemo } from 'react'
import { Button, DeleteModal, SpinnerIcon, TabPanel, Tabs, Input, PaginationController } from '@sistemiv/s-components'
import { ThrashBinIcon } from '@sistemiv/s-components'
import { DocumentDuplicateIcon, PencilIcon, PlusIcon } from '@heroicons/react/24/outline'
import classNames from '../../../../classNames'
import { useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AddWebhookModal from './modals/AddWebhookModal'
import { useWebhooks } from '../../../../repositories/settings/processes/webhooks'
import { useAddWebhook } from '../../../../repositories/settings/processes/webhooks/mutation/add-webhook.mutation'
import { useDeleteWebhook } from '../../../../repositories/settings/processes/webhooks/mutation/delete-webhook.mutation'
import EditAccessModal from './modals/EditAccessModal'
import { Switch } from '@headlessui/react'
import { useUpdateWebhookStatus } from '../../../../repositories/settings/processes/webhooks/mutation/webhook-update-status.mutation'
import IncidentsTab from './IncidentsTab'
import { useUpdateWebhookName } from '../../../../repositories/settings/processes/webhooks/mutation/webhook-update-name.mutation'
type Tab = {
  key: 'config' | 'incidents'
  value: string
}
const WebhooksPage: FC = () => {
  const { org } = useParams()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editName, setEditName] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation()
  const [copied, setCopied] = useState(false)
  const tabs: Tab[] = useMemo(
    () => [
      { key: 'config', value: t('Settings.tabs.configuration') },
      { key: 'incidents', value: t('Settings.tabs.incidents') },
    ],
    [t],
  )
  const [activeTab, setActiveTab] = useState<Tab>(tabs[0])
  const { mutate: addWebhook, isPending: addingWebhook } = useAddWebhook()
  const { mutate: updateWebhookStatus, isPending: updatingWebhook } = useUpdateWebhookStatus()
  const { mutate: updateWebhookName, isPending: updatingWebhookName } = useUpdateWebhookName()
  const [search, setSearch] = useState('')
  const {
    data: webhooks,
    isLoading,
    isFetching,
    isPending,
  } = useWebhooks({
    page: parseInt(searchParams.get('page') ?? '1'),
    size: parseInt(searchParams.get('pageSize') ?? '20'),
    // page: page,
    // size: size,
    search: search,
  })
  const [editingWebhook, setEditingWebhook] = useState<any | undefined>(undefined)
  const [pendingSearch, setPendingSearch] = useState('')
  const { mutateAsync: deleteWebhookMutation, isPending: removingWebhook } = useDeleteWebhook()

  useEffect(() => {
    setActiveTab((prev) => tabs.find((tab) => tab.key === prev.key)!)
  }, [t, tabs])

  useEffect(() => {
    const delayTyping = setTimeout(() => {
      setSearch(pendingSearch)
    }, 500)

    return () => clearTimeout(delayTyping)
  }, [pendingSearch])

  const handleDelete = () => {
    if (!org || !editingWebhook) return
    deleteWebhookMutation(
      { id: editingWebhook.id },
      {
        onSuccess: () => {
          setDeleteModalOpen(false)
          setEditingWebhook(undefined)
        },
      },
    )
  }

  const handleUpdateStatus = (status: boolean, id: string) => {
    if (!org) return
    updateWebhookStatus(
      { organization: org, id: id, value: !status },
      {
        onSuccess: () => {
          setEditingWebhook(undefined)
        },
      },
    )
  }

  const handleUpdateName = (name: string) => {
    if (!org) return
    updateWebhookName(
      { organization: org, id: editingWebhook.id, value: name },
      {
        onSuccess: () => {
          setEditingWebhook(undefined)
          setCreateModalOpen(false)
          setEditName(false)
        },
      },
    )
  }

  const handleCreate = async (body: any) => {
    addWebhook(
      {
        body: { name: body },
      },
      {
        onSuccess: () => {
          setCreateModalOpen(false)
        },
      },
    )
  }

  useEffect(() => {
    console.log(searchParams)
    if (!webhooks) return
    const page = parseInt(searchParams.get('page') ?? '1')
    const size = parseInt(searchParams.get('pageSize') ?? '20')
    if (webhooks?.total < (page - 1) * size) {
      setSearchParams({ pageSize: `${size}`, page: '1' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webhooks, searchParams])

  return (
    <div className='h-full overflow-hidden flex flex-col'>
      <div className={classNames('flex items-center justify-start px-6 py-4', 'border-b border-gray-300')}>
        <div>
          <p className='px-1 py-2 text-sm text-gray-400'>{`S-Process / ${t('Settings.processes')}`}</p>
          <p className='text-xl px-1'>{t('Settings.settingsMenu.webhooks')}</p>
        </div>
        <div className='ml-16 flex h-full items-end gap-x-3'>
          <Button
            className='text-blue-500 flex items-center gap-x-3 border border-blue-500'
            onClick={() => setCreateModalOpen(true)}
          >
            <PlusIcon className='w-4 h-4' />
            {t('Settings.webhooks.addWebhook')}
          </Button>
        </div>
      </div>
      <div className='grow overflow-auto p-8'>
        <div className='w-full relative h-full'>
          {!isLoading || !isFetching || !isPending ? (
            webhooks?.webhooks?.length > 0 || search !== '' ? (
              <Tabs
                active={activeTab.value}
                tabs={tabs.map((tab) => tab.value)}
                onClick={(string) => {
                  setActiveTab(tabs.find((tab) => tab.value === string)!)
                }}
                childrenClassName='!pt-0'
              >
                <TabPanel tab={tabs[0].value} active={activeTab.value}>
                  <div className='w-full pt-7'>
                    <div className='w-full pb-6 flex justify-between'>
                      <Input
                        id={'search'}
                        type={'search'}
                        classes='!rounded-md [&>input]:!border-solid [&>input]:border [&>input]:border-gray-300 focus:[&>input]:border-sky-100 pr-16 max-w-72'
                        placeholder={t('Common.search') as string}
                        value={pendingSearch}
                        onChange={(e) => setPendingSearch(e.target.value)}
                      />
                      {webhooks?.total > 0 && (
                        <PaginationController
                          total={webhooks?.total}
                          size={parseInt(searchParams.get('pageSize') ?? '20')}
                          page={parseInt(searchParams.get('page') ?? '1')}
                          // size={size}
                          // page={page}
                          onPageChange={
                            (value) =>
                              setSearchParams({ pageSize: searchParams.get('pageSize') ?? '20', page: `${value}` })
                            //setPage(value)
                          }
                          onSizeChange={
                            (value) => setSearchParams({ page: searchParams.get('page') ?? '1', pageSize: `${value}` })
                            // setSize(value)
                          }
                        />
                      )}
                    </div>
                    {webhooks?.webhooks?.map((webhook) => (
                      <div className={`flex group items-center hover:bg-sky-50 py-2 px-4 w-full `} key={webhook.id}>
                        <div className='grow grid grid-flow-col gap-4'>
                          <div className={`flex items-center justify-start gap-x-6`}>
                            <div className='flex'>
                              <button
                                className={`opacity-0 group-hover:opacity-100 group/button mr-3`}
                                onClick={() => {
                                  navigator.clipboard.writeText(webhook.name)
                                  setCopied(true)
                                  setTimeout(() => setCopied(false), 1000)
                                }}
                              >
                                <DocumentDuplicateIcon
                                  className={`w-5 h-5 transition-all duration-200 text-slate-300 group-hover/button:text-slate-500 ${
                                    copied ? '!text-sky-500' : ''
                                  }`}
                                />
                              </button>
                              <div className='whitespace-nowrap text-ellipsis overflow-hidden min-w-24 w-48 min-[1650px]:w-80'>
                                {webhook.name}
                              </div>
                            </div>
                            <div className='flex'>
                              <div className='  whitespace-nowrap text-ellipsis overflow-hidden w-140 mr-3'>
                                {webhook.url}
                              </div>
                              <button
                                className={`opacity-0 group-hover:opacity-100 group/button`}
                                onClick={() => {
                                  navigator.clipboard.writeText(webhook.url)
                                  setCopied(true)
                                  setTimeout(() => setCopied(false), 1000)
                                }}
                              >
                                <DocumentDuplicateIcon
                                  className={`w-5 h-5 transition-all duration-200 text-slate-300 group-hover/button:text-slate-500 ${
                                    copied ? '!text-sky-500' : ''
                                  }`}
                                />
                              </button>
                            </div>
                          </div>
                          <div className='flex relative items-center justify-end align-end gap-x-5'>
                            <div className='group/switch flex items-center justify-center gap-x-2'>
                              {editingWebhook && editingWebhook.id === webhook.id && updatingWebhook && (
                                <SpinnerIcon className='w-3 h-3 text-blue-500' />
                              )}
                              <Switch
                                disabled={editingWebhook?.id === webhook?.id && updatingWebhook}
                                checked={webhook.enabled}
                                onChange={() => {
                                  setEditingWebhook(webhook)
                                  handleUpdateStatus(webhook.enabled, webhook.id)
                                }}
                                className={`border ${
                                  webhook.enabled ? 'border-blue-500' : 'border-gray-400'
                                } relative inline-flex h-[12px] w-[24px] shrink-0 cursor-pointer rounded-full border-2 transition-colors 
            duration-50 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white disabled:opacity-50
            focus-visible:ring-opacity-75 disabled:cursor-default`}
                              >
                                <span className='sr-only'>Use setting</span>
                                <span
                                  aria-hidden='true'
                                  className={`${webhook.enabled ? 'translate-x-3' : 'translate-x-0'}
                          pointer-events-none inline-block h-[8px] w-[8px] transform rounded-full border ${
                            webhook.enabled ? 'bg-blue-500' : 'bg-gray-400'
                          } shadow-lg ring-0 transition duration-50 ease-in-out`}
                                />
                              </Switch>
                              {!editingWebhook && (
                                <span className='absolute w-max top-full mt-1.5 scale-0 rounded bg-slate-500 p-2 text-xs z-20 text-white group-hover/switch:scale-100'>
                                  {webhook.enabled
                                    ? t('Settings.webhooks.disableWebhook')
                                    : t('Settings.webhooks.enableWebhook')}
                                </span>
                              )}
                            </div>
                            <div className='flex items-center justify-end align-end gap-x-4 opacity-0 group-hover:opacity-100'>
                              <Button
                                className='!p-0'
                                onClick={() => {
                                  setEditingWebhook(webhook)
                                  setEditName(true)
                                  setCreateModalOpen(true)
                                }}
                              >
                                <PencilIcon className='w-4 h-4 text-gray-500 hover:text-gray-800' />
                              </Button>
                              <Button
                                className='!p-0'
                                onClick={() => {
                                  setEditingWebhook(webhook)
                                  setDeleteModalOpen(true)
                                }}
                              >
                                <ThrashBinIcon className='w-4 h-4 fill-slate-500 hover:fill-gray-800' />
                              </Button>
                              <Button
                                className='!p-0 text-blue-500'
                                onClick={() => {
                                  setEditingWebhook(webhook)
                                  setEditModalOpen(true)
                                }}
                              >
                                {t('Settings.webhooks.editAccess')}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </TabPanel>
                <TabPanel tab={tabs[1].value} active={activeTab.value}>
                  <IncidentsTab />
                </TabPanel>
              </Tabs>
            ) : (
              <p className='text-gray-500'>{t('Settings.webhooks.noWebhooks')}</p>
            )
          ) : (
            <div className='w-full flex justify-center inset-x-auto absolute '>
              <SpinnerIcon className='w-8 h-8 text-sky-500' />
            </div>
          )}
        </div>
      </div>
      {createModalOpen && (
        <AddWebhookModal
          open={createModalOpen}
          setOpen={setCreateModalOpen}
          onCancel={() => setCreateModalOpen(false)}
          onCreate={!editName ? handleCreate : handleUpdateName}
          creating={!editName ? addingWebhook : updatingWebhookName}
          mode={editName ? 'edit' : 'create'}
          name={editName && editingWebhook.name}
        />
      )}
      {editModalOpen && (
        <EditAccessModal
          open={editModalOpen}
          webhook={editingWebhook}
          setOpen={setEditModalOpen}
          onCancel={() => {
            setEditModalOpen(false)
            setEditingWebhook(undefined)
          }}
        />
      )}
      {deleteModalOpen && (
        <DeleteModal
          title={t('Settings.webhooks.deleteWebhook')}
          description={t('Settings.webhooks.deleteWebhookMsg')}
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          onDeleteConfirmed={handleDelete}
          isLoading={removingWebhook}
        />
      )}
    </div>
  )
}

export default WebhooksPage
