import { Task } from '../models/Task'
import http from './http-common'

class TasksService {
  async list(organization: string, params: any): Promise<{ results: Task[] }> {
    return (await http.get(`${organization}/instances/user-tasks`, { params })).data
  }

  async listAllForSearch(organization: string) {
    return (
      await http.get(`${organization}/instances/user-tasks`, {
        params: {
          page: 0,
          size: 100000,
        },
      })
    ).data
  }

  async getTaskDetail(organization: string, id: string) {
    return (await http.get(`${organization}/instances/user-tasks/${id}`)).data
  }

  async claimTask(organization: string, id: string): Promise<any> {
    return (await http.post(`${organization}/instances/user-tasks/${id}/claim`)).data
  }

  async getTaskStartForm(org: string, id: string): Promise<any> {
    return (await http.get(`${org}/forms/user-tasks/${id}`)).data
  }

  async completeTask(organization: string, id: string, body: any) {
    return (await http.post(`${organization}/instances/user-tasks/${id}/complete`, body)).data
  }

  async escalateTask(organization: string, id: string) {
    return (await http.post(`/${organization}/instances/user-tasks/${id}/escalate`)).data
  }

  async deescalateTask(organization: string, id: string, body: any) {
    return (await http.post(`/${organization}/instances/user-tasks/${id}/deescalate`, body)).data
  }
}
export default new TasksService()
