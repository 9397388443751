import React, { FC, Fragment, PropsWithChildren, useEffect, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { PropsWithClassName } from '@sistemiv/s-components/dist/esm/types/types'
import classNames from '../../../classNames'
import { useParams, useSearchParams } from 'react-router-dom'
import { ProcessDefinition, ProcessDefinitionVersion } from '../../../models/ProcessDefinitionSettings'
import { useProcessFlat } from '../../../repositories/settings/processes/processes/process-flat.repository'
import ProcessSchemasCombobox from './process-shemas-combobox/ProcessSchemasCombobox'
import { useTranslation } from 'react-i18next'

const SelectableHeader: FC<PropsWithChildren<PropsWithClassName<{ title: string; hideVersions?: boolean }>>> = ({
  children,
  className,
  title,
  hideVersions,
}) => {
  const { org } = useParams()
  const { data: processSchemas } = useProcessFlat({ org })
  const [selected, setSelected] = useState<ProcessDefinition | null>(null)
  const [processVersions, setProcessVersions] = useState<ProcessDefinitionVersion[]>([])
  const [selectedVersion, setSelectedVersion] = useState<ProcessDefinitionVersion | null>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation()

  useEffect(() => {
    if (!processSchemas) return
    if (processSchemas.length > 0) {
      const param = searchParams.get('process')
      const version = searchParams.get('version')
      if (param) {
        const newSelected = processSchemas.find((p) => p.id === param)
        setSelected(newSelected ?? null)
        const versions = newSelected?.versions
        setProcessVersions(versions ?? [])
        if (version && !hideVersions) {
          setSelectedVersion(versions?.find((v) => v.id === version) ?? null)
        }
      } else {
        const params = {
          process: processSchemas[0].id,
          key: processSchemas[0].processDefinitionKey ?? '',
        }
        if (!hideVersions) {
          params['version'] = processSchemas[0].versions?.filter((v) => v.active)?.find((v) => v.current)?.id ?? ''
          params['versionId'] =
            processSchemas[0].versions?.filter((v) => v.active)?.find((v) => v.current)?.processDefinitionId ?? ''
        }
        setSearchParams(params)
      }
    }
  }, [searchParams, processSchemas, setSearchParams, hideVersions])

  return (
    <div className={classNames('flex items-center justify-start px-6 py-4', className ?? '')}>
      <div>
        <p className='px-1 py-2 text-sm text-gray-400'>{`S-Process / ${t('Settings.processes')}`}</p>
        <div className='flex items-center justify-start gap-x-4'>
          <ProcessSchemasCombobox
            className='relative w-full cursor-default overflow-x-hidden bg-white text-left border-4 rounded-md border-sky-100 py-1 pl-3 pr-9 text-xl min-h-[44px]'
            value={selected ?? undefined}
            onChange={(value: ProcessDefinition) => {
              const params = {
                process: value.id,
                key: value.processDefinitionKey ?? '',
              }
              if (!hideVersions) {
                params['version'] = value.versions?.filter((v) => v.active)?.find((v) => v.current)?.id ?? ''
                params['versionId'] =
                  value.versions?.filter((v) => v.active)?.find((v) => v.current)?.processDefinitionId ?? ''
              }
              setSearchParams(params)
            }}
            processSchemas={processSchemas}
          />
          {!hideVersions && (
            <Combobox
              onChange={(value: ProcessDefinitionVersion) =>
                setSearchParams({
                  process: selected?.id ?? '',
                  key: selected?.processDefinitionKey ?? '',
                  version: value.id,
                  versionId: value.processDefinitionId,
                })
              }
            >
              <div className='relative  max-w-sm'>
                <div className='relative w-full cursor-default overflow-x-hidden bg-white text-left border-4 rounded-md border-sky-100 py-1 pl-3 pr-9'>
                  <div className='text-xl whitespace-nowrap overflow-hidden text-ellipsis'>
                    <div className='flex items-center gap-3'>
                      <p>v.{selectedVersion?.versionNumber}</p>
                      <p className='px-1 bg-sky-100 rounded-sm text-sm text-gray-600'>{selectedVersion?.versionTag}</p>
                      {selectedVersion?.current && <p className='text-sm text-[#0096FE]'>{t('Settings.current')}</p>}
                    </div>
                  </div>
                  <Combobox.Button className={`absolute inset-y-0 right-0 items-center pr-2`}>
                    <ChevronDownIcon className='w-4 h-4 text-gray-700' />
                  </Combobox.Button>
                </div>
                <Transition
                  as={Fragment}
                  leave='transition ease-in duration-100'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <Combobox.Options
                    className={`absolute z-10 mt-1 max-h-60 w-fit max-w-2xl overflow-y-auto overflow-x-hidden rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
                  >
                    {processVersions
                      .filter((v) => v.active)
                      .map((version, index) => (
                        <Combobox.Option
                          key={`option-${index}`}
                          value={version}
                          className='w-full cursor-pointer py-2 px-4 hover:bg-slate-100'
                        >
                          <div className='flex items-center gap-x-3 text-gray-600 whitespace-nowrap'>
                            <p>v.{version.versionNumber}</p>
                            <p className='px-1 bg-sky-100 rounded-sm'>{version.versionTag}</p>
                          </div>
                        </Combobox.Option>
                      ))}
                  </Combobox.Options>
                </Transition>
              </div>
            </Combobox>
          )}
          <p className='text-xl'>{title}</p>
        </div>
      </div>
      <div className='h-full'>{children}</div>
    </div>
  )
}

export default SelectableHeader
