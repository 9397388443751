import React, { FC } from 'react'
import TaskItem, { TaskItemProps } from './TaskItem'
import classNames from '../../../classNames'

export type TaskListProps = {
  tasks: TaskItemProps[]
  onTaskSelected?: (id: string) => void
  selectedId: string
}
const TaskList: FC<TaskListProps> = ({ tasks, onTaskSelected, selectedId }) => {
  return (
    <div className={classNames('divide-y divide-gray-300 overflow-y-auto')} data-testid='task-list'>
      {tasks.map((task, i) => {
        return <TaskItem key={i} {...task} onSelected={onTaskSelected} selected={selectedId === task.id} />
      })}
    </div>
  )
}

export default TaskList
