import React, { FC } from 'react'
import ProgressCircle from '../charts/ProgressCircle'
import { useTranslation } from 'react-i18next'

const OpenTasksProgress: FC<{ percentage: number }> = ({ percentage }) => {
  const { t } = useTranslation()
  return (
    <div>
      <h3 className='p-3 text-lg text-gray-500'>{t('Dashboard.openVsCompleted')}</h3>
      <div className='flex items-center gap-x-7 p-8'>
        <ProgressCircle percentage={percentage} color={'#1BD7AA'} className='w-[240px] aspect-square' />
        <div className='space-y-4'>
          <div className='flex items-center gap-x-3'>
            <div className='w-5 h-5 rounded bg-[#42477A]' />
            <p className='text-gray-500'>{t('Dashboard.open')}</p>
          </div>
          <div className='flex items-center gap-x-3'>
            <div className='w-5 h-5 rounded bg-[#1BD7AA]' />
            <p className='text-gray-500'>{t('Dashboard.completed')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OpenTasksProgress
