import { useQuery } from '@tanstack/react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import FiltersService from '../../../../services/Filters.service'
import { FilterableColumns } from '../../../../models/Filters'

export const useFilterableColumns = () => {
  const { org } = useParams()
  const [searchParams] = useSearchParams()
  const key = searchParams.get('key')

  return useQuery<FilterableColumns>({
    queryKey: ['filters', 'columns', org],
    queryFn: () => FiltersService.getFilterableColumns(key!, org!),
  })
}
