import { useParams } from 'react-router-dom'
import PermissionsSettingsService from '../../../../services/PermissionsSettings.service'
import { useQuery } from '@tanstack/react-query'

type PermissionsOptions = {
  applicationId?: string
}

export const usePermissions = ({ applicationId }: PermissionsOptions) => {
  const { org } = useParams()
  return useQuery<any>({
    queryKey: ['permissions', org],
    queryFn: () => PermissionsSettingsService.list(org, applicationId),
    enabled: !!applicationId,
  })
}
