import { axiosMgmtInstance } from './http-common'

class ExpressionsService {
  getExpressionName = async (organization: string, id: string): Promise<{ expression: string }> => {
    return (await axiosMgmtInstance.get(`/${organization}/processes/${id}/name-expression`)).data
  }
  getExpressionDescription = async (organization: string, id: string): Promise<{ expression: string }> => {
    return (await axiosMgmtInstance.get(`/${organization}/processes/${id}/description-expression`)).data
  }
  setExpressionName = async (organization: string, id: string, expression: string): Promise<any> => {
    const body = {
      expression,
    }
    return (await axiosMgmtInstance.post(`/${organization}/processes/${id}/name-expression`, body)).data
  }
  setExpressionDescription = async (organization: string, id: string, expression: string): Promise<any> => {
    const body = {
      expression,
    }
    return (await axiosMgmtInstance.post(`/${organization}/processes/${id}/description-expression`, body)).data
  }
}
export default new ExpressionsService()
