import React, { FC } from 'react'
import searchIcon from '../assets/icons/search-icon.png'
import { Button, EmptyPage } from '@sistemiv/s-components'
import { useNavigate } from 'react-router-dom'

const NotFound: FC = () => {
  const navigate = useNavigate()

  return (
    <EmptyPage imageUrl={searchIcon} imageAlt={'Not found'} message={'Oops! Page not found'}>
      <Button className='text-sky-500 text-lg hover:bg-gray-100 transition-all' onClick={() => navigate('/')}>
        Go Home
      </Button>
    </EmptyPage>
  )
}

export default NotFound
