import { useMutation } from '@tanstack/react-query'
import ProcessInstancesService from '../../../../../services/ProcessInstances.service'
import { useParams } from 'react-router-dom'

export const useProcessDefault = () => {
  // const queryClient = useQueryClient()
  const { org } = useParams()
  //   const [searchParams] = useSearchParams()
  // const key = searchParams.get('key')

  return useMutation<any, Error, { processId: string; versionId: string }>({
    mutationKey: ['filters', 'create'],
    mutationFn: (payload) =>
      ProcessInstancesService.changeProcessCurrentVersion(org, payload.processId, payload.versionId),
    onSuccess: () => {
      // queryClient.invalidateQueries({ queryKey: ['filters', org] })
    },
  })
}
