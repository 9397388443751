import { Button, DatePicker, Option, RestartIcon, Select, SpinnerIcon } from '@sistemiv/s-components'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import IncidentReportWrapper from './incident-report/IncidentReportWrapper'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { PriorityIcon } from '@sistemiv/s-components/dist/esm/components/input/Input'
import dayjs from 'dayjs'
import { useListAllUsersInOrg } from '../../../repositories/settings/processes/processes/list-all-users-in-org.repository'
import { imageBase } from '../../../services/http-common'
import { Priority, formatPriorityRange, getFilteredPriorities } from '../../../utils/priorityUtils'
import { User } from '../../../models/User'
import SelectAssignee, { Assignee } from './SelectAssignee'
import { useTranslation } from 'react-i18next'

type BottomInfoPreviewProps = {
  activityName: string
  dueDate: Date
  assignee: string
  priority: number
  hasIncident?: boolean
  onRestartIncident?(): void
  taskRestarting?: boolean
  onEdit?: (actionString: string, body: any, taskId: string) => void
  activityId: string
  activityType: string
  assignees: User[]
}
const BottomInfoPreview: FC<BottomInfoPreviewProps> = ({
  assignee,
  dueDate,
  activityName,
  priority,
  hasIncident,
  taskRestarting,
  onRestartIncident,
  onEdit,
  activityId,
  activityType,
  assignees,
}) => {
  const [priorityValue, setPriorityValue] = useState<Priority>(formatPriorityRange(priority))
  const [dateValue, setDateValue] = useState<Date | null>(dueDate ? dayjs(dueDate).toDate() : null)
  const [assigneeValue, setAssigneeValue] = useState<Assignee | null>(null)
  const { data: users } = useListAllUsersInOrg()
  const { t } = useTranslation()
  const priorities = getFilteredPriorities(priorityValue.value)
  const now = useMemo(() => new Date(), [])
  const bottomRef = useRef(null)

  useEffect(() => {
    //scroll down to focus component
    if (bottomRef.current) {
      //@ts-ignore
      bottomRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [bottomRef, activityName])

  useEffect(() => {
    if (assignees && assignees.length > 0) {
      const createdByUser: User = users?.users?.find((user) => user.objectId === assignee)
      createdByUser
        ? setAssigneeValue({
            id: createdByUser?.objectId,
            name: createdByUser?.name,
            icon: `${imageBase}/${createdByUser?.objectId}/76`,
          })
        : setAssigneeValue(null)
    }
  }, [assignees, users, assignee])

  useEffect(() => setPriorityValue(formatPriorityRange(priority)), [priority])
  useEffect(() => setDateValue(dueDate ? dayjs(dueDate).toDate() : null), [dueDate])

  return (
    <div className='flex flex-col mb-2' ref={bottomRef}>
      {taskRestarting ? (
        <div className='bg-[#6275A5] text-white py-2 px-3 mb-4 flex gap-x-8 rounded'>
          <SpinnerIcon className='w-6 h-6 text-sky-500' />
          <p>{t('ProcessInstance.restartingFailed')}</p>
        </div>
      ) : (
        <div className='flex flex-row gap-x-20'>
          <IncidentReportWrapper label={t('ProcessInstance.activity')}>
            <div className='flex flex-row gap-1.5 items-center'>
              <div className='rounded bg-[#DEE1FC] flex items-start p-0.5 px-2 min-w-fit'>{activityName}</div>
              {hasIncident && <ExclamationCircleIcon className='w-5 h-5 text-red-400' />}
              {hasIncident && (
                <Button onClick={() => onRestartIncident?.()} className='!p-0'>
                  <RestartIcon className='w-5 h-5 text-gray-400 hover:text-gray-700' />
                </Button>
              )}
            </div>
          </IncidentReportWrapper>
          {activityType === 'userTask' && (
            <>
              {onEdit && !hasIncident && (
                <IncidentReportWrapper label={t('ProcessInstance.taskDueDate')}>
                  <DatePicker
                    value={dateValue}
                    editMode={true}
                    inputClassName='!px-0'
                    onChange={(date) => {
                      setDateValue(date)
                      onEdit('due-date', { dueDate: date }, activityId)
                    }}
                    topMenu={true}
                    min={now}
                  />
                </IncidentReportWrapper>
              )}
              {onEdit && !hasIncident && (
                <IncidentReportWrapper label={t('ProcessInstance.taskAssignee')}>
                  <SelectAssignee
                    assignees={assignees.map((user) => ({
                      id: user.objectId,
                      name: user.name,
                      icon: `${imageBase}/${user.objectId}/76`,
                    }))}
                    selectedAssignee={assigneeValue}
                    onSelect={(e) => {
                      setAssigneeValue(e)
                      onEdit('assignee', { AssigneeId: e.id }, activityId)
                    }}
                  />
                </IncidentReportWrapper>
              )}
              {!hasIncident && onEdit && (
                <IncidentReportWrapper label={t('ProcessInstance.taskPriority')}>
                  <Select
                    type='priority'
                    value={priorityValue}
                    topMenu={true}
                    onChange={(e) => {
                      setPriorityValue(e)
                      onEdit('priority', { priority: e.value }, activityId)
                    }}
                    editMode={true}
                  >
                    {priorities
                      .filter((p) => p.value !== priorityValue.value)
                      .map((p) => (
                        <Option key={p.value} value={p}>
                          <div className='flex justify-start items-center gap-x-3'>
                            <PriorityIcon priority={p.label} />
                            {p.label}
                          </div>
                        </Option>
                      ))}
                  </Select>
                </IncidentReportWrapper>
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default BottomInfoPreview
