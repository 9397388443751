import { Combobox, Dialog, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ColorSelect, EditInput } from '@sistemiv/s-components'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { Fragment, useEffect, useState } from 'react'
import ModalDialog from '../../../../ModalDialog'
import FilterColumn from './FilterColumn'
import { useTranslation } from 'react-i18next'

type EditFilterModalProps = {
  isOpen: boolean
  setOpen: DispatchSetAction<boolean>
  filter: any
  definition: any[]
  columns?: {
    id: string
    name: string
    itemType: string
    position: number
    scope: string
    isVisible: boolean
    type: string
  }[]
  colors: string[]
  setColors(v: string[]): void
  onUpdateBody?(filed: string, value: any): void
  onUpdateDefinition?(definition: any): void
  onAddDefinition?(definition: any): void
  onDeleteDefinition?(definition: any): void
}
const EditFilterModal: React.FC<EditFilterModalProps> = ({
  isOpen,
  setOpen,
  filter,
  colors,
  setColors,
  definition,
  columns,
  onUpdateBody,
  onUpdateDefinition,
  onAddDefinition,
  onDeleteDefinition,
}) => {
  const [filterableColumns, setFilterableColumns] = useState(columns)
  const [chosenColumns, setChosenColumns] = useState<any[]>([])
  const [query, setQuery] = useState('')
  const { t } = useTranslation()

  const addCondition = (e) => {
    setChosenColumns((old) => [...old, { field: e }])
  }

  const removeChosenColumn = (option) => {
    setChosenColumns((old) => old.filter((value) => option !== value.field))
  }

  useEffect(() => {
    if (!filterableColumns || Object.keys(filterableColumns).length === 0) return
    const filtered =
      query === ''
        ? columns
        : columns?.filter(({ name }) =>
            name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')),
          )

    setFilterableColumns(filtered)
  }, [columns, query, filterableColumns])

  useEffect(() => setChosenColumns([]), [definition])

  return (
    <ModalDialog isOpen={isOpen} setOpen={setOpen} className='w-200 !max-w-md'>
      <Dialog.Title as='h3' className='leading-6 text-lg flex justify-between'>
        {t('Settings.filters.editFilter')}
        <button onClick={() => setOpen(false)}>
          <XMarkIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </Dialog.Title>
      <div className='flex flex-col justify-evenly items-start w-full gap-y-6'>
        <div className='mt-5'>
          <ColorSelect
            value={filter.color}
            options={colors}
            onChange={(color) => onUpdateBody?.('color', color)}
            onNewColorAdd={(color) => setColors([...colors, color])}
          />
        </div>
        <EditInput
          placeholder={t('Settings.filters.enterFilter') as string}
          value={filter.name}
          onSave={(e) => onUpdateBody?.('name', e)}
        />

        <div className={'flex flex-col gap-3 w-full'}>
          {definition.map((value) => (
            <FilterColumn
              isEdit={true}
              key={value.id}
              value={value}
              type={filterableColumns?.find((f) => f.id === value.field)?.itemType ?? ''}
              onRemove={onDeleteDefinition}
              onUpdate={onUpdateDefinition}
            />
          ))}
          {chosenColumns.map((value) => (
            <FilterColumn
              isEdit={true}
              key={value.id}
              value={value}
              type={filterableColumns?.find((f) => f.id === value.field)?.itemType ?? ''}
              onRemove={removeChosenColumn}
              onUpdate={(value) => {
                if (value.value) {
                  onAddDefinition?.(value)
                } else {
                  setChosenColumns((old) =>
                    old.map((obj) => {
                      if (obj.field === value.field) {
                        return value
                      }
                      return obj
                    }),
                  )
                }
              }}
            />
          ))}
        </div>

        <Combobox
          onChange={(v: any) => {
            addCondition(v)
          }}
        >
          <div className='relative'>
            <div className='relative flex w-full justify-between'>
              <Combobox.Button className='border-none text-blue-500 flex items-center relative'>
                <PlusIcon className='w-4 h-4' />
                {t('Settings.filters.condition')}
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
              afterLeave={() => setQuery('')}
            >
              <Combobox.Options className='absolute top-0 mt-1 py-1 z-10 max-h-60 rounded-md overflow-auto bg-white shadow-xl ring-1 ring-black ring-opacity-5'>
                <div className='relative w-full border-b border-gray-300'>
                  <Combobox.Input
                    displayValue={(value) => (value ? '' : '')}
                    placeholder={t('Common.search')}
                    className='focus:border-none focus:ring-0 border-none pl-8 leading-5'
                    onChange={(e) => setQuery(e.target.value)}
                  />
                  <span className='absolute left-0 pl-2 text-gray-400 inset-y-0 flex items-center justify-center'>
                    <MagnifyingGlassIcon className='w-5 h-5' />
                  </span>
                </div>
                {!filterableColumns && query !== '' ? (
                  <div className='px-3 py-2 text-gray-700 cursor-default select-none'>{t('Common.nothingFound')}</div>
                ) : (
                  filterableColumns &&
                  filterableColumns
                    .filter(({ id }) => !chosenColumns.map((f) => f.field).includes(id))
                    .filter(({ id }) => !definition.map((f) => f.field).includes(id))
                    .map(({ id, name }) => (
                      <Combobox.Option
                        key={id}
                        value={id}
                        className='px-3 py-2 hover:bg-slate-100 cursor-pointer select-none'
                      >
                        {name}
                      </Combobox.Option>
                    ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    </ModalDialog>
  )
}

export default EditFilterModal
