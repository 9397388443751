import React, { FC, useState } from 'react'
import AddProcessCombobox from './AddProcessCombobox'
import IconPicker, { IconOption } from '@sistemiv/s-components/dist/esm/components/icon-picker/IconPicker'
import { TableField } from '@sistemiv/s-components/dist/esm/components/table/Table'
import { Base64SVG, Button, ColorSelect, Input } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'

type AddProcessOverviewProps = {
  options?: any[]
  onCancel?: () => void
  onAdd: (body: any) => void
  isLoading?: boolean
  base64file: string
  addFromVersion?: boolean
  icons: IconOption[]
  colors: string[]
  setColors?(value: string[]): void
}
const AddProcessOverview: FC<AddProcessOverviewProps> = ({
  options,
  onCancel,
  onAdd,
  isLoading,
  base64file,
  addFromVersion = false,
  icons,
  colors,
  setColors,
}) => {
  const [color, setColor] = useState('#CAD1F8')
  const [assignee, setAssignee] = useState<TableField | null>(null)
  const [code, setCode] = useState('')
  const [translationKey, setTranslationKey] = useState('')
  const [versionTag, setVersionTag] = useState('')
  const [selectedIcon, setSleectedIcon] = useState(icons[0])
  const { t } = useTranslation()

  const handleAdd = () => {
    const body = {
      color,
      icon: selectedIcon.base64string,
      code,
      name: translationKey,
      escalationAssignee: assignee?.objectId,
      deploymentPackage: base64file,
      versionTag,
    }
    onAdd(body)
  }

  return (
    <div className='space-y-7'>
      {addFromVersion && (
        <div className='py-2 px-3 border-l-4 border-orange-500 bg-orange-200 text-orange-500'>
          {t('AddProcessModal.newProcessNotification')}
        </div>
      )}
      <div className='space-y-8'>
        <ColorSelect
          value={color}
          onChange={setColor}
          options={colors}
          onNewColorAdd={(newColor: string) => {
            setColors && setColors([...colors, newColor])
          }}
        />
        <IconPicker selected={selectedIcon} setSelected={setSleectedIcon} options={icons}>
          <Base64SVG
            base64image={selectedIcon.base64string}
            className='[&>svg]:w-6 [&>svg]:h-6 [&>svg>*]:fill-gray-500 [&>svg>*]:hover:fill-gray-800'
          />
        </IconPicker>
      </div>
      <div className='space-y-6'>
        <Input
          type='text'
          id='short'
          removePencil
          value={code}
          onChange={(e) => setCode(e.target.value)}
          mainClasses='!m-0'
          placeholder={t('AddProcessModal.code') as string}
          classes='!rounded-md !border-solid border border-gray-300 focus:border-sky-100'
        />
        <Input
          type='text'
          id='long'
          removePencil
          mainClasses='!mt-6'
          placeholder={t('AddProcessModal.name') as string}
          value={translationKey}
          onChange={(e) => setTranslationKey(e.target.value)}
          classes='!rounded-md !border-solid border border-gray-300 focus:border-sky-100'
        />
        <AddProcessCombobox
          topMenu
          value={assignee}
          onChange={(value) => {
            setAssignee(value)
          }}
          options={options}
          placeholder={t('AddProcessModal.selectEscalation') as string}
          inputPlaceholder={t('Common.search') as string}
        />
        <div className='flex items-center gap-x-3 mt-6'>
          <p className='flex-none text-sm'>V.1</p>
          <Input
            type='text'
            id='version_tag'
            removePencil
            mainClasses='!mt-0'
            placeholder={t('AddProcessModal.versionName') as string}
            value={versionTag}
            onChange={(e) => setVersionTag(e.target.value)}
            classes='!rounded-md !border-solid border border-gray-300 focus:border-sky-100'
          />
        </div>
        <div className='flex items-center justify-end gap-x-3'>
          <Button className='hover:bg-gray-100 active:text-blue-500' onClick={onCancel}>
            {t('Common.cancel')}
          </Button>
          <Button
            className='text-white bg-blue-500 disabled:bg-slate-200 disabled:text-slate-400'
            loading={isLoading}
            disabled={!assignee}
            onClick={handleAdd}
          >
            {t('Common.add')}
          </Button>
        </div>
      </div>
    </div>
  )
}
export default AddProcessOverview
