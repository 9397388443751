import { useMutation, useQueryClient } from '@tanstack/react-query'
import ProcessInstancesService from '../../../services/ProcessInstances.service'
import { useParams } from 'react-router-dom'

type TaskUpdatePayload = {
  actionString: string
  taskId: string
  body: any
}

export const useProcessInstanceTaskUpdate = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()

  return useMutation<any, Error, TaskUpdatePayload>({
    mutationKey: ['task-update'],
    mutationFn: async ({ actionString, taskId, body }) => {
      await ProcessInstancesService.bulkEdit(org!, 'user-tasks', taskId, actionString, body)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['process-instances'] })
    },
  })
}
