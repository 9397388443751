import React, { FC, SVGProps } from 'react'

const OrdersProcessIcon: FC<SVGProps<string>> = ({ className }) => {
  return (
    <svg
      width='18'
      height='19'
      viewBox='0 0 18 19'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      className={className}
    >
      <path d='M8 4.00977H14V6.00977H8V4.00977ZM8 8.00977H14V10.0098H8V8.00977ZM8 12.0098H14V14.0098H8V12.0098ZM4 4.00977H6V6.00977H4V4.00977ZM4 8.00977H6V10.0098H4V8.00977ZM4 12.0098H6V14.0098H4V12.0098ZM17.1 0.00976562H0.9C0.4 0.00976562 0 0.409766 0 0.909766V17.1098C0 17.5098 0.4 18.0098 0.9 18.0098H17.1C17.5 18.0098 18 17.5098 18 17.1098V0.909766C18 0.409766 17.5 0.00976562 17.1 0.00976562ZM16 16.0098H2V2.00977H16V16.0098Z' />
    </svg>
  )
}

export default OrdersProcessIcon
