import { Combobox, Transition } from '@headlessui/react'
import React, { FC, Fragment, useState } from 'react'
import { ProcessDefinition } from '../../../../models/ProcessDefinitionSettings'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Base64SVG } from '@sistemiv/s-components'
import { PropsWithClassName } from '@sistemiv/s-components/dist/esm/types/types'
import { useTranslation } from 'react-i18next'

const ProcessSchemasCombobox: FC<
  PropsWithClassName<{
    value?: ProcessDefinition
    processSchemas?: ProcessDefinition[]
    onChange?(value: ProcessDefinition): void
  }>
> = ({ value, processSchemas, onChange, className }) => {
  const [query, setQuery] = useState('')
  const { t } = useTranslation()

  const filteredOptions =
    query === ''
      ? processSchemas
      : processSchemas?.filter((option) =>
          option.name.toLowerCase().replace(/\s+/g, '').startsWith(query.toLowerCase().replace(/\s+/g, '')),
        )
  return (
    <Combobox value={value} onChange={onChange}>
      <div className='relative max-w-sm'>
        <div className={className}>
          <div className='whitespace-nowrap overflow-hidden text-ellipsis'>{value?.name ?? t('Common.select')}</div>
          <Combobox.Button className={`absolute inset-y-0 right-0 items-center pr-2`}>
            <ChevronDownIcon className='w-5 h-5 text-gray-700' />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options
            className={`absolute z-10 mt-1 max-h-60 w-fit max-w-2xl overflow-y-auto overflow-x-hidden rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
          >
            <div className='border-b border-gray-300'>
              <Combobox.Input
                onChange={(e) => setQuery(e.target.value)}
                displayValue={(value: any) => {
                  return value ? '' : ''
                }}
                placeholder={t('Common.search')}
                className={`w-full border-none focus:border-none focus:ring-0 rounded-md py-2 text-gray-900 leading-3 text-sm pl-3 pr-3`}
              />
            </div>

            {filteredOptions?.length === 0 && query !== '' ? (
              <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                {t('Common.nothingFound')}
              </div>
            ) : (
              filteredOptions?.map((option, index) => (
                <Combobox.Option
                  key={`option-${index}`}
                  value={option}
                  className='w-full cursor-pointer py-2 px-4 hover:bg-slate-100'
                >
                  <div className='flex items-center gap-x-3 text-gray-600 whitespace-nowrap'>
                    <div className='rounded-md h-5 w-5 flex-none' style={{ backgroundColor: option.color }} />
                    <Base64SVG
                      base64image={option.icon ?? ''}
                      className='[&>svg>*]:fill-gray-500 flex-none [&>svg]:w-6 [&>svg]:h-6'
                    />
                    <p>{option.name}</p>
                  </div>
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
}

export default ProcessSchemasCombobox
