import React, { FC, useEffect, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import ImportFile from './ImportFile'
import AddProcessOverview from './AddProcessOverview'
import AddNewVersionOverview, { VersionInfo } from './AddNewVersionOverview'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import { IconOption } from '@sistemiv/s-components/dist/esm/components/icon-picker/IconPicker'
import { getBase64 } from '../../../../utils/byteUtils'
import ModalDialog from '../../../ModalDialog'
import { useTranslation } from 'react-i18next'

type AddProcessModalProps = {
  open: boolean
  options?: any[]
  hasFileData?: boolean
  base64File?: string
  setOpen: DispatchSetAction<boolean>
  onAdd: (body: any) => void
  checkFile?(base64: string): Promise<any>
  icons: IconOption[]
  colors: string[]
  setColors?(value: string[]): void
  isLoading?: boolean
}

const AddProcessModal: FC<AddProcessModalProps> = ({
  open,
  options,
  hasFileData = false,
  base64File = '',
  setOpen,
  onAdd,
  checkFile,
  icons,
  colors,
  setColors,
  isLoading,
}) => {
  const [showOverview, setShowOverview] = useState(hasFileData)
  const [newVersionInfo, setNewVersionInfo] = useState<VersionInfo | undefined>(undefined)
  const [base64file, setBase64file] = useState(base64File)
  const { t } = useTranslation()

  useEffect(() => {
    open && setShowOverview(hasFileData)
  }, [open, hasFileData])

  const handleFileContinue = async (files: File[]) => {
    const base64 = await getBase64(files[0])
    setBase64file(base64)
    checkFile?.(base64).then((response) => {
      setNewVersionInfo(response)
      setShowOverview(true)
    })
  }

  return (
    <ModalDialog isOpen={open} setOpen={() => null}>
      <Dialog.Title as='h3' className='leading-6 text-lg flex justify-between'>
        {t('AddProcessModal.title')}
        <button onClick={() => setOpen(false)}>
          <XMarkIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </Dialog.Title>
      {showOverview ? (
        <div className='pt-5'>
          {newVersionInfo ? (
            <AddNewVersionOverview onCancel={() => setOpen(false)} onAdd={onAdd} versionInfo={newVersionInfo} />
          ) : (
            <AddProcessOverview
              addFromVersion={hasFileData}
              base64file={base64file}
              options={options}
              onCancel={() => setOpen(false)}
              onAdd={onAdd}
              icons={icons}
              colors={colors}
              setColors={setColors}
              isLoading={isLoading}
            />
          )}
        </div>
      ) : (
        <div className='pt-5 space-y-8'>
          <ImportFile onCancel={() => setOpen(false)} onContinue={handleFileContinue} />
        </div>
      )}
    </ModalDialog>
  )
}

export default AddProcessModal
