import { Combobox } from '@headlessui/react'
import { CheckIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Button } from '@sistemiv/s-components'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type FieldT = {
  id: string
  name: string
  itemType: string
  position: number
  scope: string
  type: string
}

type TableFieldsFilterProps = {
  fields: FieldT[]
  selected: FieldT[]
  setSelected: (fields: FieldT[]) => void
  onRestoreDefault: () => void
}

const TableFieldsFilter: FC<TableFieldsFilterProps> = ({ fields, selected, setSelected, onRestoreDefault }) => {
  const [query, setQuery] = useState('')
  const [t] = useTranslation()

  const filteredFields =
    query === ''
      ? fields
      : fields.filter((field) =>
          field.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')),
        )
  return (
    <Combobox
      value={selected.map((f) => f.id)}
      onChange={(value) => {
        setSelected(fields.filter((f) => value.includes(f.id)))
      }}
      multiple
      name='table-field-filter'
    >
      <div className='relative rounded-l-md border border-gray-300 shadow-md'>
        <div className='relative w-full border-b border-gray-300 rounded-tl-md cursor-default overflow-hidden bg-white text-left focus:outline-none focus-visible:ring-0 sm:text-sm'>
          <Combobox.Input
            className='w-full border-none py-2 pl-10 pr-3 text-sm leading-5 text-gray-900 focus:ring-0'
            onChange={(event) => setQuery(event.target.value)}
            placeholder='Search'
            name='table-field-filter-input'
          />
          <Combobox.Button className='absolute inset-y-0 left-0 flex items-center pl-2'>
            <MagnifyingGlassIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
          </Combobox.Button>
        </div>

        <Combobox.Options
          className='relative max-h-60 w-full overflow-auto bg-white py-1 text-base rounded-l-md focus:outline-none sm:text-sm'
          static
        >
          <Button className='disabled:text-gray-400 !px-3' onClick={onRestoreDefault}>
            {t('ProcessInstance.restoreDefault')}
          </Button>
          {filteredFields.length === 0 && query !== '' ? (
            <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
              {t('Common.nothingFound')}
            </div>
          ) : (
            filteredFields.map((field, index) => (
              <React.Fragment key={field.id}>
                {(index === 0 || field.scope !== filteredFields[index - 1].scope) && (
                  <React.Fragment>
                    {index !== 0 && <div className='w-full border-b border-[#E1E3EA] mt-[6px] mb-3'></div>}
                    <p className='pl-3 text-[#8087A0] text-sm uppercase'>{field.scope}</p>
                  </React.Fragment>
                )}
                <Combobox.Option
                  data-testid='option'
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-2 pl-10 pr-4 text-gray-900 ${
                      active ? 'bg-sky-50' : 'bg-white'
                    }`
                  }
                  value={field.id}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                        data-testid='table-field-filter'
                      >
                        {field.id}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 text-gray-900`}
                          data-testid='table-field-filter-selected'
                        >
                          <CheckIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              </React.Fragment>
            ))
          )}
        </Combobox.Options>
      </div>
    </Combobox>
  )
}

export default TableFieldsFilter
