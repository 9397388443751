import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import ProcessInstancesService from '../../services/ProcessInstances.service'

type ProcessStartFormType = {
  type?: string
  version?: number
}
export const useProcessStartFrom = ({ version, type }: ProcessStartFormType) => {
  const { org } = useParams()
  return useQuery<any>({
    queryKey: ['process-instances', org, 'form', type, version],
    queryFn: () => ProcessInstancesService.getStartFrom(org!, type!, version),
    enabled: !!org && !!type,
  })
}
