import React, { FC, Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon, PencilIcon } from '@heroicons/react/24/solid'
import { ImgWrapper } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'

export type Assignee = { id: string; name: string; icon?: string }

type Prop = {
  assignees: Assignee[]
  selectedAssignee: Assignee | null
  onSelect: (assigne: Assignee) => void
}

//TO DO: refactor and move to s-components

const SelectAssignee: FC<Prop> = ({ assignees, selectedAssignee, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const { t } = useTranslation()

  const filteredAssignees = searchTerm
    ? assignees.filter((assignee) => assignee.name.toLowerCase().includes(searchTerm.toLowerCase()))
    : assignees.filter((assignee) => assignee.id !== selectedAssignee?.id)

  return (
    <div className='relative inline-block text-left'>
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button className='flex items-center space-x-2'>
              {selectedAssignee && (
                <>
                  <div className='bg-[#7b87ae] w-7 h-7 mr-1 rounded-full overflow-hidden text-white flex items-center justify-center text-md'>
                    <ImgWrapper
                      className='w-full h-full'
                      src={selectedAssignee.icon}
                      onErrorComponent={<p>{selectedAssignee?.name?.charAt(0)?.toUpperCase()}</p>}
                    />
                  </div>
                  <span className='pr-3'>{selectedAssignee.name}</span>
                </>
              )}
              {!selectedAssignee && <span className='pr-3'>{t('ProcessInstance.setAssignee')}</span>}
              <PencilIcon className='w-4 h-4 text-gray-400 hover:text-gray-700' aria-hidden='true' />
            </Menu.Button>
            <Transition
              show={open}
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items
                static
                className='origin-top-right absolute mb-2 bottom-full w-56 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg focus:outline-none'
              >
                <div className='p-0'>
                  <label htmlFor='search' className='sr-only'>
                    {t('Common.search')}
                  </label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                      <MagnifyingGlassIcon className='h-4 w-4 text-gray-400' />
                    </div>
                    <input
                      id='search'
                      className='w-full pl-10 pr-3 py-2 leading-5 border-none rounded-t-md bg-white focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
                      placeholder={t('Common.search')!}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
                <div className='py-1 max-h-30 overflow-auto'>
                  {filteredAssignees.map((assignee) => (
                    <Menu.Item key={assignee.id}>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            onSelect(assignee)
                            setSearchTerm('')
                          }}
                          className={`${
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-900'
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        >
                          <div className='bg-[#7b87ae] w-7 h-7 mr-1 rounded-full overflow-hidden text-white flex items-center justify-center text-md'>
                            <ImgWrapper
                              className='w-full h-full'
                              src={assignee.icon}
                              onErrorComponent={<p>{selectedAssignee?.name?.charAt(0)?.toUpperCase()}</p>}
                            />
                          </div>
                          {assignee.name}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  )
}

export default SelectAssignee
