import { ExpandableSideMenuItem, GearIcon, SideMenuItem } from '@sistemiv/s-components'
import React, { FC, useEffect, useState } from 'react'
import classNames from '../../classNames'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const SettingsMenuItems: FC<{ isSideOpen: boolean }> = ({ isSideOpen }) => {
  const { org, nav, type } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [expanded, setExpanded] = useState<boolean>(false)

  const settingsMenu = [
    { key: 'processes', translation: t('Settings.settingsMenu.processes') },
    { key: 'flow', translation: t('Settings.settingsMenu.flow') },
    { key: 'variables', translation: t('Settings.settingsMenu.variables') },
    { key: 'webhooks', translation: t('Settings.settingsMenu.webhooks') },
    { key: 'filters', translation: t('Settings.settingsMenu.filters') },
    { key: 'permissions', translation: t('Settings.globalMenu.permissions') },
  ]

  useEffect(() => {
    nav === 'settings' ? setExpanded(true) : setExpanded(false)
  }, [nav])

  return (
    <>
      <ExpandableSideMenuItem
        onClick={() => {
          setExpanded((old) => !old)
          navigate(`/${org && org !== '' ? org : 'default'}/settings/processes`)
        }}
        expandedClasses={expanded ? 'rotate-90' : ''}
        selectedClasses={''}
        className={classNames(
          'active:text-white [&_svg]:active:fill-white leading-5',
          isSideOpen ? 'gap-x-3 px-9 py-3.5' : 'items-center justify-center py-3.5',
          nav === 'settings' ? 'bg-blue-500 text-white [&_svg]:fill-white hover:!bg-blue-500' : '',
        )}
      >
        <GearIcon className={classNames('w-5 h-5 fill-black')} />
        {isSideOpen && t('SideNav.settings')}
      </ExpandableSideMenuItem>
      {expanded && isSideOpen && (
        <>
          {settingsMenu.map((menuItem) => (
            <SideMenuItem
              classes={classNames(
                'pl-14 pr-9 py-3.5 leading-5 active:bg-sky-100 active:text-blue-500',
                nav === 'settings' && type === menuItem.key
                  ? 'bg-sky-100 hover:!bg-sky-100 text-blue-500 [&_svg]:fill-blue-500'
                  : '',
              )}
              key={`submenu-${menuItem.key}`}
              onClick={() => navigate(`/${org && org !== '' ? org : 'default'}/settings/${menuItem.key}`)}
            >
              {menuItem.translation}
            </SideMenuItem>
          ))}
        </>
      )}
    </>
  )
}

export default SettingsMenuItems
