import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { Editable, Slate, withReact } from 'slate-react'
import { createEditor, Transforms, Range } from 'slate'
import { Combobox, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon, PlusIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { Button, SpinnerIcon } from '@sistemiv/s-components'
import { useParams, useSearchParams } from 'react-router-dom'
import ExpressionsService from '../../../../services/Expressions.service'

type VariablesTextEditorProps = {
  options: string[]
  label: string
  placeholder: string
  //passedValue?: string
  //saveExpression: (expression) => void
  type: 'name' | 'description'
  //dataLoading?: boolean
}

const formatDescendantValue = (descendant): string => {
  let outputString = ''
  for (const paragraph of descendant) {
    for (const item of paragraph.children) {
      if ('type' in item) {
        outputString += `{{${item.value}}}`
      } else {
        outputString += item.text
      }
    }
  }
  return outputString
}

const formatStringValue = (inputString: string) => {
  const result: any = []
  const rootParagraph: any = { type: 'paragraph', children: [] }
  let currentVariable: any = {
    text: '',
  }

  for (let i = 0; i < inputString.length; i++) {
    if (inputString[i] === '{' && inputString[i + 1] === '{') {
      if (currentVariable) {
        rootParagraph.children.push(currentVariable)
      }
      currentVariable = {
        type: 'variable',
        value: '',
        children: [{ text: '' }],
      }
      i++
    } else if (inputString[i] === '}' && inputString[i + 1] === '}') {
      if (currentVariable) {
        rootParagraph.children.push(currentVariable)
        currentVariable = {
          text: '',
        }
      }
      i++
    } else {
      if ('value' in currentVariable) {
        currentVariable.value += inputString.at(i) ?? ''
      } else {
        currentVariable.text += inputString.at(i) ?? ''
      }
    }
  }
  if (currentVariable) {
    rootParagraph.children.push(currentVariable)
  }

  result.push(rootParagraph)

  return result
}

const VariablesTextEditor: FC<VariablesTextEditorProps> = ({
  options,
  label,
  placeholder,
  //passedValue,
  //saveExpression,
  //dataLoading = false,
  type,
}) => {
  const renderElement = useCallback((props) => <Element {...props} />, [])
  const editor = useMemo(() => withVaraiablesPlugin(withReact(createEditor())), [])
  const [searchParams] = useSearchParams()
  const { org } = useParams()
  const [loading, setisLoading] = useState<boolean>(false)
  const [saving, setSaving] = useState(false)
  const { t } = useTranslation()
  const [initialValue, setInitialValue] = useState('')
  const formattedInitialValue = useMemo(() => formatStringValue(initialValue ?? ''), [initialValue])
  const [value, setValue] = useState<any>(
    formattedInitialValue ?? [
      {
        type: 'paragraph',
        children: [{ text: '' }],
      },
    ],
  )
  const [query, setQuery] = useState('')

  useEffect(() => {
    const versionId = searchParams.get('version')
    if (!org || !versionId) return
    setisLoading(true)
    if (type === 'name') {
      ExpressionsService.getExpressionName(org, versionId).then((res) => {
        setInitialValue(res.expression)
        setisLoading(false)
      })
    } else {
      ExpressionsService.getExpressionDescription(org, versionId).then((res) => {
        setInitialValue(res.expression)
        setisLoading(false)
      })
    }
  }, [org, searchParams, type])

  useEffect(() => {
    editor.children = formattedInitialValue
    setValue(formattedInitialValue)
    setSaving(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedInitialValue])

  const saveExpression = (expression) => {
    const versionId = searchParams.get('version')
    if (!org || !versionId) return
    const stringExpression = formatDescendantValue(expression)
    if (type === 'name') {
      ExpressionsService.setExpressionName(org, versionId, stringExpression).then(() => {
        setInitialValue(stringExpression)
        // TODO: toastMsg
      })
    } else {
      ExpressionsService.setExpressionDescription(org, versionId, stringExpression).then(() => {
        setInitialValue(stringExpression)
        // TODO: toastMsg
      })
    }
  }
  const filteredOptions =
    query === ''
      ? options
      : options.filter((o) => o.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')))

  const insertTag = (tag: string) => {
    const element = {
      type: 'variable',
      value: tag,
      children: [{ text: '' }],
    }
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      Transforms.insertNodes(editor, element)
      Transforms.move(editor)
      Transforms.insertText(editor, ' ')
      Transforms.move(editor)
    }
  }

  return loading ? (
    <div className='w-full flex justify-center pt-5'>
      <SpinnerIcon className='w-8 h-8 text-sky-500' />
    </div>
  ) : (
    <Slate editor={editor} value={value} onChange={(v) => setValue(v)}>
      <div>
        <p className='text-gray-400'>{label}</p>
        <div className='rounded-md border border-gray-300 px-3 py-2 space-y-2'>
          <Editable renderElement={renderElement} placeholder={placeholder} />
          <Combobox
            onChange={(v: string) => {
              insertTag(v)
            }}
          >
            <div className='relative'>
              <div className='relative flex w-full justify-between'>
                <Combobox.Button className='border-none text-blue-500 flex items-center relative'>
                  <PlusIcon className='w-4 h-4' />
                  {t('Settings.expressions.variable')}
                </Combobox.Button>
                {JSON.stringify(formattedInitialValue) !== JSON.stringify(value) && (
                  <div className='flex gap-x-2'>
                    <Button
                      className='bg-white rounded-md border border-sky-500 !py-1 !px-1 hover:bg-slate-50'
                      onClick={() => {
                        saveExpression(value)
                        setSaving(true)
                      }}
                      disabled={saving}
                    >
                      {saving ? (
                        <SpinnerIcon className='w-4 h-4 text-sky-500' />
                      ) : (
                        <CheckIcon className='w-4 h-4 text-sky-500' />
                      )}
                    </Button>
                    <Button
                      className='bg-white rounded-md border border-gray-300 !py-1 !px-1 hover:bg-slate-50'
                      onClick={() => {
                        setValue(formattedInitialValue)
                        editor.children = formattedInitialValue
                      }}
                      disabled={saving}
                    >
                      <XMarkIcon className='w-4 h-4 text-gray-400' />
                    </Button>
                  </div>
                )}
              </div>
              <Transition
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
                afterLeave={() => setQuery('')}
              >
                <Combobox.Options className='absolute mt-1 py-1 z-10 rounded-md bg-white shadow-xl ring-1 ring-black ring-opacity-5'>
                  <div className='relative w-full border-b border-gray-300'>
                    <Combobox.Input
                      displayValue={(value) => (value ? '' : '')}
                      placeholder='Search'
                      className='focus:border-none focus:ring-0 border-none pl-8 leading-5'
                      onChange={(e) => setQuery(e.target.value)}
                    />
                    <span className='absolute left-0 pl-2 text-gray-400 inset-y-0 flex items-center justify-center'>
                      <MagnifyingGlassIcon className='w-5 h-5' />
                    </span>
                  </div>
                  {filteredOptions.length === 0 && query !== '' ? (
                    <div className='px-3 py-2 text-gray-700 cursor-default select-none'>
                      {' '}
                      {t('Common.nothingFound')}
                    </div>
                  ) : (
                    filteredOptions.map((o) => (
                      <Combobox.Option
                        value={o}
                        key={o}
                        className='px-3 py-2 hover:bg-slate-100 cursor-pointer select-none'
                      >
                        {o}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>
      </div>
    </Slate>
  )
}
export type ElementPops = {
  attributes: any
  children: any
  element: any
}

const Element: FC<ElementPops> = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'variable':
      return <VariableElement {...{ attributes, children, element }} />
    default:
      return <span {...attributes}>{children}</span>
  }
}

const VariableElement: FC<ElementPops> = ({ attributes, children, element }) => {
  return (
    <span className='bg-gray-200 text-slate-500 border border-slate-500 rounded-full px-1.5 pb-0.5' {...attributes}>
      {element.value}
      {children}
    </span>
  )
}

const withVaraiablesPlugin = (editor) => {
  const { isInline, isVoid, apply } = editor

  editor.apply = (op) => {
    apply(op)
  }

  editor.isInline = (element) => {
    return element.type === 'variable' ? true : isInline(element)
  }

  editor.isVoid = (element) => {
    return element.type === 'variable' ? true : isVoid(element)
  }

  return editor
}

export default React.memo(VariablesTextEditor)
