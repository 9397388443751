import { useMutation, useQueryClient } from '@tanstack/react-query'
import ProcessInstancesService from '../../../services/ProcessInstances.service'
import { useParams } from 'react-router-dom'

type BulkUpdatePayload = {
  option: string
  actionString: string
  selectedIds: string[]
  body: any
}

export const useProcessInstancesBulkUpdate = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()

  return useMutation<any, Error, BulkUpdatePayload>({
    mutationKey: ['bulk-update'],
    mutationFn: async ({ actionString, selectedIds, body, option }) => {
      await Promise.all(
        selectedIds.map((row) => ProcessInstancesService.bulkEdit(org!, option, row, actionString, body)),
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['process-instances'] })
    },
  })
}
