import { useQuery } from '@tanstack/react-query'
import ProcessInstancesService from '../../services/ProcessInstances.service'
import { useParams } from 'react-router-dom'

type SideMenuType = {
  enabled?: boolean
}

export const useSideMenu = ({ enabled }: SideMenuType) => {
  const { org } = useParams()
  return useQuery<any>({
    queryKey: ['side-menu', org],
    queryFn: () => ProcessInstancesService.listSideMenuItems(org!),
    enabled: !!org && enabled,
    staleTime: 0,
  })
}
