import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ProcessInstancesService from '../../services/ProcessInstances.service'

type ProcessInstancesOptions = {
  processDefinitionId: string
  processInstanceId?: string
}

export const useProcessDiagram = ({ processDefinitionId, processInstanceId }: ProcessInstancesOptions) => {
  const { org } = useParams()
  return useQuery<any>({
    queryKey: ['process-instances-diagram', org, processDefinitionId, processInstanceId],
    queryFn: () => ProcessInstancesService.getProcessDiagram(org!, processDefinitionId, processInstanceId),
    enabled: !!org && !!processDefinitionId && processDefinitionId !== '' && processInstanceId !== '',
    staleTime: 0,
  })
}
