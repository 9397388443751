import { useQuery } from '@tanstack/react-query'
import FiltersService from '../../../../services/Filters.service'

type FiltersOperationsType = {
  organization?: string
  key?: string
  type?: string
}

export const useFiltersOperations = ({ organization, key, type }: FiltersOperationsType) => {
  return useQuery({
    queryKey: ['filters-operations', organization, key, type],
    queryFn: () => FiltersService.getFieldOperations(organization!, key!, type!),
    enabled: !!key && !!organization && !!type,
  })
}
