import React, { FC } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useVariablesByScope } from '../../../../repositories/settings/processes/variables/variables-scope.repository'
import { useTranslation } from 'react-i18next'
import VariablesTextEditor from './VariablesTextEditor'
import { SpinnerIcon } from '@sistemiv/s-components'

const ExpressionsPage: FC = () => {
  const { org } = useParams()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()

  const { data: variables = [], isLoading: loading } = useVariablesByScope({
    organization: org,
    key: searchParams.get('key') ?? undefined,
    id: searchParams.get('version') ?? undefined,
    params: { scope: 'StartTime' },
  })

  return (
    <div>
      <div className='p-8'>
        {loading ? (
          <div className='w-full flex justify-center pt-5'>
            <SpinnerIcon className='w-8 h-8 text-sky-500' />
          </div>
        ) : (
          <div className='space-y-6 w-full max-w-3xl'>
            <VariablesTextEditor
              key={'name'}
              options={variables}
              label={t('Settings.expressions.instanceName')}
              placeholder={t('Settings.expressions.addDescription')}
              type='name'
            />
            <VariablesTextEditor
              key={'description'}
              options={variables}
              label={t('Settings.expressions.instanceDescription')}
              placeholder={t('Settings.expressions.addDescription')}
              type='description'
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ExpressionsPage
