import React, { useState, FC } from 'react'
import { useTranslation } from 'react-i18next'

type AutoDescriptionProps = {
  text: string
}
const AutoDescription: FC<AutoDescriptionProps> = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { t } = useTranslation()
  const currentFourLineTextLength = 104

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const trunkedText =
    text.length <= currentFourLineTextLength || !isExpanded ? text : `${text.slice(0, currentFourLineTextLength)}...`

  return (
    <div>
      <div>{trunkedText}</div>

      {text.length > currentFourLineTextLength && (
        <button
          className='text-[color:var(--link-default,#1C63EA)] text-center text-sm not-italic font-normal leading-[22px] tracking-[-0.42px]
'
          onClick={toggleExpand}
        >
          {!isExpanded ? t('ProcessInstance.seeLess') : t('ProcessInstance.seeMore')}
        </button>
      )}
    </div>
  )
}

export default AutoDescription
