import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Base64SVG, ImgWrapper, TabPanel, Tabs } from '@sistemiv/s-components'
import { TableField } from '@sistemiv/s-components/dist/esm/components/table/Table'
import React, { FC, Fragment, useRef, useState } from 'react'
import classNames from '../../../../classNames'

type AddProcessComboboxProps = {
  value: TableField | null
  onChange: (value: any) => void
  options?: TableField[]
  inputPlaceholder?: string
  placeholder?: string
  assignee?: boolean
  topMenu?: boolean
}

const AddProcessCombobox: FC<AddProcessComboboxProps> = ({
  value,
  onChange,
  options = [],
  placeholder,
  inputPlaceholder,
  assignee,
  topMenu,
}) => {
  const [query, setQuery] = useState('')
  const tabs = ['Users', 'Groups', 'Org chart']
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const comboboxButtonRef = useRef<HTMLButtonElement>(null)
  const comboboxInputRef = useRef<HTMLInputElement>(null)

  const filteredOptions =
    query === ''
      ? assignee
        ? options.filter((o) => o.tab === selectedTab)
        : options
      : assignee
      ? options.filter(
          (option) =>
            option.value.toLowerCase().replace(/\s+/g, '').startsWith(query.toLowerCase().replace(/\s+/g, '')) &&
            option.tab === selectedTab,
        )
      : options.filter((option) =>
          option.value.toLowerCase().replace(/\s+/g, '').startsWith(query.toLowerCase().replace(/\s+/g, '')),
        )

  return (
    <Combobox value={value} onChange={onChange}>
      {({ open }) => (
        <div className='relative'>
          <div className='relative w-full cursor-default rounded-md bg-white text-left'>
            {!open && (
              <div
                className='w-full flex items-center gap-x-3 border border-gray-300 rounded-md py-2 pr-9 pl-3 text-gray-900'
                onClick={() => {
                  if (!open) {
                    comboboxButtonRef?.current?.click()
                  }
                }}
              >
                {value && (
                  <ImgWrapper
                    className='rounded-full w-6 h-6'
                    src={value?.icon}
                    onErrorComponent={
                      <div className='rounded-full bg-slate-400 text-white flex items-center justify-center aspect-square w-6'>
                        <p>{value?.value?.[0]?.toUpperCase()}</p>
                      </div>
                    }
                  />
                )}

                {value ? <p>{value?.value}</p> : <p className='text-gray-400'>{placeholder}</p>}
              </div>
            )}
            {open && (
              <Combobox.Input
                ref={comboboxInputRef}
                onChange={(e) => setQuery(e.target.value)}
                displayValue={(value: any) => {
                  return open ? '' : value
                }}
                placeholder={inputPlaceholder}
                className={`w-full border-gray-300 rounded-md py-2 text-gray-900 ${
                  open
                    ? 'ring-2 focus:ring-2 ring-sky-100 !border-sky-100 focus:border-sky-100 focus:ring-sky-100 pl-9 pr-3'
                    : 'pr-9 pl-3'
                }`}
              />
            )}
            <span className={`absolute inset-y-0 left-0 items-center pl-2 ${open ? 'flex' : 'hidden'}`}>
              <MagnifyingGlassIcon className='w-4 h-4 text-gray-400' />
            </span>
            <Combobox.Button
              onClick={() => {
                comboboxInputRef.current?.focus()
              }}
              className={`absolute inset-y-0 right-0 items-center pr-2 ${open ? 'hidden' : 'flex'}`}
              ref={comboboxButtonRef}
            >
              <ChevronDownIcon className='w-4 h-4 text-gra y-400' />
            </Combobox.Button>
          </div>

          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options
              className={`absolute z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${
                topMenu ? 'mb-1 bottom-full' : 'mt-1 top-full'
              }`}
            >
              {assignee ? (
                <Tabs active={selectedTab} tabs={tabs} onClick={setSelectedTab} className='px-3 [&+div]:!pt-2'>
                  <TabPanel active={selectedTab} tab='Users'>
                    {filteredOptions
                      .filter((o) => o.tab === 'Users')
                      .map((o) => (
                        <Combobox.Option key={o.id} value={o} className='hover:bg-slate-100 cursor-pointer select-none'>
                          <div
                            className={classNames(
                              'flex items-center gap-x-3 py-2 px-3',
                              value?.id === o.id ? 'opacity-100' : 'opacity-70',
                            )}
                          >
                            <ImgWrapper
                              className={classNames(
                                'rounded-full w-6 h-6 border-2',
                                value?.id === o.id ? 'border-sky-500' : 'border-transparent',
                              )}
                              src={o.icon}
                              onErrorComponent={() => (
                                <div className='rounded-full bg-slate-400 text-white flex items-center justify-center aspect-square w-6'>
                                  <p>{o?.value?.[0]?.toUpperCase()}</p>
                                </div>
                              )}
                            />

                            <p>{o.value}</p>
                          </div>
                        </Combobox.Option>
                      ))}
                  </TabPanel>
                  <TabPanel active={selectedTab} tab='Groups'>
                    {filteredOptions
                      .filter((o) => o.tab === 'Groups')
                      .map((o) => (
                        <Combobox.Option key={o.id} value={o} className='hover:bg-slate-100 cursor-pointer select-none'>
                          <div
                            className={classNames(
                              'flex items-center gap-x-3 py-2 px-3',
                              value?.id === o.id ? 'opacity-100' : 'opacity-70',
                            )}
                          >
                            <Base64SVG base64image={o.icon ?? ''} className='&>svg:w-4 &>svg:h-4' />
                            <p>{o.value}</p>
                          </div>
                        </Combobox.Option>
                      ))}
                  </TabPanel>
                  <TabPanel active={selectedTab} tab='Org chart'>
                    {filteredOptions
                      .filter((o) => o.tab === 'Org chart')
                      .map((o) => (
                        <Combobox.Option key={o.id} value={o} className='hover:bg-slate-100 cursor-pointer select-none'>
                          <div
                            className={classNames(
                              'flex items-center gap-x-3 py-2 px-3',
                              value?.id === o.id ? 'opacity-100' : 'opacity-70',
                            )}
                          >
                            <CheckIcon
                              className={classNames(
                                'w-6 h-6',
                                value?.id === o.id ? 'text-sky-500' : 'text-transparent',
                              )}
                            />
                            <p>{o.value}</p>
                          </div>
                        </Combobox.Option>
                      ))}
                  </TabPanel>
                </Tabs>
              ) : (
                filteredOptions.map((o) => (
                  <Combobox.Option key={o.id} value={o} className='hover:bg-slate-100 cursor-pointer select-none'>
                    <div
                      className={classNames(
                        'flex items-center gap-x-3 py-2 px-3',
                        value?.id === o.id ? 'opacity-100' : 'opacity-70',
                      )}
                    >
                      <ImgWrapper
                        className={classNames(
                          'rounded-full w-6 h-6 border-2',
                          value?.id === o.id ? 'border-sky-500' : 'border-transparent',
                        )}
                        src={o.icon}
                        onErrorComponent={
                          <div className='rounded-full bg-slate-400 text-white flex items-center justify-center aspect-square w-6'>
                            <p>{o.value?.[0]}</p>
                          </div>
                        }
                      />

                      <p>{o.value}</p>
                    </div>
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      )}
    </Combobox>
  )
}

export default AddProcessCombobox
