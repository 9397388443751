import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import AttachmentPreviewModal from './AttachmentPreviewModal'
import Table, { TableAction, TableHeader, TableRow } from '@sistemiv/s-components/dist/esm/components/table/Table'
import { Button, Tooltip } from '@sistemiv/s-components'
import { FileData, GalleryData, Item } from './AttachmentsTab'
import { PDFDocument } from 'pdf-lib'
import ProcessInstancesService from '../../../services/ProcessInstances.service'
import { useParams } from 'react-router-dom'
import { saveAs } from 'file-saver'
import { useTranslation } from 'react-i18next'

type ProcessInstanceAttachmentsProps = {
  headers: TableHeader[]
  data: TableRow[]
  actions?: TableAction[]
  onAttachmentClicked?: (attachment: TableRow) => void
  onDownloadGalleryItemClicked?: (id: string) => void
  onPrintGalleryItemClicked?: (item: Item | null) => void
  selectedItem: Item | null
  galleryData?: GalleryData
  filesData: FileData
  isPreviewOpen: boolean
  setPreviewOpen: Dispatch<SetStateAction<boolean>>
}
const ProcessInstanceAttachments: FC<ProcessInstanceAttachmentsProps> = ({
  headers,
  data,
  actions,
  //galleryData,
  selectedItem,
  isPreviewOpen,
  setPreviewOpen,
  onDownloadGalleryItemClicked,
  onPrintGalleryItemClicked,
  filesData,
}) => {
  const [highlightedRows, setHighlightedRows] = useState<TableRow[]>([])
  //const [isGalleryOpen, setGalleryOpen] = useState(false)
  const [currentItem, setCurrentItem] = useState<Item | null>(null)
  const { org } = useParams()
  const [isPdfLoading, setIsPdfLoading] = useState(false)
  const [isDownloadLoading, setIsDownloadLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (isPreviewOpen && selectedItem) setCurrentItem(selectedItem)
    else if (!isPreviewOpen) setCurrentItem(null)
  }, [selectedItem, isPreviewOpen])

  const handleNextItem = () => {
    const index = filesData.findIndex((item) => item.fileName === currentItem?.fileName)
    if (index + 1 < filesData.length) {
      ProcessInstancesService.downloadBlobFile(org!, filesData[index + 1].id).then((res) => {
        const contentType = filesData[index + 1].mimeType
        const blob = new Blob([res.blob], { type: contentType })
        const fileUrl = URL.createObjectURL(blob)
        setCurrentItem({
          id: filesData[index + 1].id,
          fileName: filesData[index + 1].fileName,
          url: fileUrl,
          mimeType: contentType,
        })
      })
    } else {
      ProcessInstancesService.downloadBlobFile(org!, filesData[0].id).then((res) => {
        const contentType = filesData[0].mimeType
        const blob = new Blob([res.blob], { type: contentType })
        const fileUrl = URL.createObjectURL(blob)
        setCurrentItem({ id: filesData[0].id, fileName: filesData[0].fileName, url: fileUrl, mimeType: contentType })
      })
    }
  }

  const handlePreviousItem = () => {
    const index = filesData.findIndex((item) => item.id === currentItem?.id)
    if (index - 1 >= 0) {
      ProcessInstancesService.downloadBlobFile(org!, filesData[index - 1].id).then((res) => {
        const contentType = filesData[index - 1].mimeType
        const blob = new Blob([res.blob], { type: contentType })
        const fileUrl = URL.createObjectURL(blob)
        setCurrentItem({
          id: filesData[index - 1].id,
          fileName: filesData[index - 1].fileName,
          url: fileUrl,
          mimeType: contentType,
        })
      })
    } else {
      ProcessInstancesService.downloadBlobFile(org!, filesData[filesData.length - 1].id).then((res) => {
        const contentType = filesData[filesData.length - 1].mimeType
        const blob = new Blob([res.blob], { type: contentType })
        const fileUrl = URL.createObjectURL(blob)
        setCurrentItem({
          id: filesData[filesData.length - 1].id,
          fileName: filesData[filesData.length - 1].fileName,
          url: fileUrl,
          mimeType: contentType,
        })
      })
    }
  }

  function base64ToBlob(base64DataUri) {
    const byteString = atob(base64DataUri.split(',')[1])
    const mimeString = base64DataUri.split(',')[0].split(':')[1].split(';')[0]
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ab], { type: mimeString })
  }

  async function onBulkPrint() {
    setIsPdfLoading(true)
    const mergedPdf = await PDFDocument.create()
    if (highlightedRows && highlightedRows.length > 0) {
      for (const row of highlightedRows) {
        const existingPdfBytes = await ProcessInstancesService.downloadArrayBufferFile(org!, row['id'].value)
        const document = await PDFDocument.load(existingPdfBytes)

        const copiedPages = await mergedPdf.copyPages(document, document.getPageIndices())
        copiedPages.forEach((page) => mergedPdf.addPage(page))
      }
      const pdfDataUri = await mergedPdf.saveAsBase64({
        dataUri: true,
      })
      setIsPdfLoading(false)
      const blob = base64ToBlob(pdfDataUri)
      const fileUrl = URL.createObjectURL(blob)
      const iframe = document.createElement('iframe')
      document.body.appendChild(iframe)
      iframe.style.display = 'none'
      iframe.src = fileUrl
      iframe.onload = () => {
        iframe.focus()
        iframe.contentWindow?.print()
      }
    }
  }

  const onBulkDownload = async () => {
    setIsDownloadLoading(true)
    const blobArray: any[] = []
    if (highlightedRows && highlightedRows.length > 0) {
      for (const row of highlightedRows) {
        const fileBlob = await ProcessInstancesService.downloadBlobFile(org!, row['id'].value, row['attachment'].value)
        blobArray.push(fileBlob)
      }
      blobArray.forEach((file) => {
        saveAs(file.blob, file.fileName)
      })
      setIsDownloadLoading(false)
    }
  }

  return (
    <div>
      {filesData.length > 0 ? (
        <>
          <div className='flex items-center gap-x-3 mb-5'>
            <Tooltip text={t('ProcessInstance.attachments.printTooltip')} tooltipClassName='left-full'>
              <Button
                className='text-blue-500 border min-w-32 border-blue-500 disabled:text-gray-300 disabled:border-gray-300'
                disabled={
                  highlightedRows.length === 0 ||
                  !highlightedRows.every((obj) => (obj.attachment.value as string).includes('.pdf')) ||
                  isDownloadLoading
                }
                onClick={() => onBulkPrint()}
                loading={isPdfLoading}
              >
                {t('ProcessInstance.attachments.printPdf')}
              </Button>
            </Tooltip>
            <Tooltip text={t('ProcessInstance.attachments.downloadTooltip')} tooltipClassName='left-full'>
              <Button
                className='text-blue-500 border min-w-32 border-blue-500 disabled:text-gray-300 disabled:border-gray-300'
                disabled={highlightedRows.length === 0 || isPdfLoading}
                onClick={onBulkDownload}
                loading={isDownloadLoading}
              >
                {t('ProcessInstance.attachments.downloadFiles')}
              </Button>
            </Tooltip>
            {/* <Button className='text-blue-500 border border-blue-500' onClick={() => setGalleryOpen(true)}>
          <div data-testid='view-gallery'>View gallery</div>
        </Button> */}
          </div>
          <Table
            headers={headers}
            data={data}
            actions={actions}
            selectable
            selectedRows={highlightedRows}
            onSelectedRowsChange={setHighlightedRows}
            hasBulkActions={true}
            fieldClassName='max-w-fit'
          />
        </>
      ) : (
        <div>{t('ProcessInstance.attachments.noAttachments')}</div>
      )}
      {/* <AttachmentGalleryModal
        open={isGalleryOpen}
        setOpen={setGalleryOpen}
        data={galleryData!}
        onDownloadClick={onDownloadGalleryItemClicked}
        onPrintClick={onPrintGalleryItemClicked}
      /> */}
      {currentItem && (
        <AttachmentPreviewModal
          open={isPreviewOpen}
          setOpen={setPreviewOpen}
          onDownloadClick={onDownloadGalleryItemClicked}
          onExitClick={() => {
            setPreviewOpen(false)
          }}
          onNextItemClick={handleNextItem}
          onPreviousItemClick={handlePreviousItem}
          currentItem={currentItem}
          onPrintClick={onPrintGalleryItemClicked}
        />
      )}
    </div>
  )
}

export default ProcessInstanceAttachments
