import React, { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ProcessInstanceContextProvider from '../../context/ProcessInstanceContextProvider'
import ProcessInstancesContextProvider from '../../context/ProcessInstancesContextProvider'
// import UserTasksContextProvider from '../../context/UserTasksContextProvider'
import Home from '../home/Home'
import ProcessInstance from '../process-instance/ProcessInstance'
import ProcessInstances from '../process-instances/ProcessInstances'
import UserTasks from '../user-tasks/UserTasks'
import SettingsSwitch from '../user-settings/SettingsSwitch'
import NotAuthorized from '../../pages/NotAuthorized'
import { useGetApplications } from '../../repositories/user/user-applications.repository'
import ProcessInstanceDashboard from '../process-instance-dashboard/ProcessInstanceDashboard'
import { SignalRContext } from '../../pages/Dashboard'

const DashboardSwitch: FC = () => {
  const { nav, type, id, org } = useParams()
  const { data: applications } = useGetApplications()
  const navigate = useNavigate()

  useEffect(() => {
    if (!nav || !applications || applications?.length === 0) return
    if (
      nav === 'process-instance' &&
      !applications?.find((app) => app.code === 's-process')?.permissions?.find((perm) => perm === 'manage')
    )
      navigate('/not-authorized')
    if (
      nav === 'settings' &&
      !applications?.find((app) => app.code === 's-process')?.permissions?.find((perm) => perm === 'design')
    )
      navigate('/not-authorized')
  }, [applications, nav, navigate])

  useEffect(() => {
    if (nav === 'process-instance') {
      const timer = setTimeout(() => {
        if (!type || !SignalRContext.connection || SignalRContext.connection?.state !== 'Connected') return
        console.log('effect')
        console.log(type)
        console.log(SignalRContext.connection)
        SignalRContext.invoke('add-filter', 'processDefinitionKey', type)
      }, 1000)
      return () => {
        SignalRContext.invoke('remove-filter', 'processDefinitionKey', type)
        clearTimeout(timer)
      }
    } else if (nav === 'tasks') {
      const timer = setTimeout(() => {
        console.log('effect')
        console.log(SignalRContext.connection?.state)
        if (!SignalRContext.connection || SignalRContext.connection?.state !== 'Connected') return
        console.log('effect')
        console.log(SignalRContext.connection)
        SignalRContext.invoke('add-filter', 'module', `${org}:tasklist`)
      }, 1000)
      return () => {
        console.log('return')
        SignalRContext.invoke('remove-filter', 'module', `${org}:tasklist`)
        clearTimeout(timer)
      }
    }
  }, [type, nav, org])

  switch (nav) {
    case 'process-instance-dashboard':
      return <ProcessInstanceDashboard />
    case 'tasks':
      return (
        // <UserTasksContextProvider>
        <UserTasks />
        // </UserTasksContextProvider>
      )
    case 'process-instance':
      if (id && applications?.find((app) => app.code === 's-process')?.permissions?.find((perm) => perm === 'manage')) {
        return (
          <ProcessInstancesContextProvider key={type}>
            <ProcessInstanceContextProvider>
              <ProcessInstance />
            </ProcessInstanceContextProvider>
          </ProcessInstancesContextProvider>
        )
      } else if (applications?.find((app) => app.code === 's-process')?.permissions?.find((perm) => perm === 'manage'))
        return (
          <ProcessInstancesContextProvider key={type}>
            <ProcessInstances key={type} />
          </ProcessInstancesContextProvider>
        )
      else return <NotAuthorized />
    case 'settings':
      if (applications?.find((app) => app.code === 's-process')?.permissions?.find((perm) => perm === 'design'))
        return <SettingsSwitch />
      else return <NotAuthorized />
    default:
      return <Home />
  }
}

export default DashboardSwitch
