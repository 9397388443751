import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import VariablesService from '../../../../../services/Variables.service'

export const useAddVariables = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()
  const [searchParams] = useSearchParams()
  const key = searchParams.get('key')

  return useMutation<any, Error, { sectionId: string | null; variables: any[] }>({
    mutationKey: ['add-variables'],
    mutationFn: (body) => VariablesService.addVariables(org!, key!, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['variables'] })
      queryClient.invalidateQueries({ queryKey: ['process-fields', org] })
    },
  })
}
