import { SortableTree, SortableTreeItem } from '@sistemiv/s-components'
import React, { FC, useEffect, useState } from 'react'
import { IconOption } from '@sistemiv/s-components/dist/esm/components/icon-picker/IconPicker'
import { useParams } from 'react-router-dom'
import { TreeItem } from '@sistemiv/s-components/dist/esm/types/types'
import { setProperty } from '@sistemiv/s-components/dist/esm/treeUtils'
import {
  ProcessDefinition,
  ProcessDefinitionCategory,
  ProcessDefinitionResponse,
} from '../../../models/ProcessDefinitionSettings'
import ProcessItemVersion from './process-configuration-tree/ProcessItemVersion'
import CategoryItem from './process-configuration-tree/CategoryItem'
import ProcessItem from './process-configuration-tree/ProcessItem'
import ProcessDefinitionsSettings from '../../../services/ProcessDefinitionsSettings'
import useLocalStorage from 'use-local-storage'
import { ColorOption } from '../../../models/ColorOption'
import { initColors, initIcons } from '../../../utils/data'
import { useRemoveUserFromOrg } from '../../../repositories/settings/processes/processes/mutations/remove-user-from-org.repository'
import { useAddUserInOrg } from '../../../repositories/settings/processes/processes/mutations/add-user-in-org.repository'
import { useListAllUsersInOrg } from '../../../repositories/settings/processes/processes/list-all-users-in-org.repository'
import { useQueryClient } from '@tanstack/react-query'
import { useListUsersInOrg } from '../../../repositories/settings/processes/processes/list-users-in-org.repository'
import { useChangeUserAccess } from '../../../repositories/settings/processes/processes/mutations/change-user-access.repository'
import { imageBase } from '../../../services/http-common'
import AddProcessModal from './add-process-modal/AddProcessModal'
import AccessModal from './access-modal/AccessModal'
import ProcessInstancesService from '../../../services/ProcessInstances.service'

type ProcessConfigurationProps = {
  refreshData?(showSpinner?): void
  handleCheckFile?(base64: string): Promise<any>
  processDefinitions: ProcessDefinitionResponse[]
}
const ProcessesConfiguration: FC<ProcessConfigurationProps> = ({
  refreshData,
  handleCheckFile,
  processDefinitions,
}) => {
  const { org } = useParams()
  const [item, setItem] = useState<any>(undefined)
  const { data: usersInOrg } = useListAllUsersInOrg()
  const [acessModalOpen, setAccessModalOpen] = useState(false)
  const [addProcessOpen, setAddProcessOpen] = useState(false)
  const [treeItems, setTreeItems] = useState<TreeItem[]>([])
  const [collapsedVersions, setCollapsedVersions] = useState<string[]>([])
  const queryClient = useQueryClient()
  const { data: acessModalProcessData, isLoading: accessModalProcessDataLoading } = useListUsersInOrg(
    item && !item.id.includes('CategoryItem') ? item.id : '',
  )

  const { mutate: removeUserFromOrg } = useRemoveUserFromOrg()
  const { mutate: addUserInOrg } = useAddUserInOrg()
  const { mutate: changeUserAccess } = useChangeUserAccess()

  const [colors, setColors] = useLocalStorage<ColorOption>('colors', initColors)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [icons /*, setIcons*/] = useLocalStorage<IconOption[]>('icons', initIcons)

  const findParent = (array: any, id: string, currentParent = null) => {
    for (const item of array) {
      if (item.id === id) {
        return currentParent
      }
      if ('leaves' in item) {
        const parent = findParent(item.leaves, id, item)
        if (parent) {
          return parent
        }
      }
    }
  }

  const handleReorder = async (activeId: string, overId: string, parentId: string | null) => {
    if (!org) return
    const overProcess = find(processDefinitions, overId)
    const activeProcess = find(processDefinitions, activeId)
    const activeParent = findParent(processDefinitions, activeId)
    const overParent = findParent(processDefinitions, overId)
    if (overProcess) {
      let newPosition = overProcess.position
      if (!overParent) {
        if (parentId) {
          if (overId === parentId) {
            newPosition = 0
          } else {
            const parentProcess = find(processDefinitions, parentId)
            newPosition = parentProcess.leaves.length
          }
        } else {
          if (activeParent) {
            if (activeParent.id === overProcess.id) {
              newPosition = overProcess.postion
            } else {
              if (activeParent.position < overProcess.position) {
                newPosition = overProcess.position + 1
              }
            }
          }
        }
      } else {
        if (activeId === overId) {
          newPosition = activeParent.position + 1
        } else if (parentId) {
          const currPosition = activeParent ? activeParent.position : activeProcess.position
          if (currPosition < overParent.position) {
            newPosition = overProcess.position + 1
          }
        } else if (!parentId) {
          if (activeParent) {
            newPosition = overParent.position + 1
          } else {
            newPosition = overParent.position
          }
        }
      }

      await ProcessDefinitionsSettings.update(org, activeId, 'position', {
        position: newPosition,
        parentItemId: parentId,
      })

      refreshData?.(false)
    }
  }

  // TODO: test
  const handleAddProcess = (body: any) => {
    if (!org) return
    if (body.addingVersion && body.id !== '') {
      ProcessDefinitionsSettings.addProcessVersion(org, body.id, {
        versionTag: body.versionName,
        deploymentPackage: body.base64File,
        makeCurrent: body.currentVersionSelected,
      }).then(() => {
        setAddProcessOpen(false)
        refreshData?.()
        queryClient.invalidateQueries({ queryKey: ['process-definition-versions', org] })
      })
    } else {
      if (item && item.id && item.id !== '') {
        body['parentItemId'] = item.id
      }
      ProcessDefinitionsSettings.create(org, body).then(() => {
        setAddProcessOpen(false)
        refreshData?.()
        queryClient.invalidateQueries({ queryKey: ['process-definition-versions', org] })
      })
    }
  }

  const find = (array: any, id: string): any | undefined => {
    let result = undefined
    array.some((o) => (result = o.id === id ? o : find(o.leaves || [], id)))
    return result
  }

  const handleCollapse = (id: string) => {
    setTreeItems((items) =>
      setProperty(items, id, 'collapsed', (value) => {
        return !value
      }),
    )
  }

  useEffect(() => {
    setCollapsedVersions(processDefinitions.map((process) => process.id))
    setTreeItems((oldItems) => {
      const newItems: TreeItem[] = processDefinitions.map((item) => ({
        id: item.id,
        children:
          item.type === 'CategoryItem'
            ? (item as ProcessDefinitionCategory).leaves?.map((child) => ({ id: child.id, children: [], leaf: true }))
            : [],
        collapsed: oldItems.find((i) => i.id === item.id)?.collapsed,
        leaf: item.type === 'ProcessItem',
      }))
      return newItems
    })
  }, [processDefinitions])

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['list-all-users-in-org'] })
  }, [org, queryClient])

  const handleAddAccess = (value, item, accessLevel) => {
    addUserInOrg({
      itemId: item.id,
      processAccessId: item.processAccessId,
      userId: value.objectId,
      accessLevel: accessLevel,
    })
  }
  const handleDeleteCategory = (id: string) => {
    if (!org || !id) return
    try {
      ProcessInstancesService.deleteProcessCategory(org, id).then(() => {
        refreshData?.()
      })
    } catch (error) {
      console.log(error)
    }
  }
  const handleRemoveAccess = (value, item) => {
    removeUserFromOrg({ itemId: item.id, processAccessId: item.processAccessId, userId: value.objectId })
  }

  const handleChangeUserAccess = (value, user) => {
    changeUserAccess({
      userId: user.objectId,
      itemId: item.id,
      processAccessId: item.processAccessId,
      accessLevel: value.id,
    })
  }

  return (
    <div className='relative'>
      <div>
        <SortableTree
          items={treeItems}
          setItems={setTreeItems}
          onReorder={handleReorder}
          renderElement={(id, depth, treeChildren, collapsed, clone) => {
            const process = find(processDefinitions, id)
            if (!process) return null
            return (
              <SortableTreeItem
                key={id}
                id={id}
                depth={depth}
                childCount={treeChildren.length}
                onCollapse={() => handleCollapse(id)}
                collapsed={collapsed}
                clone={clone}
                versionsCollapsed={collapsedVersions.includes(id)}
                versions={
                  process.type === 'ProcessItem' &&
                  (process as ProcessDefinition).versions &&
                  (process as ProcessDefinition).versions?.map((version) => (
                    <ProcessItemVersion
                      key={version.id}
                      disableActivate={version.versionActivationDisabled}
                      version={version}
                      versions={process.versions}
                      processId={process.id}
                      processKey={process.processDefinitionKey}
                      depth={depth}
                      clone={clone}
                      refreshData={refreshData}
                    />
                  ))
                }
              >
                {process.type === 'CategoryItem' ? (
                  <CategoryItem
                    item={process}
                    icons={icons}
                    childCount={treeChildren.length}
                    collapsed={collapsed}
                    handleDeleteCategory={handleDeleteCategory}
                    handleCollapse={handleCollapse}
                    setAddProcessOpen={setAddProcessOpen}
                    setItem={setItem}
                    setAccessModalOpen={setAccessModalOpen}
                    refreshData={refreshData}
                  />
                ) : (
                  <ProcessItem
                    collapsedVersions={collapsedVersions}
                    setCollapsedVersions={setCollapsedVersions}
                    item={process}
                    depth={depth}
                    icons={icons}
                    colors={colors}
                    setColors={setColors}
                    setItem={setItem}
                    setAccessModalOpen={setAccessModalOpen}
                    refreshData={refreshData}
                    users={usersInOrg ? usersInOrg.users : []}
                  />
                )}
              </SortableTreeItem>
            )
          }}
        />
      </div>
      <AccessModal
        open={acessModalOpen}
        setOpen={setAccessModalOpen}
        usersInOrg={usersInOrg ? usersInOrg.users : []}
        dataLoading={accessModalProcessDataLoading}
        data={acessModalProcessData}
        item={item}
        onAddAccess={handleAddAccess}
        onRemoveAccess={handleRemoveAccess}
        onChangeUserAccess={handleChangeUserAccess}
      />
      <AddProcessModal
        open={addProcessOpen}
        options={
          usersInOrg
            ? usersInOrg.users.map((x: any) => ({ ...x, value: x.name, icon: `${imageBase}/${x.objectId}/76` }))
            : []
        }
        setOpen={setAddProcessOpen}
        onAdd={handleAddProcess}
        checkFile={handleCheckFile}
        colors={colors}
        icons={icons}
        setColors={setColors}
      />
    </div>
  )
}

export default ProcessesConfiguration
