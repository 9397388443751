import { useMutation, useQueryClient } from '@tanstack/react-query'
import VariablesService from '../../../../../services/Variables.service'
import { useParams, useSearchParams } from 'react-router-dom'

export const useDeleteVariableSection = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()
  const [searchParams] = useSearchParams()
  const key = searchParams.get('key')

  return useMutation<any, Error, { sectionId: string }>({
    mutationKey: ['delete-section'],
    mutationFn: (payload) => VariablesService.deleteVariableSection(org!, key!, payload.sectionId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['variables'] })
    },
  })
}
