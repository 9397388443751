import React, { FC, PropsWithChildren, useEffect } from 'react'
import http, {
  axiosBoardInstance,
  axiosAnalyticsInstance,
  axiosIamInstance,
  axiosMgmtInstance,
} from '../../services/http-common'
// import { Toast } from '@sistemiv/s-components'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { loginRequest, msalInstance } from '../../services/AuthConfig'

const ErrorHandlingInterceptor: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  // const [errorMessage, setErrorMessage] = useState('An error occurred!')

  useEffect(() => {
    const authInterceptor = async (error: any) => {
      if (error.response?.status === 401) {
        msalInstance.acquireTokenRedirect(loginRequest)
      } else if (error.response?.status === 404) {
        toast.error('Resources not found!')
      } else if (error.response?.status === 403) {
        navigate('/not-authorized')
        toast.error('Not authorized!')
      } else if (error.response?.status === 500) {
        toast.error('Server error occurred!')
      } else {
        toast.error('Something went wrong!')
      }
      console.error(error)
      throw error
    }

    const httpInterceptor = http.interceptors.response.use(async (response) => response, authInterceptor)
    const iamInterceptor = axiosIamInstance.interceptors.response.use(async (response) => response, authInterceptor)
    const mgmtInterceptor = axiosMgmtInstance.interceptors.response.use(async (response) => response, authInterceptor)
    const boardInterceptor = axiosBoardInstance.interceptors.response.use(async (response) => response, authInterceptor)
    const historyInterceptor = axiosAnalyticsInstance.interceptors.response.use(
      async (response) => response,
      authInterceptor,
    )

    return () => {
      http.interceptors.response.eject(httpInterceptor)
      axiosIamInstance.interceptors.response.eject(iamInterceptor)
      axiosMgmtInstance.interceptors.response.eject(mgmtInterceptor)
      axiosBoardInstance.interceptors.response.eject(boardInterceptor)
      axiosAnalyticsInstance.interceptors.response.eject(historyInterceptor)
    }
  }, [navigate])

  return <div>{children}</div>
}

export default ErrorHandlingInterceptor
