import React, { FC } from 'react'
import IncidentReportField from './IncidentReportField'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

type IncidentReportProps = {
  errorCode?: string
  errorMessage?: string
  errorDescription?: string
  failedAt: Date | null
}

const IncidentReport: FC<IncidentReportProps> = ({ errorCode, errorDescription, errorMessage, failedAt }) => {
  const { t } = useTranslation()
  return (
    <div className='flex flex-col pb-4'>
      <div className='   flex w-[720px] flex-col items-start bg-[color:var(--notification-error-fill-3,rgba(253,217,230,0.30))] px-3 py-1.5 rounded-md'>
        <div>
          <IncidentReportField label={t('ProcessInstance.errorCode')} text={errorCode ?? 'N/A'} />
          <IncidentReportField label={t('ProcessInstance.message')} text={errorMessage ?? 'N/A'} />
          <IncidentReportField label={t('ProcessInstance.description')} text={errorDescription ?? 'N/A'} />
          <IncidentReportField
            label={t('ProcessInstance.date')}
            text={failedAt ? dayjs(new Date(failedAt)).format('DD MMM YYYY H:mm:ss') : t('ProcessInstance.unknown')}
          />
        </div>
      </div>
    </div>
  )
}

export default IncidentReport
