import { useQuery } from '@tanstack/react-query'
import AnalyticsService from '../../services/Analytics.service'

type TaskTimeSeriesType = {
  organization?: string
  startDate?: Date
}

export const useTaskTimeSeries = ({ organization, startDate }: TaskTimeSeriesType) => {
  return useQuery({
    queryKey: ['task-time-series', organization, { startDate }],
    queryFn: () => AnalyticsService.getTaskTimeSeries(organization!, startDate!, new Date()),
    enabled: !!organization && !!startDate,
  })
}
