import { MultiSelectSearch, DueDateFilter, RangePicker, Input } from '@sistemiv/s-components'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import UserService from '../../../services/UserService'
import { imageBase } from '../../../services/http-common'
import { useProcessInstances } from '../../../repositories'
import { TableField } from '@sistemiv/s-components/dist/esm/components/table/Table'
import { AssigneeCandidateType } from '../../../models/ProcessInstance'
import { useTranslation } from 'react-i18next'

const HeaderSearchField: FC<{
  onSearch?(id: string, option: string, value: any): void
  header: any
  value: any
  filters?: string[]
}> = ({ header, value, filters, onSearch }) => {
  const { org } = useParams()
  const [groups, setGroups] = useState<any>(null)
  const [orgNodes, setOrgNodes] = useState<any>(null)
  const [usersInOrg, setUsersInOrg] = useState<any>(null)
  const [assigneeOptions, setAssigneeOptions] = useState<any>([])
  const [startDate, setStartDate] = useState<Date | undefined>(value?.value?.[0])
  const [endDate, setEndDate] = useState<Date | undefined>(value?.value?.[1])
  const [selectOpen, setSelectOpen] = useState(false)
  const { t } = useTranslation()
  const [size, setSize] = useState(20)
  const {
    data: optionsRaw,
    isLoading,
    isFetching,
  } = useProcessInstances({
    page: 1,
    size,
    params: {
      select:
        header.scope === 'Activity' && header.processInstanceField.toLowerCase() !== 'activities'
          ? `activities.${header.processInstanceField}`
          : header.scope === 'Variable'
          ? `typedValues.${header.processInstanceField}`
          : `${header.processInstanceField}`,
      filters,
    },
    enabled: header.searchType.includes('select') && (value?.value?.length > 0 || selectOpen),
  })

  useEffect(() => {
    if (!optionsRaw) return
    if (optionsRaw.total > size) {
      setSize(optionsRaw.total)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsRaw])

  const [query, setQuery] = useState(value?.value ?? '')

  useEffect(() => {
    if (header.searchType !== 'assignee') return
    if (!org) return
    UserService.listAllGroups(org).then((res) => {
      setGroups(res)
    })
    UserService.listAllOrgNodes(org).then((res) => {
      setOrgNodes(res)
    })
    UserService.listAllUsersInOrg(org).then((res) => {
      setUsersInOrg(res)
    })
  }, [org, header.searchType])

  useEffect(() => {
    if (startDate && endDate && startDate instanceof Date && endDate instanceof Date) {
      onSearch?.(
        header.scope === 'Activity'
          ? `activities.${header.processInstanceField}`
          : header.scope === 'Variable'
          ? `typedValues.${header.processInstanceField}`
          : header.processInstanceField,
        'between',
        [startDate.toISOString(), endDate.toISOString()],
      )
    } else if (!startDate && !endDate) {
      onSearch?.(
        header.scope === 'Activity'
          ? `activities.${header.processInstanceField}`
          : header.scope === 'Variable'
          ? `typedValues.${header.processInstanceField}`
          : header.processInstanceField,
        'between',
        [],
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

  useEffect(() => {
    setAssigneeOptions([
      ...(groups?.results?.map((r) => ({
        type: 'b64_value',
        id: r.id,
        value: r.name,
        icon: r.icon,
        tab: 'Groups',
      })) ?? []),
      ...(orgNodes?.results?.map((r) => ({
        type: 'value',
        id: r.id,
        value: r.name,
        tab: 'Org chart',
      })) ?? []),
      ...(usersInOrg?.users?.map((r) => ({
        type: 'circled_value',
        id: r.objectId,
        value: r.name,
        icon: `${imageBase}/${r.objectId}/76`,
        tab: 'Users',
      })) ?? []),
    ])
  }, [groups, orgNodes, usersInOrg])

  useEffect(() => {
    if (header.searchType === 'like') {
      const delayTyping = setTimeout(() => {
        onSearch?.(
          header.scope === 'Activity'
            ? `activities.${header.processInstanceField}`
            : header.scope === 'Variable'
            ? `typedValues.${header.processInstanceField}`
            : header.processInstanceField,
          'like',
          query,
        )
      }, 500)

      return () => clearTimeout(delayTyping)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const priorityMap = (p) => {
    switch (p) {
      case 0:
        return 'Lowest'
      case 25:
        return 'Low'
      case 50:
        return 'Medium'
      case 75:
        return 'High'
      case 100:
        return 'Highest'
      default:
        return ''
    }
  }

  const options: TableField[] = useMemo(() => {
    return header.scope === 'Activity' && header.processInstanceField !== 'activities'
      ? optionsRaw && optionsRaw.values?.[`activities.${header.processInstanceField}`]
        ? Object.keys(optionsRaw?.values?.[`activities.${header.processInstanceField}`]).map((key) => {
            return {
              id: key,
              value: header.id.toLowerCase().includes('priority')
                ? priorityMap(optionsRaw?.values?.[`activities.${header.processInstanceField}`]?.[key])
                : optionsRaw?.values?.[`activities.${header.processInstanceField}`]?.[key],
              solidColor: '#DEE1FC',
              type: header.id.toLowerCase().includes('priority')
                ? 'priority_select'
                : header.processInstanceField.toLowerCase().includes('activities')
                ? 'solid'
                : 'value',
            }
          }) ?? []
        : []
      : optionsRaw && optionsRaw.values?.[header.processInstanceField]
      ? Object.keys(optionsRaw?.values?.[header.processInstanceField]).map((key) => {
          return {
            id: key,
            value: header.id.includes('Priority')
              ? priorityMap(optionsRaw?.values?.[header?.processInstanceField]?.[key])
              : optionsRaw?.values?.[header?.processInstanceField]?.[key],
            solidColor: '#DEE1FC',
            type: header.id.toLowerCase().includes('priority')
              ? 'priority_select'
              : header.processInstanceField.toLowerCase().includes('activities')
              ? 'solid'
              : 'value',
          }
        }) ?? []
      : []
  }, [optionsRaw, header])

  return header.searchType === 'like' ? (
    <Input
      value={query}
      onChange={(e) => setQuery(e.target.value)}
      type='search'
      id={header.searchType}
      placeholder={t('Common.search') as string}
      mainClasses='!m-0 border-none'
      classes2='!border !border-solid !rounded-md !border-gray-300 focus:!ring-0 !text-sm'
    />
  ) : header.searchType === 'select' ? (
    <MultiSelectSearch
      loading={isLoading || isFetching}
      onOpenChange={(v) => setSelectOpen(v)}
      options={options}
      value={options.filter((f) => value?.value.includes(f.id)) ?? []}
      onChange={(e) =>
        onSearch?.(
          header.processInstanceField.toLowerCase() === 'activities'
            ? 'activities.activityId'
            : header.scope === 'Activity'
            ? `activities.${header.processInstanceField}`
            : header.scope === 'Variable'
            ? `typedValues.${header.processInstanceField}`
            : header.processInstanceField,
          'in',
          e?.map((v) => v.id),
        )
      }
    />
  ) : header.searchType === 'dueDate' ? (
    <DueDateFilter
      selectedOption={value?.operation}
      time={value?.value}
      onChange={(option, time) =>
        onSearch?.(
          header.scope === 'Activity' && header.processInstanceField.toLowerCase() !== 'activities'
            ? `activities.${header.processInstanceField}`
            : header.scope === 'Variable'
            ? `typedValues.${header.processInstanceField}`
            : header.processInstanceField,
          option,
          time,
        )
      }
    />
  ) : header.searchType === 'rangePicker' ? (
    <RangePicker
      startDate={startDate}
      endDate={endDate}
      onRangeChange={(startDate, endDate) => {
        setStartDate(startDate)
        setEndDate(endDate)
      }}
      className='[&>div>input]:!border [&>div>input]:!border-solid [&>div>input]:!border-gray-300 rounded-md'
    />
  ) : header.searchType === 'assignee' ? (
    <MultiSelectSearch
      options={assigneeOptions}
      value={assigneeOptions.filter((f) => value?.value.includes(f.id)) ?? []}
      onChange={(v) => {
        onSearch?.(
          header.processInstanceField.toLowerCase() === 'activities'
            ? 'activities.activityId'
            : header.scope === 'Activity'
            ? header.processInstanceField === 'assignee'
              ? `activities.assigneeId`
              : `activities.${header.processInstanceField}`
            : header.scope === 'Variable'
            ? `typedValues.${header.processInstanceField}`
            : header.processInstanceField,
          'in',
          v?.map((a) => a.id) ?? [],
        )
        onSearch?.(
          'activities.assigneeType',
          'in',
          v?.[0]?.tab === 'Users'
            ? [AssigneeCandidateType.User, AssigneeCandidateType.InstanceStarter]
            : v?.[0]?.tab === 'Groups'
            ? [AssigneeCandidateType.Group]
            : v?.[0]?.tab === 'Org chart'
            ? [AssigneeCandidateType.OrganizationNode, AssigneeCandidateType.InstanceStarterNode]
            : '',
        )
      }}
      assignee
    />
  ) : (
    <div></div>
  )
}

export default HeaderSearchField
