import React, { FC, useEffect, useState } from 'react'
import {
  DnDSortableList,
  Button,
  TabPanel,
  Tabs,
  SortableListItem,
  DeleteModal,
  SpinnerIcon,
} from '@sistemiv/s-components'
import TranslationsTable from '../../TranslationsTable'
import { ThrashBinIcon } from '@sistemiv/s-components'
import { PlusIcon } from '@heroicons/react/24/outline'
import { PencilIcon } from '@heroicons/react/24/solid'
import { Translation } from '../../TranslationsTable'
import { LinkTypeModal } from '@sistemiv/s-components'
import GlobalSettingsHeader from '../GlobalSettingsHeader'
import { useLinkTypes, useDeleteLinkType, useAddLinkType, useUpdateLinkType } from '../../../../repositories'
import { useParams } from 'react-router-dom'
import { arraySwap } from '@dnd-kit/sortable'
import { useQueryClient } from '@tanstack/react-query'

const LinkTypes: FC = () => {
  const { org } = useParams()
  const tabs = ['Configuration', 'Translation']
  const [activeTab, setActiveTab] = useState(tabs[0])
  const [modalData, setModalData] = useState({ id: '', index: 0, type: '' })
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [deletingId, setDeletingId] = useState('')

  const queryClient = useQueryClient()
  const { data: linkTypes = [], isLoading } = useLinkTypes({ org })
  const { mutateAsync: linkTypeAddMutation } = useAddLinkType()
  const { mutateAsync: linkTypeDeleteMutation } = useDeleteLinkType()
  const { mutateAsync: linkTypeUpdateMutation, isPending: isUpdating } = useUpdateLinkType()

  const mapTranslations = (): Translation[] => {
    const tr: Translation[] = []
    for (let i = 0; i < linkTypes.length; i++) {
      tr.push({
        key: linkTypes[i].linkName,
        translationSingular: linkTypes[i].linkName,
        translationPlural: linkTypes[i].linkName,
      })
      tr.push({
        key: linkTypes[i].counterLinkName,
        translationSingular: linkTypes[i].counterLinkName,
        translationPlural: linkTypes[i].counterLinkName,
      })
    }
    return tr
  }
  const handleDragEnd = async (e: any) => {
    const { active, over } = e
    if (active.id !== over.id) {
      const oldIndex = linkTypes?.findIndex((item) => item.id === active.id)
      const newIndex = linkTypes?.findIndex((item) => item.id === over.id)
      linkTypeUpdateMutation({
        id: linkTypes[oldIndex].id,
        field: 'position',
        body: { position: linkTypes[newIndex].position },
      })
      queryClient.setQueryData(['link-types', org], arraySwap(linkTypes, oldIndex, newIndex))
    }
  }

  const onEdit = (field: string, value: any) => {
    let param = field
    if (field === 'to-type') {
      param = 'linkTypeTo'
    }
    if (field === 'from-type') {
      param = 'linkTypeFrom'
    }
    linkTypeUpdateMutation({ id: modalData.id, field, body: { [param]: value } })
  }
  const onAdd = (obj: any) => {
    linkTypeAddMutation({ linkTypeTo: obj.backward, linkTypeFrom: obj.forward })
  }

  const handleDelete = () => {
    linkTypeDeleteMutation({ linkTypeId: deletingId })
  }

  useEffect(() => {
    if (modalData.type !== '') setModalOpen(true)
  }, [modalData])

  if (isLoading)
    return (
      <div className='w-full flex justify-center pt-5'>
        <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
      </div>
    )

  return (
    <div className='h-full overflow-hidden flex flex-col'>
      <GlobalSettingsHeader title='Link Types' className='border-b border-gray-300 flex-none'>
        <div className='ml-16 flex h-full items-end gap-x-3'>
          <Button
            className='text-blue-500 flex items-center gap-x-3 border border-blue-500'
            onClick={() => {
              setModalData({ id: '', index: 0, type: 'add' })
            }}
          >
            <PlusIcon className='w-4 h-4' />
            Link Type
          </Button>
        </div>
      </GlobalSettingsHeader>
      <div className='grow overflow-auto p-8'>
        <Tabs active={activeTab} tabs={tabs} onClick={setActiveTab}>
          <TabPanel tab={tabs[0]} active={activeTab}>
            <div className={`w-full max-w-3xl ${isUpdating ? 'opacity-50' : 'opacity-100'}`}>
              <DnDSortableList items={linkTypes.map((e) => e.id)} onDragEnd={handleDragEnd}>
                {linkTypes.map((v, index) => (
                  <SortableListItem id={v.id} key={v.id} className='group'>
                    <div className='flex justify-between items-center'>
                      <div className='flex flex-col justify-center items-start'>
                        <p>To: {v.linkTypeTo}</p>
                        <small>From: {v.linkTypeFrom}</small>
                      </div>

                      <div className='flex items-center opacity-0 group-hover:opacity-100 gap-x-3'>
                        <Button
                          className='!p-0'
                          onClick={() => {
                            setModalData({ id: v.id, index: index, type: 'edit' })
                          }}
                        >
                          <PencilIcon className='w-4 h-4 text-slate-500 hover:text-gray-800' />
                        </Button>
                        <Button
                          className='!p-0'
                          onClick={() => {
                            setDeleteModalOpen(true)
                            setDeletingId(v.id)
                          }}
                        >
                          <ThrashBinIcon className='w-4 h-4 fill-slate-500 hover:fill-gray-800' />
                        </Button>
                      </div>
                    </div>
                  </SortableListItem>
                ))}
              </DnDSortableList>
            </div>
          </TabPanel>
          <TabPanel tab={tabs[1]} active={activeTab}>
            <TranslationsTable translations={mapTranslations()} />
          </TabPanel>
        </Tabs>
      </div>
      <DeleteModal
        title={'Delete Link Type'}
        description={'Are you sure you want to delete this Link Type?'}
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        onDeleteConfirmed={() => handleDelete()}
      />
      {modalOpen && (
        <LinkTypeModal
          isOpen={modalOpen}
          setOpen={setModalOpen}
          onEdit={onEdit}
          onAdd={onAdd}
          modalType={modalData.type}
          linkTypePair={{
            forward: linkTypes.length > 0 ? linkTypes[modalData.index].linkTypeTo : '',
            backward: linkTypes.length > 0 ? linkTypes[modalData.index].linkTypeFrom : '',
          }}
        />
      )}
    </div>
  )
}

export default LinkTypes
