import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ProcessDefinitionsSettings from '../../../../../services/ProcessDefinitionsSettings'

export const useAddUserInOrg = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()

  return useMutation<
    any,
    Error,
    { itemId: string; processAccessId: string; userId: string; accessLevel: string | null }
  >({
    mutationKey: ['add-user-in-org'],
    mutationFn: (payload) =>
      ProcessDefinitionsSettings.addUserInOrgAccess(
        org!,
        payload.itemId,
        payload.processAccessId,
        payload.userId,
        payload.accessLevel,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['list-users-in-org'] })
    },
  })
}
