import { SpinnerIcon } from '@sistemiv/s-components'
import React, { FC, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import ProcessDefinitionsSettings from '../../../../services/ProcessDefinitionsSettings'
// import { useTranslation } from 'react-i18next'

const ResolutionsTab: FC = () => {
  const { org } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  // const { t } = useTranslation()

  const [rows, setRows] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!org) return
    const versionId = searchParams.get('version')
    if (!versionId) return
    setLoading(true)
    ProcessDefinitionsSettings.listAllResolutions(org, versionId)
      .then((response) => {
        setRows(response)
        // TODO: store response in rows for table
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [org, searchParams, setSearchParams])

  return (
    <div className='overflow-hidden h-full flex flex-col'>
      <div className='p-8 overflow-auto grow'>
        <div className='relative w-full max-w-3xl'>
          {loading && (
            <div className='flex justify-center top-1 inset-x-auto absolute w-full'>
              <SpinnerIcon className='w-8 h-8 text-sky-500' />
            </div>
          )}
          <table className={`${loading ? 'opacity-50' : 'opacity-100'} table-auto w-full text-sm`}>
            <thead>
              <tr className='border-b border-gray-300'>
                <th className='text-slate-500 px-3 py-2 font-normal text-left'>Resolution</th>
              </tr>
            </thead>
            <tbody>
              {rows.map(
                (row) =>
                  row && (
                    <tr key={row} className='border-b border-gray-300'>
                      <td className='py-3 px-3'>{row}</td>
                    </tr>
                  ),
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ResolutionsTab
