import React, { FC, PropsWithChildren } from 'react'
import { Outlet } from 'react-router-dom'
import { useIsAuthenticated } from '@azure/msal-react'
import ErrorHandlingInterceptor from '../components/error-handling/ErrorHandlingInterceptor'

const PrivateRoute: FC<PropsWithChildren> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated()
  return isAuthenticated ? <ErrorHandlingInterceptor>{children}</ErrorHandlingInterceptor> : <Outlet />
}

export default PrivateRoute
