import { useMutation, useQueryClient } from '@tanstack/react-query'
import TasksService from '../../../services/Tasks.service'
import { useParams } from 'react-router-dom'

type TaskClaimOptions = {
  id: string
}

export const useTaskClaim = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()
  return useMutation<any, Error, TaskClaimOptions>({
    mutationKey: ['claim-task'],
    mutationFn: (payload) => TasksService.claimTask(org!, payload.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] })
      queryClient.invalidateQueries({ queryKey: ['tasks-details'] })
      queryClient.invalidateQueries({ queryKey: ['tasks-form'] })
    },
  })
}
