import { useQuery } from '@tanstack/react-query'
import AnalyticsService from '../../services/Analytics.service'

type ProcessTimeSeriesType = {
  organization?: string
  processDefinitionKey?: string
  startDate?: Date
  endDate?: Date
}

export const useProcessTimeSeries = ({
  organization,
  processDefinitionKey,
  startDate,
  endDate,
}: ProcessTimeSeriesType) => {
  return useQuery({
    queryKey: ['process-time-series', organization, processDefinitionKey, { startDate, endDate }],
    queryFn: () => AnalyticsService.getProcessTimeSeries(organization!, processDefinitionKey!, startDate!, endDate!),
    enabled: !!organization && !!processDefinitionKey && !!startDate && !!endDate,
  })
}
