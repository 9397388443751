import { axiosMgmtInstance } from './http-common'

export type FieldOption = 'icon' | 'name' | 'color' | 'importance' | 'default'

class PrioritiesService {
  async list(org?: string) {
    if (!org) return
    return (await axiosMgmtInstance.get(`/${org}/priorities`)).data
  }

  async add(payload: { name: string; color: string; icon: string }, org?: string) {
    if (!org) return
    return (await axiosMgmtInstance.post(`/${org}/priorities`, payload)).data
  }

  async update(priorityId: string, field: FieldOption, payload?: { [key: string]: string | number }, org?: string) {
    if (!org) return
    return (await axiosMgmtInstance.post(`/${org}/priorities/${priorityId}/${field}`, payload)).data
  }

  async delete(priorityId: string, org?: string) {
    if (!org) return
    return (await axiosMgmtInstance.delete(`/${org}/priorities/${priorityId}`)).data
  }
}
export default new PrioritiesService()
