import { useQuery } from '@tanstack/react-query'
import UserService from '../../../../services/UserService'
import { useParams } from 'react-router-dom'

export const useListAllGroupsInOrg = () => {
  const { org } = useParams()
  return useQuery({
    queryKey: ['list-all-groups-in-org', org],
    queryFn: () => UserService.listAllGroups(org!),
    enabled: !!org,
  })
}
