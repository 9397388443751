import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ProcessInstancesService from '../../../services/ProcessInstances.service'
import { NodeValue } from '../../../components/process-instances/create-process-instance/CreateProcessInstanceModal'

type ProcessInstanceCreateOptions = {
  processDefinitionKey: string
  version?: number
  organizationalNode: NodeValue | null
  priority: number
  dueDate: string
  notes: string
  typedValues: {
    [key: string]: { [key: string]: string }
  } | null
}

export const useProcessInstanceCreate = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()
  return useMutation<any, Error, ProcessInstanceCreateOptions>({
    mutationKey: ['create-instance'],
    mutationFn: (payload) => ProcessInstancesService.createInstance(org!, payload.processDefinitionKey, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['process-instances', org] })
    },
  })
}
