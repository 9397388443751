import { useMutation, useQueryClient } from '@tanstack/react-query'
import FiltersService from '../../../../../services/Filters.service'

type UpdateFilterPayload = {
  organization: string
  key: string
  id: string
  field: string
  value: any
}

export const useUpdateFilter = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, UpdateFilterPayload>({
    mutationKey: ['filters-update'],
    mutationFn: (payload) =>
      FiltersService.updateFilter(payload.field, payload.value, payload.id, payload.key, payload.organization),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['filters'] })
      queryClient.invalidateQueries({ queryKey: ['filters-definition'] })
    },
  })
}
