import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import FiltersService from '../../../../../services/Filters.service'

export const useDeleteFilter = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()
  const [searchParams] = useSearchParams()
  const key = searchParams.get('key')

  return useMutation<any, Error, { id: string }>({
    mutationKey: ['filters-delete', 'delete'],
    mutationFn: (payload) => FiltersService.deleteFilter(org!, key!, payload.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['filters'] })
    },
  })
}
