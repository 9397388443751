import { Configuration, PublicClientApplication } from '@azure/msal-browser'

const flowSub = () => {
  return process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE !== 'prod'
    ? `_${process.env.REACT_APP_STAGE?.toUpperCase()}`
    : ''
}

export const msalConfig: Configuration = {
  auth: {
    clientId: 'fd1442d1-cbcc-4b73-a893-a61b8d3d4782',
    authority: `https://login.sistemiv.com/f993cc02-b280-412a-86e1-c2b0aa67d1d3/B2C_1_SIGN_UP_AND_SIGN_IN${flowSub()}`,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    knownAuthorities: ['login.sistemiv.com'],
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    allowRedirectInIframe: true,
  },
}

export const loginRequest = { scopes: ['openid', 'fd1442d1-cbcc-4b73-a893-a61b8d3d4782'] }

export const msalInstance = new PublicClientApplication(msalConfig)
