import { ClockIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import React, { FC } from 'react'
import classNames from '../../../classNames'

export type TaskItemProps = {
  id: string
  name: string
  selected: boolean
  dueDate?: Date
  processName: string
  processColor: string
  processInstanceId: string
  processDefinitionKey: string
  processDefinitionId?: string
  code?: string
  onSelected?: (id: string) => void
}

const TaskItem: FC<TaskItemProps> = ({ id, name, dueDate, processName, processColor, onSelected, selected, code }) => {
  const dueTime = (date?: Date) => {
    const dayjsDate = dayjs(date)
    const now = dayjs()
    if (!date) return ''
    return Math.abs(dayjsDate.diff(now, 'year')) > 0
      ? `${Math.abs(dayjsDate.diff(now, 'year'))}y`
      : Math.abs(dayjsDate.diff(now, 'month')) > 0
      ? `${Math.abs(dayjsDate.diff(now, 'month'))}mo`
      : Math.abs(dayjsDate.diff(now, 'week')) > 0
      ? `${Math.abs(dayjsDate.diff(now, 'week'))}w`
      : Math.abs(dayjsDate.diff(now, 'day')) > 0
      ? `${Math.abs(dayjsDate.diff(now, 'day'))}d`
      : `${Math.abs(dayjsDate.diff(now, 'hour'))}h`
  }

  return (
    <div
      className={classNames(
        selected ? 'bg-blue-50 hover:!bg-blue-50' : '',
        'w-full p-3 text-base space-y-2 cursor-pointer hover:bg-blue-50',
      )}
      id={id}
      data-testid='task-list-item'
      onClick={() => {
        onSelected && onSelected(id)
      }}
    >
      <div>
        <p className={classNames(selected ? 'text-blue-500' : '')}>{name}</p>
        <p className='text-sm'>{processName}</p>
      </div>
      <div className='flex items-center justify-between font-light'>
        <div className='text-sm flex items-stretch justify-start gap-x-1.5'>
          <div className='w-3.5 flex justify-center items-start'>
            <div
              className={classNames('w-3.5 h-3.5 rounded flex-none')}
              style={{ backgroundColor: processColor }}
              data-testid='process-color'
            />
          </div>
          <p className='text-slate-600 self-start leading-[0.875rem]'>{code}</p>
        </div>
        <div
          className={classNames(
            'flex flex-row gap-1 items-center text-gray-400',
            dueDate && dayjs().isAfter(dueDate) ? '!text-rose-600' : '',
          )}
        >
          <ClockIcon className='w-4 h-4 stroke-2' />
          <p>{dueTime(dueDate)}</p>
        </div>
      </div>
    </div>
  )
}
export default TaskItem
