import { Dialog, Transition } from '@headlessui/react'
import { DispatchSetAction, PropsWithClassName } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC, Fragment, MutableRefObject, PropsWithChildren } from 'react'
import classNames from '../classNames'

type ModalDialogProps = {
  isOpen: boolean
  setOpen: DispatchSetAction<boolean>
  initialRef?: MutableRefObject<HTMLElement | null>
}

const ModalDialog: FC<PropsWithChildren<PropsWithClassName<ModalDialogProps>>> = ({
  isOpen,
  setOpen,
  initialRef,
  children,
  className,
}) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-20' onClose={setOpen} initialFocus={initialRef}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-20 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Panel
                className={classNames(
                  className ?? '',
                  'relative transform rounded-md bg-white p-6 text-left shadow-xl transition-all w-full max-w-xs',
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ModalDialog
