import { useMutation, useQueryClient } from '@tanstack/react-query'
import TasksService from '../../../services/Tasks.service'
import { useParams } from 'react-router-dom'

type TaskEscalateOptions = {
  id: string
}

export const useTaskEscalate = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()
  return useMutation<any, Error, TaskEscalateOptions>({
    mutationKey: ['escalate-task'],
    mutationFn: (payload) => TasksService.escalateTask(org!, payload.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] })
    },
  })
}
