import { useMutation, useQueryClient } from '@tanstack/react-query'
import LinkTypesService from '../../../../../services/LinkTypes.service'
import { useParams } from 'react-router-dom'

type DeleteUserPhoneOptions = {
  linkTypeId: string
}

export const useDeleteLinkType = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()
  return useMutation<any, Error, DeleteUserPhoneOptions>({
    mutationKey: ['deleteLinkType'],
    mutationFn: (payload) => LinkTypesService.delete(payload.linkTypeId, org),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['link-types', org] })
    },
  })
}
