import { useMutation, useQueryClient } from '@tanstack/react-query'
import TasksService from '../../../services/Tasks.service'
import { useParams } from 'react-router-dom'

type TaskCompleteOptions = {
  id: string
  body: any
}

export const useTaskComplete = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()
  return useMutation<any, Error, TaskCompleteOptions>({
    mutationKey: ['complete-task'],
    mutationFn: (payload) => TasksService.completeTask(org!, payload.id, payload.body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks-paginated', org, 16], refetchType: 'all' })
      console.log('completed')
    },
  })
}
