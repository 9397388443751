import { PlusIcon } from '@heroicons/react/24/outline'
import { PencilIcon } from '@heroicons/react/24/solid'
import { IconPicker, Base64SVG, Button, ThrashBinIcon, SideMenuParentArrowIcon } from '@sistemiv/s-components'
import React, { FC, useState } from 'react'
import { ProcessDefinitionCategory } from '../../../../models/ProcessDefinitionSettings'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import { IconOption } from '@sistemiv/s-components/dist/esm/components/icon-picker/IconPicker'
import ProcessDefinitionsSettings from '../../../../services/ProcessDefinitionsSettings'
import { useParams } from 'react-router-dom'
import EditCategoryModal from '../create-category-modal/EditCategoryModal'
import { useTranslation } from 'react-i18next'

const CategoryItem: FC<{
  item: ProcessDefinitionCategory
  icons: IconOption[]
  childCount: number
  collapsed?: boolean
  handleCollapse?(id: string): void
  refreshData?(): void
  setItem: DispatchSetAction<any>
  setAddProcessOpen: DispatchSetAction<boolean>
  setAccessModalOpen: DispatchSetAction<boolean>
  handleDeleteCategory(id: string): void
}> = ({
  item,
  icons,
  childCount,
  collapsed,
  handleCollapse,
  refreshData,
  setItem,
  setAddProcessOpen,
  // setAccessModalOpen,
  handleDeleteCategory,
}) => {
  const { org } = useParams()
  const [editCategoryOpen, setEditCategoryOpen] = useState(false)
  const [isCategoryEdit, setIsCategoryEdit] = useState(false)
  const { t } = useTranslation()

  const handleCategoryUpdate = (field: string, value: any) => {
    if (!org) return
    if (!field) return
    const body = {}
    body[field] = value
    setIsCategoryEdit(true)
    ProcessDefinitionsSettings.update(org, item.id, field, body)
      .then(() => {
        setIsCategoryEdit(false)
        setEditCategoryOpen(false)
        refreshData?.()
      })
      .catch((error) => {
        setIsCategoryEdit(false)
        console.error(error)
      })
  }
  return (
    <div className='grid grid-cols-2 gap-x-20'>
      <div className='flex items-center gap-x-3 '>
        {childCount && childCount > 0 ? (
          <button onClick={() => handleCollapse?.(item.id)}>
            <SideMenuParentArrowIcon className={`w-3 h-3 fill-black ${collapsed ? 'rotate-0' : 'rotate-90'}`} />
          </button>
        ) : null}
        <IconPicker setSelected={(icon: any) => handleCategoryUpdate('icon', icon.base64string)} options={icons}>
          <Base64SVG
            base64image={item.icon ?? ''}
            className='[&>svg>*]:fill-slate-500 [&>svg>*]:hover:fill-gray-800 [&>svg]:w-7 [&>svg]:h-7'
          />
        </IconPicker>
        <span className='whitespace-nowrap text-ellipsis overflow-hidden'>{item.name}</span>
      </div>
      <div className='flex items-center justify-end align-end gap-x-5 opacity-0 group-hover:opacity-100'>
        <Button
          className='!p-0'
          tooltip={t('Settings.tooltips.addCategoryProcess') as string}
          dataTestId={`process-category-addProcess`}
          onClick={() => {
            setItem(item)
            setAddProcessOpen(true)
          }}
        >
          <PlusIcon className='w-4 h-4 text-slate-500 hover:text-gray-800' />
        </Button>
        <Button
          className='!p-0'
          tooltip={t('Settings.tooltips.editCategory') as string}
          dataTestId={`process-category-edit`}
          onClick={() => {
            setEditCategoryOpen(true)
          }}
        >
          <PencilIcon className='w-4 h-4 fill-slate-500 hover:fill-gray-800' />
        </Button>
        {/* <Button
          className='!p-0'
          tooltip={t('Settings.tooltips.editCategoryAccess') as string}
          dataTestId={`process-category-edit-access`}
          onClick={() => {
            setItem(item)
            setAccessModalOpen(true)
          }}
        >
          <UserCircleIcon className='w-5 h-5 text-slate-500 hover:text-gray-800' />
        </Button> */}
        <Button
          className='!p-0 group'
          tooltip={t('Settings.tooltips.removeCategory') as string}
          dataTestId={`process-category-delete`}
          onClick={() => handleDeleteCategory(item.id)}
        >
          <ThrashBinIcon className='w-4 h-4 fill-slate-500 hover:fill-gray-800 group-disabled:fill-slate-500 group-disabled:opacity-50' />
        </Button>
      </div>
      {editCategoryOpen && (
        <EditCategoryModal
          category={item}
          open={editCategoryOpen}
          setOpen={setEditCategoryOpen}
          onUpdate={handleCategoryUpdate}
          icons={icons}
          loading={isCategoryEdit}
        />
      )}
    </div>
  )
}

export default CategoryItem
