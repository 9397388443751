import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import ProcessInstancesService from '../../services/ProcessInstances.service'

export const useBulkActions = (selectedRows: string[]) => {
  const { org, type } = useParams()
  return useQuery<any>({
    queryKey: ['process-instances-actions', org, type, selectedRows],
    queryFn: () => ProcessInstancesService.getBulkActions(org!, type!, { processInstanceIds: [...selectedRows] }),
    enabled: !!org && !!type,
  })
}
