import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

const TimeSeriesSelect: FC<{ selectedTimeFilter: string; setSelectedTimeFilter: DispatchSetAction<string> }> = ({
  selectedTimeFilter,
  setSelectedTimeFilter,
}) => {
  const { t } = useTranslation()
  const timeFilterOptions = [
    { value: '7', label: t('Dashboard.timeFilterOptions.7') },
    { value: '30', label: t('Dashboard.timeFilterOptions.30') },
    { value: '90', label: t('Dashboard.timeFilterOptions.90') },
    { value: '365', label: t('Dashboard.timeFilterOptions.365') },
  ]
  const selected = timeFilterOptions.find((o) => o.value === selectedTimeFilter)

  return (
    <Listbox value={selectedTimeFilter} onChange={setSelectedTimeFilter}>
      <div className='relative mt-1'>
        <Listbox.Button className='relative font-semibold w-full cursor-default rounded-lg bg-[#313042] py-2 pl-4 pr-14 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 '>
          <span className='block truncate text-white'>{selected?.label}</span>
          <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4'>
            <ChevronDownIcon className='h-4 w-4 stroke-2 text-white' aria-hidden='true' />
          </span>
        </Listbox.Button>
        <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
          <Listbox.Options className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm'>
            {timeFilterOptions
              .filter((o) => o.value !== selectedTimeFilter)
              .map((o) => (
                <Listbox.Option
                  key={o.value}
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-2 px-3 text-gray-900 ${active ? 'bg-blue-50' : ''}`
                  }
                  value={o.value}
                >
                  <span className={`block truncate `}>{o.label}</span>
                </Listbox.Option>
              ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

export default TimeSeriesSelect
