/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useEffect } from 'react'
import {
  DnDSortableList,
  Button,
  SortableListItem,
  DeleteModal,
  ColorSelect,
  SpinnerIcon,
} from '@sistemiv/s-components'
import { ThrashBinIcon } from '@sistemiv/s-components'
import { PlusIcon } from '@heroicons/react/24/outline'
import classNames from '../../../../classNames'
import SelectableHeader from '../SelectableHeader'
import { useParams, useSearchParams } from 'react-router-dom'
import { PencilIcon } from '@heroicons/react/24/solid'
import { FilterDefinition } from '../../../../models/Filters'
import {
  // useFilterDefinition,
  useCreateFilter,
  useDeleteFilter,
  useFilterDefinition,
  useFilters,
  useUpdateFilter,
} from '../../../../repositories'
import useLocalStorage from 'use-local-storage'
import { ColorOption } from '../../../../models/ColorOption'
import { initColors } from '../../../../utils/data'
import { useProcessFields } from '../../../../repositories/settings/processes/processes/process-fields.repository'
import { useUpdateFilterDefinition } from '../../../../repositories/settings/processes/filters/mutation/filter-update-definition.mutation'
import { useAddFilterDefinition } from '../../../../repositories/settings/processes/filters/mutation/filter-add-definition.mutation'
import { useDeleteFilterDefinition } from '../../../../repositories/settings/processes/filters/mutation/filter-delete-definition.mutation'
import EditFilterModal from './add-filter-modal/EditFilterModal'
import AddFilterModal from './add-filter-modal/AddFilterModal'
import { useTranslation } from 'react-i18next'
import { arrayMove } from '@dnd-kit/sortable'
import { useQueryClient } from '@tanstack/react-query'
// import { FilterableColumnsResponse, ProcessDefinitionFilter } from '../../../../models/ProcessDefinitionFilters'

const FiltersPage: FC = () => {
  const { org } = useParams()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editingFilter, setEditingFilter] = useState<any>(null)
  const [searchParams] = useSearchParams()
  const [colors, setColors] = useLocalStorage<ColorOption>('colors', initColors)
  const { t } = useTranslation()
  // const tabs = [t('Settings.tabs.configuration') /*, t('Settings.tabs.configuration')*/]
  // const [activeTab, setActiveTab] = useState(tabs[0])
  const queryClient = useQueryClient()

  const {
    data: customFilters = [],
    isLoading,
    isFetching,
  } = useFilters({
    organization: org,
    key: searchParams.get('key') ?? undefined,
  })

  const { data: processFields = [] } = useProcessFields({
    organization: org,
    key: searchParams.get('key') ?? undefined,
  })
  const { data: filterDefinition } = useFilterDefinition({
    organization: org,
    key: searchParams.get('key') ?? undefined,
    id: editingFilter?.id,
  })
  const { mutateAsync: createFilterMutation } = useCreateFilter()
  const { mutateAsync: updateFilterMutation } = useUpdateFilter()
  const { mutateAsync: deleteFilterMutation } = useDeleteFilter()
  const { mutate: updateFilterDefinition } = useUpdateFilterDefinition()
  const { mutate: addFilterDefinition } = useAddFilterDefinition()
  const { mutate: deleteFilterDefinition } = useDeleteFilterDefinition()

  const handleDragEnd = ({ active, over }: any) => {
    const key = searchParams.get('key')
    if (over) {
      if (!org || !key) return
      const overIndex = customFilters?.findIndex(({ id }) => id === over.id)
      const activeIndex = customFilters?.findIndex(({ id }) => id === active.id)
      console.log(overIndex, activeIndex)
      queryClient.setQueryData(['filters', org, searchParams.get('key')], (old: any[]) =>
        arrayMove(old, activeIndex, overIndex),
      )

      updateFilterMutation({
        organization: org,
        key: key,
        id: active.id,
        field: 'position',
        value: customFilters[overIndex].position,
      })
    }
  }

  const handleUpdateBody = (field: string, value: any) => {
    const process = searchParams.get('key')
    if (!org || !process || !editingFilter) return
    updateFilterMutation({ organization: org, key: process, id: editingFilter.id, field, value })
  }

  const handleDelete = () => {
    const process = searchParams.get('key')
    if (!org || !process || !editingFilter) return
    deleteFilterMutation(
      { id: editingFilter.id },
      {
        onSuccess: () => {
          setDeleteModalOpen(false)
          setEditingFilter(null)
        },
      },
    )
  }

  const handleCreate = async (body: any, chosenColumns: any[]) => {
    const process = searchParams.get('key')
    if (!org || !process) return
    const newBody = {
      color: body.color,
      name: body.name,
    }
    const predicates: FilterDefinition[] = []
    chosenColumns.forEach((column) => {
      predicates.push({
        field: column.field,
        operation: column.operation,
        value: column.value,
      })
    })

    await createFilterMutation({ color: body.color, name: body.name, predicates })
    setCreateModalOpen(false)
  }

  useEffect(() => {
    if (!editingFilter) return
    setEditingFilter(customFilters?.find((v) => v.id === editingFilter.id) ?? null)
  }, [customFilters, editingFilter, setEditingFilter])
  return (
    <div className='h-full overflow-hidden flex flex-col'>
      <SelectableHeader
        title={t('Settings.settingsMenu.filters')}
        hideVersions={true}
        className='border-b border-gray-300'
      >
        <div className='ml-16 flex h-full items-end gap-x-3'>
          <Button
            className='text-blue-500 flex items-center gap-x-3 border border-blue-500'
            onClick={() => setCreateModalOpen(true)}
          >
            <PlusIcon className='w-4 h-4' />
            {t('Settings.filters.filter')}
          </Button>
        </div>
      </SelectableHeader>
      <div className='grow overflow-auto p-8'>
        <div className='w-full max-w-3xl relative'>
          {!isLoading && !isFetching ? (
            customFilters.length > 0 ? (
              // <Tabs active={activeTab} tabs={tabs} onClick={setActiveTab}>
              //   <TabPanel tab={tabs[0]} active={activeTab}>
              <div className='w-full max-w-3xl'>
                <DnDSortableList items={customFilters?.map((e) => e.id)} onDragEnd={handleDragEnd}>
                  {customFilters?.map((v) => (
                    <SortableListItem id={v.id} key={v.id} className='group'>
                      <div className='flex justify-between items-center'>
                        <div className='flex items-center gap-x-3'>
                          <ColorSelect
                            value={v.color}
                            onChange={(color) => {
                              const key = searchParams.get('key')
                              if (!org || !key) return
                              updateFilterMutation({
                                organization: org,
                                key,
                                id: v.id,
                                field: 'color',
                                value: color,
                              })
                            }}
                            options={colors}
                          />
                          <p className={classNames(v.color ? 'text-gray-400' : 'text-gray-800')}>{v.name}</p>
                        </div>

                        <div className='flex items-center opacity-0 group-hover:opacity-100 gap-x-3'>
                          <Button
                            className='!p-0'
                            onClick={() => {
                              setEditingFilter(v)
                              setEditModalOpen(true)
                            }}
                          >
                            <PencilIcon className='w-4 h-4 text-slate-500 hover:text-gray-800' />
                          </Button>
                          <Button
                            className='!p-0'
                            onClick={() => {
                              setEditingFilter(v)
                              setDeleteModalOpen(true)
                            }}
                          >
                            <ThrashBinIcon className='w-4 h-4 fill-slate-500 hover:fill-gray-800' />
                          </Button>
                        </div>
                      </div>
                    </SortableListItem>
                  ))}
                </DnDSortableList>
              </div>
            ) : (
              /* </TabPanel>
              <TabPanel tab={tabs[1]} active={activeTab}>
                <TranslationsTable
                  translations={customFilters?.map((e) => {
                    return {
                      key: e.id,
                      translationSingular: e.name,
                      translationPlural: e.name,
                    }
                  })}
                />
              </TabPanel>
            </Tabs> */
              <p className='text-gray-500'>{t('Settings.filters.noFilters')}</p>
            )
          ) : (
            <div className='w-full flex justify-center inset-x-auto absolute '>
              <SpinnerIcon className='w-8 h-8 text-sky-500' />
            </div>
          )}
        </div>
      </div>
      {createModalOpen && ( //&& filterableColumns
        <AddFilterModal
          isOpen={createModalOpen}
          setOpen={setCreateModalOpen}
          columns={processFields}
          onCreate={handleCreate}
          colors={colors}
          setColors={setColors}
        />
      )}
      {editModalOpen && editingFilter && (
        <EditFilterModal
          isOpen={editModalOpen}
          setOpen={setEditModalOpen}
          filter={editingFilter}
          definition={filterDefinition ?? []}
          colors={colors}
          setColors={setColors}
          columns={processFields}
          onAddDefinition={(body) => {
            const key = searchParams.get('key')
            if (!org || !key || !editingFilter.id) return
            addFilterDefinition({ organization: org, key, id: editingFilter.id, body })
          }}
          onDeleteDefinition={(body) => {
            const key = searchParams.get('key')
            if (!org || !key || !editingFilter.id) return
            deleteFilterDefinition({ organization: org, key, id: editingFilter.id, predicateId: body.id })
          }}
          onUpdateBody={handleUpdateBody}
          onUpdateDefinition={(body) => {
            const key = searchParams.get('key')
            if (!org || !key || !editingFilter.id) return
            updateFilterDefinition({
              organization: org,
              key,
              id: editingFilter.id,
              body: { ...body, predicateId: body.id },
            })
          }}
        />
      )}
      {deleteModalOpen && (
        <DeleteModal
          title={t('Settings.filters.deleteFilter')}
          description={t('Settings.filters.deleteFilterDescription')}
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          onDeleteConfirmed={handleDelete}
        />
      )}
    </div>
  )
}

export default FiltersPage
