import React, { FC, useMemo } from 'react'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import { AccessCombobox, GroupList, SpinnerIcon } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'
import ModalDialog from '../../../../ModalDialog'
import { useServiceAccounts } from '../../../../../repositories/settings/processes/webhooks/service-accounts.repository'
import { useWebhooksAccess } from '../../../../../repositories/settings/processes/webhooks'
import { useAddWebhookAccess } from '../../../../../repositories/settings/processes/webhooks/mutation/webhook-add-access.mutation'
import { useParams } from 'react-router-dom'
import { useRemoveWebhookAccess } from '../../../../../repositories/settings/processes/webhooks/mutation/webhook-remove-access.mutation'

type EditAccessProps = {
  open: boolean
  webhook: any
  setOpen: DispatchSetAction<boolean>
  onCancel: () => void
}
export type ServiceAccount = {
  credentialId: string
  credentialType: string
  id: string
  name: string
  organizationNodeId: string
}

const EditAccessModal: FC<EditAccessProps> = ({ open, webhook, setOpen, onCancel }) => {
  const { org } = useParams()
  const { t } = useTranslation()
  const {
    data: options,
    isLoading: optionsLoading,
    isFetching: optionsFetching,
  } = useServiceAccounts({ page: 1, size: 100 })
  const {
    data: values,
    isLoading: valuesLoading,
    isFetching: valuesFetching,
  } = useWebhooksAccess({ page: 1, size: 100, id: webhook.id })
  const { mutate: addWebhookAccess, isPending: updatingWebhookAccess } = useAddWebhookAccess()
  const { mutate: removeAccess, isPending: removingAccess } = useRemoveWebhookAccess()

  const chosenAccess = useMemo(
    () => values?.results?.map((v) => options?.serviceAccounts?.find((o) => o.id === v)) ?? [],
    [options, values],
  )
  const nonChosenAccess = useMemo(
    () => options?.serviceAccounts?.filter((o) => !values?.results?.includes(o?.id)) ?? [],
    [options, values],
  )

  const handleRemove = (item: any) => {
    if (!org || !item.id) return
    console.log(org, webhook.id)
    removeAccess({ organization: org, id: webhook.id, value: item.id })
  }

  const handleAddAccess = (item: any) => {
    console.log(item)
    if (!org || !item.id) return
    addWebhookAccess({ organization: org, id: webhook.id, value: [item.id] })
  }

  return (
    <ModalDialog isOpen={open} setOpen={setOpen}>
      <Dialog.Title as='h3' className='leading-6 text-lg  mb-5 flex justify-between'>
        {t('Settings.webhooks.editAccess')}
        <button onClick={onCancel}>
          <XMarkIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </Dialog.Title>
      {optionsLoading || valuesLoading ? (
        <div className='w-full flex justify-center '>
          <SpinnerIcon className='w-8 h-8 text-sky-500' />
        </div>
      ) : (
        <div className='space-y-3'>
          {updatingWebhookAccess || removingAccess || optionsFetching || valuesFetching ? (
            <div className='w-full flex justify-center '>
              <SpinnerIcon className='w-8 h-8 text-sky-500' />
            </div>
          ) : (
            chosenAccess?.length > 0 && (
              <GroupList
                title={t('Settings.webhooks.serviceAccounts')!}
                onRemove={handleRemove}
                items={
                  chosenAccess.length > 0
                    ? chosenAccess?.map((access) => ({
                        id: access?.id,
                        type: 'value',
                        value: access?.name,
                      }))
                    : []
                }
              />
            )
          )}
          <AccessCombobox
            topMenu={false}
            onChange={handleAddAccess}
            options={
              nonChosenAccess?.map((access) => ({
                id: access?.id,
                type: 'value',
                value: access?.name,
              })) ?? []
            }
            placeholder={t('Settings.webhooks.addAccess')!}
            inputPlaceholder={t('Common.search') as string}
          />
        </div>
      )}
    </ModalDialog>
  )
}

export default EditAccessModal
