import { useQuery } from '@tanstack/react-query'
import ProcessDefinitionsSettings from '../../../../services/ProcessDefinitionsSettings'
import { UserTasksModel } from '../../../../models/ProcessDefinitionSettings'

type UserTasksSettingsType = {
  organization?: string
  versionId?: string
}

export const useUserTaskSettings = ({ organization, versionId }: UserTasksSettingsType) => {
  return useQuery<UserTasksModel>({
    queryKey: ['user-tasks-settings', organization, versionId],
    queryFn: () => ProcessDefinitionsSettings.listAllUserTasks(organization!, versionId!),
    enabled: !!organization && !!versionId,
  })
}
