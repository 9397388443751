import { SpinnerIcon, PaginationController } from '@sistemiv/s-components'
import Table, { TableRow } from '@sistemiv/s-components/dist/esm/components/table/Table'
import React, { FC, useEffect, useState } from 'react'
import { useIncidents } from '../../../../repositories/settings/processes/webhooks/incidents.repository'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

const IncidentsTab: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  // const [page, setPage] = useState(1)
  // const [size, setSize] = useState(20)
  const [webhookSearch, setWebhookSearch] = useState('')
  const [dataSearch, setDataSearch] = useState('')
  const [eventSearch, setEventSearch] = useState('')
  const [errorSearch, setErrorSearch] = useState('')
  const { t } = useTranslation()
  const { data: incidents, isLoading: loading } = useIncidents({
    page: parseInt(searchParams.get('page') ?? '1'),
    size: parseInt(searchParams.get('pageSize') ?? '20'),
    nameSearch: webhookSearch,
    dataSearch: dataSearch,
    errorSearch: errorSearch,
    eventSearch: eventSearch,
  })

  useEffect(() => {
    setSearchParams({ page: '1', size: '20' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!incidents) return
    const page = parseInt(searchParams.get('page') ?? '1')
    const size = parseInt(searchParams.get('pageSize') ?? '20')
    if (incidents?.total < (page - 1) * size) {
      setSearchParams({ pageSize: `${size}`, page: '1' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incidents, searchParams])

  // useEffect(() => {
  //   if (!incidents) return
  //   console.log((page - 1) * size)
  //   if (incidents?.total < (page - 1) * size) {
  //     setPage(1)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [incidents, size, page])

  const incidentsHeaders = [
    { accessor: 'webhook', label: t('Settings.webhooks.webhooks'), searchType: 'search', onSearch: setWebhookSearch },
    { accessor: 'event', label: t('Settings.webhooks.event'), searchType: 'search', onSearch: setEventSearch },
    { accessor: 'data', label: t('Settings.webhooks.payload'), searchType: 'search', onSearch: setDataSearch },
    { accessor: 'error', label: t('Settings.webhooks.errorMsg'), searchType: 'search', onSearch: setErrorSearch },
    { accessor: 'status', label: t('Settings.webhooks.status') },
    { accessor: 'sentAt', label: t('Settings.webhooks.sentAt') },
  ]

  const data: TableRow[] = incidents?.incidents?.map((incident) => {
    return {
      id: { type: 'value', value: incident?.id ?? 'N/A' },
      webhook: { type: 'value', value: incident?.webhookName ?? 'N/A' },
      event: { type: 'value', value: incident?.eventName ?? 'N/A' },
      data: {
        type: 'value',
        value: incident?.payload ? JSON.stringify(incident?.payload, undefined, 4) : 'N/A',
        copyBtn: incident?.payload && true,
        previewValue: incident?.payload && true,
      },
      error: { type: 'value', value: incident?.errorMessage ?? 'N/A' },
      status: { type: 'value', value: incident?.status ? incident?.status : 'N/A' },
      sentAt: {
        type: 'date',
        // value: incident?.sentAt ? dayjs(new Date(incident?.sentAt)).format('DD MMM YYYY H:mm') : 'N/A',
        value: incident?.sentAt ? incident?.sentAt : 'N/A',
        dateFormat: 'DD MMM YYYY H:mm',
      },
    }
  })

  return (
    <div
      className={`flex flex-col h-full ${loading ? 'w-full' : 'w-fit min-[1650px]:w-full'} pb-7`}
      data-testid='incidents-tab'
    >
      <div className='flex justify-end'>
        {incidents?.total > 0 && (
          <PaginationController
            total={incidents?.total}
            size={parseInt(searchParams.get('pageSize') ?? '20')}
            page={parseInt(searchParams.get('page') ?? '1')}
            onPageChange={(value) =>
              setSearchParams({ pageSize: searchParams.get('pageSize') ?? '20', page: `${value}` })
            }
            onSizeChange={(value) => setSearchParams({ page: searchParams.get('page') ?? '1', pageSize: `${value}` })}
          />
        )}
      </div>
      {loading ? (
        <div className='w-full flex justify-center pt-5'>
          <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
        </div>
      ) : (
        <div className='flex flex-col mt-4 h-full'>
          {data.length === 0 && !webhookSearch && !dataSearch && !eventSearch && !errorSearch ? (
            <p className='text-gray-500'>{t('Settings.webhooks.noIncidents')}</p>
          ) : (
            <Table
              headers={incidentsHeaders}
              data={data ?? []}
              rowClassName='group hover:bg-sky-50'
              searchable={true}
              fieldClassName='max-w-56 overflow-hidden text-ellipsis whitespace-nowrap'
            />
          )}
        </div>
      )}
    </div>
  )
}

export default IncidentsTab
