import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC, useState } from 'react'
import ModalDialog from '../../../../ModalDialog'
import { Dialog } from '@headlessui/react'
import { FolderIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { SideMenuParentArrowIcon } from '@sistemiv/s-components'
import { Variable } from '../../../../../models/Variables'
import { useTranslation } from 'react-i18next'

const MoveToModal: FC<{
  open: boolean
  movingVariable?: Variable
  sections: Variable[]
  setOpen: DispatchSetAction<boolean>
  onSelect?(selectedId?: string): void
}> = ({ open, setOpen, sections, movingVariable, onSelect }) => {
  const [collapsed, setCollapsed] = useState(false)
  const { t } = useTranslation()
  return (
    <ModalDialog isOpen={open} setOpen={setOpen} className='!max-w-sm'>
      <Dialog.Title as='h3' className='leading-6 text-lg flex justify-between'>
        {t('Settings.variables.moveTo')}
        <button onClick={() => setOpen(false)}>
          <XMarkIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </Dialog.Title>

      <div className='mt-5 overflow-auto max-h-96'>
        <div
          className={`flex items-center gap-x-3 py-1.5 px-3 cursor-pointer ${
            !movingVariable?.parentId ? 'bg-[#E9F1FF]' : 'bg-transparent'
          }`}
          onClick={() => {
            if (!movingVariable?.parentId) return
            onSelect?.()
          }}
        >
          <button onClick={() => setCollapsed((old) => !old)}>
            <SideMenuParentArrowIcon className={`w-3 h-3 fill-black ${collapsed ? 'rotate-0' : 'rotate-90'}`} />
          </button>
          <FolderIcon className='text-slate-400 w-7 h-7' />
          <p>{t('Settings.variables.variables')}</p>
        </div>
        {!collapsed &&
          sections.map((section) => (
            <div
              key={section.id}
              onClick={() => {
                if (movingVariable?.parentId === section.id) return
                onSelect?.(section.id)
              }}
              className={`flex items-center gap-x-3 pl-16 py-1.5 px-3 cursor-pointer ${
                movingVariable?.parentId === section.id ? 'bg-[#E9F1FF]' : 'bg-transparent'
              }`}
            >
              <FolderIcon className='text-slate-400 w-7 h-7' />
              <p>{section.name}</p>
            </div>
          ))}
      </div>
    </ModalDialog>
  )
}

export default MoveToModal
