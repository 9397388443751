import { FilterCreation, FilterDefinition, FilterOverview, FilterableColumns } from '../models/Filters'
import { axiosBoardInstance } from './http-common'

class FiltersService {
  async list(key: string, org?: string): Promise<FilterOverview[]> {
    if (!org) return []
    return (await axiosBoardInstance.get(`/${org}/processes/key/${key}/filters`)).data
  }

  async getFieldOperations(organization: string, key: string, type: string) {
    return (
      await axiosBoardInstance.get(`/${organization}/processes/key/${key}/fields/operations`, { params: { type } })
    ).data
  }

  async getFieldValues(
    organization: string,
    key: string,
    id: string,
    params: { search?: string; page?: number; size?: number },
  ) {
    const data = (await axiosBoardInstance.get(`/${organization}/processes/key/${key}/fields/${id}/values`, { params }))
      .data

    if (params.size && data.total > params.size) {
      return (
        await axiosBoardInstance.get(`/${organization}/processes/key/${key}/fields/${id}/values`, {
          params: { ...params, size: data.total },
        })
      ).data
    }
    return data
  }

  async createFilter(payload: FilterCreation, key: string, organization: string) {
    return (await axiosBoardInstance.post(`/${organization}/processes/key/${key}/filters`, payload)).data
  }

  async updateFilter(field: string, value: any, id: string, key: string, organization: string) {
    return (
      await axiosBoardInstance.post(`/${organization}/processes/key/${key}/filters/${id}/${field}`, { [field]: value })
    ).data
  }

  async updateFilterDefinition(organization: string, key: string, id: string, body: any) {
    return (await axiosBoardInstance.put(`/${organization}/processes/key/${key}/filters/${id}/predicate`, body)).data
  }

  async deleteFilter(organization: string, key: string, id: string) {
    return (await axiosBoardInstance.delete(`/${organization}/processes/key/${key}/filters/${id}`)).data
  }

  async getFilterableColumns(key: string, org?: string): Promise<FilterableColumns> {
    if (!org) return {}
    return (await axiosBoardInstance.get(`/${org}/processes/${key}/filterable-columns`)).data
  }

  async getFilterDefinition(id: string, key: string, organization: string): Promise<FilterDefinition[]> {
    return (await axiosBoardInstance.get(`/${organization}/processes/key/${key}/filters/${id}`)).data
  }

  async getFilterOperationsBasedOnVariableType(type: string, key: string, org?: string): Promise<string[]> {
    if (!org) return []
    return (await axiosBoardInstance.get(`/${org}/processes/${key}/filter-operations/variable-type/${type}`)).data
  }

  async createFilterPredicate(organization: string, key: string, id: string, body: any) {
    return (await axiosBoardInstance.post(`/${organization}/processes/key/${key}/filters/${id}/predicate`, body)).data
  }

  async deleteFilterPredicate(organization: string, key: string, id: string, predicateId: string) {
    return (
      await axiosBoardInstance.delete(`/${organization}/processes/key/${key}/filters/${id}/predicate`, {
        data: { predicateId },
      })
    ).data
  }
}
export default new FiltersService()
