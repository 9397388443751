import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import WebhooksService from '../../../../services/Webhooks.service'

type ServiceAccountsOptions = {
  page: number
  size: number
}

export const useServiceAccounts = ({ page, size }: ServiceAccountsOptions) => {
  const { org } = useParams()
  return useQuery<any>({
    queryKey: ['service-accounts', org, { page, size }],
    queryFn: () => WebhooksService.listServiceAccounts(org!, page, size),
    placeholderData: keepPreviousData,
    enabled: !!org,
  })
}
