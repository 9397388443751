import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { Form } from '@bpmn-io/form-js'
import '@bpmn-io/form-js/dist/assets/form-js.css'
import FileUploadModule from '../custom-file-render'
import FileDownloadModule from '../custom-file-download'

type FormViewerProps = {
  schema: any
  data?: any
  readOnly?: boolean
  onSubmit?(value: any): void
  onSave?(value: any): void
  onDeescalate?(data: any): void
}
const FormViewer = forwardRef<unknown, FormViewerProps>(
  ({ schema, data, readOnly, onDeescalate, onSubmit, onSave }, ref) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const form = useRef<Form | null>(null)
    useEffect(() => {
      form.current = new Form({
        container: containerRef.current,
        additionalModules: [FileUploadModule, FileDownloadModule],
      })
      const load = async () => {
        try {
          await form.current?.importSchema(schema, data)
        } catch (err) {
          console.error('failed to import form', err)
        }
      }
      load()
      form.current?.setProperty('readOnly', readOnly)
      //localStorage.setItem('form', JSON.stringify(form.current._getState()))
      data ? localStorage.setItem('formData', JSON.stringify(data)) : JSON.stringify({})
      console.log(form)

      return () => form?.current?.destroy()
    }, [schema, data, readOnly])

    useImperativeHandle(ref, () => ({
      deescalateTask() {
        if (!form.current) return
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { data } = form.current?.submit()
        onDeescalate?.(data)
      },
      submitForm() {
        if (!form.current) return
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { data, errors } = form.current?.submit()
        if (!Object.keys(errors).length) {
          onSubmit?.(data)
        }
      },
      saveForm() {
        if (!form.current) return
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { data } = form.current?.submit()
        onSave?.(data)
      },
    }))

    return (
      <div>
        <div ref={containerRef} />
      </div>
    )
  },
)

FormViewer.displayName = 'FormViewer'

export default FormViewer
