import { useQuery } from '@tanstack/react-query'
import { FilterDefinition } from '../../../../models/Filters'
import FiltersService from '../../../../services/Filters.service'

type FilterDefinitionType = {
  organization?: string
  key?: string
  id?: string
}

export const useFilterDefinition = ({ organization, key, id }: FilterDefinitionType) => {
  return useQuery<FilterDefinition[]>({
    queryKey: ['filters-definition', organization, key, id],
    queryFn: () => FiltersService.getFilterDefinition(id!, key!, organization!),
    enabled: !!organization && !!key && !!id,
  })
}
