import { TabPanel, Tabs } from '@sistemiv/s-components'
import React, { FC, useEffect, useMemo, useState } from 'react'
import SelectableHeader from '../SelectableHeader'
import { useTranslation } from 'react-i18next'
import UserTasksTab from './UserTasksTab'
import ServiceTasksTab from './ServiceTasksTab'
import ResolutionsTab from './ResolutionsTab'
import ExpressionsPage from '../expressions/ExpressionsPage'

type Tab = {
  key: 'user-tasks' | 'service-tasks' | 'resolution' | 'expressions'
  value: string
}

const Flow: FC = () => {
  const { t } = useTranslation()
  const tabs: Tab[] = useMemo(
    () => [
      { key: 'user-tasks', value: t('Settings.settingsMenu.userTasks') },
      { key: 'service-tasks', value: t('Settings.settingsMenu.serviceTasks') },
      { key: 'resolution', value: t('Settings.settingsMenu.resolutions') },
      { key: 'expressions', value: t('Settings.settingsMenu.expressions') },
    ],
    [t],
  )
  const [activeTab, setActiveTab] = useState<Tab>(tabs[0])

  useEffect(() => {
    setActiveTab((prev) => tabs.find((tab) => tab.key === prev.key)!)
  }, [t, tabs])

  return (
    <div className='overflow-hidden h-full flex flex-col'>
      <SelectableHeader className='border-b border-gray-300 flex-none' title={t('Settings.settingsMenu.flow')} />
      <div className='p-8 pt-3 overflow-auto grow'>
        <Tabs
          active={activeTab.value}
          tabs={tabs.map((tab) => tab.value)}
          onClick={(string) => {
            setActiveTab(tabs.find((tab) => tab.value === string)!)
          }}
          childrenClassName='!pt-0'
        >
          <TabPanel tab={tabs[0].value} active={activeTab.value}>
            <UserTasksTab />
          </TabPanel>
          <TabPanel tab={tabs[1].value} active={activeTab.value}>
            <ServiceTasksTab />
          </TabPanel>
          <TabPanel tab={tabs[2].value} active={activeTab.value}>
            <ResolutionsTab />
          </TabPanel>
          <TabPanel tab={tabs[3].value} active={activeTab.value}>
            <ExpressionsPage />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}

export default Flow
