import { useQuery } from '@tanstack/react-query'
import UserService from '../../../../services/UserService'
import { useParams } from 'react-router-dom'

export const useListAllUsersInOrg = () => {
  const { org } = useParams()
  return useQuery({
    queryKey: ['list-all-users-in-org', org],
    queryFn: () => UserService.listAllUsersInOrg(org!),
    enabled: !!org,
  })
}
