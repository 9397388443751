import React, { FC, Suspense, useEffect, useState } from 'react'
import { AuthError, InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser'
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react'
import { loginRequest, msalInstance } from './services/AuthConfig'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import toast, { Toaster } from 'react-hot-toast'
import { handleError } from './utils/errorHandling'
import { getCookie, portalUrl } from './services/http-common'
import AppRouter from './router/AppRouter'

const ErrorComponent: FC<{ error: AuthError | null }> = ({ error }) => {
  console.log(error)
  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      // window.location.replace(`${portalUrl}?app=${encodeURIComponent(window.location.href)}`)
      // window.location.replace(`${portalUrl}/logout`)
      msalInstance.acquireTokenRedirect(loginRequest)
    }
  }, [error])
  return null
}
const App: FC = () => {
  const [first, setFirst] = useState(true)
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
      },
      mutations: {
        onError: (error) => {
          console.log(error)
          toast.error(handleError(error))
        },
      },
    },
    queryCache: new QueryCache({
      onError: (error) => {
        console.error(error)
        toast.error(handleError(error))
      },
    }),
  })

  useEffect(() => {
    msalInstance
      .ssoSilent(loginRequest)
      .then((response) => {
        console.log('token response')
        if (response.account?.localAccountId !== msalInstance.getActiveAccount()?.localAccountId) {
          msalInstance.setActiveAccount(response.account)
        }
        //acquireToken()
        setFirst(false)
      })
      .catch((err) => {
        console.log(err)
        if (err instanceof InteractionRequiredAuthError) {
          window.location.replace(`${portalUrl}/logout`)
        }
      })

    // const acquireToken = async () => {
    //   try {
    //     const response = await msalInstance.acquireTokenSilent(loginRequest)
    //     if (response) {
    //       console.log('Token refreshed successfully:', response.accessToken)
    //     }
    //   } catch (err) {
    //     console.log('Error acquiring token silently:', err)
    //     if (err instanceof InteractionRequiredAuthError) {
    //       window.location.replace(`${portalUrl}?app=${encodeURIComponent(window.location.href)}`)
    //     }
    //   }
    // }

    // const interval = setInterval(() => {
    //   acquireToken()
    // }, 60 * 1000)

    // return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (getCookie('refreshProcess') === 'true') {
        document.cookie = `refreshProcess=false; path=/; max-age=0; domain=.sistemiv.com`
        window.location.reload()
      }
    }, 1000)

    return () => clearInterval(interval)
  })

  return (
    <QueryClientProvider client={queryClient}>
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Silent}
          authenticationRequest={loginRequest}
          errorComponent={ErrorComponent}
        >
          {!first && (
            <Suspense fallback='...loading'>
              <AppRouter />
              <Toaster position='bottom-right' reverseOrder={false} />
            </Suspense>
          )}
        </MsalAuthenticationTemplate>
      </MsalProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
