import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import VariablesService from '../../../../../services/Variables.service'

export const useDeleteVariable = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()
  const [searchParams] = useSearchParams()
  const key = searchParams.get('key')

  return useMutation<any, Error, { variableId: string }>({
    mutationKey: ['delete-variable'],
    mutationFn: (payload) => VariablesService.deleteVariable(org!, key!, payload.variableId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['variables'] })
      queryClient.invalidateQueries({ queryKey: ['process-fields'] })
    },
  })
}
