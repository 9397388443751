import { Combobox, Transition } from '@headlessui/react'
import { ExclamationCircleIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import React, { FC, Fragment, useState } from 'react'
import classNames from '../../../../../../classNames'
import { useTranslation } from 'react-i18next'

type ComboboxPillsWarningProps = {
  options: { name: string; inSection: boolean }[]
  value: { name: string; inSection: boolean }[]
  onChange?: (value: any) => void
  label?: string
}
const ComboboxPillsWarning: FC<ComboboxPillsWarningProps> = ({ value, onChange, options, label }) => {
  const [query, setQuery] = useState('')
  const { t } = useTranslation()
  const filteredOptions =
    query === ''
      ? options.filter((o) => !value.includes(o))
      : options
          .filter((o) => !value.includes(o))
          .filter((o) => o.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')))

  return (
    <Combobox
      onChange={(value) => {
        setQuery('')
        onChange?.(value)
      }}
      value={value}
      multiple
    >
      <div className='relative'>
        <div className='relative w-full'>
          <div className='flex items-center gap-x-3 gap-y-2 flex-wrap border rounded-md border-gray-300 px-3 py-2 focus-within:border-sky-100 focus-within:ring-2 focus-within:ring-sky-100'>
            {value.map((tag) => (
              <div
                key={tag.name}
                className='flex items-center gap-x-2 rounded-full py-1 px-2 bg-gray-200 text-slate-500 text-sm leading-4'
              >
                {tag.inSection && (
                  <div>
                    <ExclamationCircleIcon className='w-5 h-5 text-[#FF9B25]' />
                  </div>
                )}
                <div>{tag.name}</div>
                <button className='flex items-center justify-center'>
                  <XMarkIcon
                    className='w-4 h-4'
                    onClick={() => {
                      onChange?.(value.filter((v) => v !== tag))
                    }}
                  />
                </button>
              </div>
            ))}
            <Combobox.Input
              placeholder={value.length === 0 ? (label ? label : t('Settings.variables.addVariables')) : ''}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className={classNames(
                'w-auto shrink relative border-none py-0 px-1 focus:border-none focus:ring-0',
                value.length === 0 ? '!pl-5' : '',
              )}
            />
            <Combobox.Button
              className={classNames(
                'absolute inset-y-0 flex items-center justify-center left-0 pl-2',
                value.length === 0 ? 'flex' : 'hidden',
              )}
            >
              <PlusIcon className='w-4 h-4 text-gray-500' />
            </Combobox.Button>
          </div>
        </div>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options className='absolute overflow-auto mt-1 z-10 min-w-sm max-h-60 w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 bg-white'>
            {filteredOptions.length === 0 && query !== '' ? (
              <div className='py-2 px-3 cursor-default select-none text-gray-700'>{t('Common.nothingFound')}</div>
            ) : (
              filteredOptions.map((option) => (
                <Combobox.Option
                  key={option.name}
                  value={option}
                  className='py-2 px-3 cursor-pointer select-none hover:bg-slate-100'
                >
                  {option.name}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
}

export default ComboboxPillsWarning
