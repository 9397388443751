import React, { FC, useState } from 'react'
import GlobalSettingsHeader from '../GlobalSettingsHeader'
import { PlusIcon } from '@heroicons/react/24/outline'
import {
  AddPriorityModal,
  Base64SVG,
  Button,
  ColorSelect,
  DeleteModal,
  DnDSortableList,
  EditPriorityModal,
  IconPicker,
  SortableListItem,
  SpinnerIcon,
  TabPanel,
  Tabs,
  ThrashBinIcon,
} from '@sistemiv/s-components'
import TranslationsTable, { Translation } from '../../TranslationsTable'
import { IconOption } from '@sistemiv/s-components/dist/esm/components/icon-picker/IconPicker'
import classNames from '../../../../classNames'
import { PencilIcon } from '@heroicons/react/24/solid'
import { arraySwap } from '@dnd-kit/sortable'
import { useAddPriority, useDeletePriority, usePriorities, useUpdatePriority } from '../../../../repositories'
import toast from 'react-hot-toast'
import { FieldOption } from '../../../../services/Priorities.service'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import useLocalStorage from 'use-local-storage'
import { ColorOption } from '../../../../models/ColorOption'
import { initColors, initIcons } from '../../../../utils/data'

const PrioritiesPage: FC = () => {
  const { org } = useParams()
  const tabs = ['Configuration', 'Translation']
  const [activeTab, setActiveTab] = useState(tabs[0])
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [editValue, setEditValue] = useState<any>(null)

  const queryClient = useQueryClient()
  const { data: priorities, isLoading } = usePriorities()
  const { mutateAsync: prioritiesAddMutation, isPending: isAdding } = useAddPriority()
  const { mutateAsync: prioritiesDeleteMutation, isPending: isDeleting } = useDeletePriority()
  const { mutateAsync: prioritiesUpdateMutation, isPending: isUpdating } = useUpdatePriority()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [colors, setColors] = useLocalStorage<ColorOption>('colors', initColors)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [icons, setIcons] = useLocalStorage<IconOption[]>('icons', initIcons)

  const translations: Translation[] = [
    {
      key: 'Highest',
      translationSingular: 'Highest',
      translationPlural: '',
    },
    {
      key: 'High',
      translationSingular: 'High',
      translationPlural: '',
    },
    {
      key: 'Medium',
      translationSingular: 'Medium',
      translationPlural: '',
    },
    {
      key: 'Low',
      translationSingular: 'Low',
      translationPlural: '',
    },
    {
      key: 'Lowest',
      translationSingular: 'Lowest',
      translationPlural: '',
    },
  ]

  const handleAddPriority = (value: any) => {
    prioritiesAddMutation(
      { name: value.key, color: value.color, icon: value.icon },
      {
        onSuccess: () => {
          toast.success('Priority added successfully')
          setAddModalOpen(false)
        },
      },
    )
  }

  const handleUpdatePriority = async (
    priorityId: string,
    field: FieldOption,
    payload?: { [key: string]: string | number },
  ) => {
    prioritiesUpdateMutation(
      { priorityId, field, payload },
      {
        onSuccess: () => {
          toast.success('Priority updated successfully')
        },
      },
    )
  }

  const handleDelete = () => {
    prioritiesDeleteMutation(
      { priorityId: deleteId },
      {
        onSuccess: () => {
          toast.success('Priority successfully deleted')
          setDeleteModalOpen(false)
        },
      },
    )
  }

  const handleDragEnd = async (e: any) => {
    const { active, over } = e
    if (active.id !== over.id) {
      const oldIndex = priorities?.findIndex((item) => item.id === active.id)
      const newIndex = priorities?.findIndex((item) => item.id === over.id)
      prioritiesUpdateMutation({
        priorityId: priorities[oldIndex],
        field: 'importance',
        payload: { importance: priorities[newIndex].importance },
      })
      queryClient.setQueryData(['priorities', org], arraySwap(priorities, oldIndex, newIndex))
    }
  }

  if (isLoading)
    return (
      <div className='w-full flex justify-center pt-5'>
        <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
      </div>
    )

  return (
    <div className='h-full flex flex-col overflow-hidden'>
      <GlobalSettingsHeader title='Priorities' className='border-b border-gray-300 flex-none'>
        <div className='ml-16 flex h-full items-end gap-x-3'>
          <Button
            className='text-blue-500 flex items-center gap-x-3 border border-blue-500'
            onClick={() => setAddModalOpen(true)}
          >
            <PlusIcon className='w-4 h-4' />
            Priority
          </Button>
        </div>
      </GlobalSettingsHeader>
      <div className='px-8 py-6 text-sm grow overflow-auto'>
        <Tabs active={activeTab} tabs={tabs} onClick={setActiveTab}>
          <TabPanel tab={tabs[0]} active={activeTab}>
            <div className={`w-full max-w-3xl ${isUpdating || isLoading ? 'opacity-50' : 'opacity-100'}`}>
              <DnDSortableList items={priorities?.map((p) => p.id)} onDragEnd={handleDragEnd}>
                {priorities?.map((p) => (
                  <SortableListItem key={p.id} id={p.id} className='group'>
                    <div className='flex items-center justify-between'>
                      <div className='flex items-center gap-x-3'>
                        <IconPicker
                          setSelected={(icon: any) => handleUpdatePriority(p.id, 'icon', { icon: icon.base64string })}
                          options={icons}
                        >
                          <Base64SVG
                            base64image={p.icon}
                            className='flex items-center jusify-center h-5 w-5 [&>svg>*]:fill-slate-500 [&>svg>*]:hover:fill-gray-800'
                          />
                        </IconPicker>
                        <ColorSelect
                          value={p.color}
                          onChange={(color) => handleUpdatePriority(p.id, 'color', { color })}
                          options={colors}
                        />
                        <p className='text-base'>{p.name}</p>
                      </div>
                      <div className='flex items-center gap-x-3'>
                        <div>
                          <p
                            className={classNames(
                              'text-sky-500 cursor-default select-none',
                              p.default ? 'opacity-100' : 'opacity-0',
                            )}
                          >
                            Default
                          </p>
                        </div>
                        <div className='flex items-center gap-x-3 opacity-0 group-hover:opacity-100'>
                          <Button
                            className={classNames('text-blue-500 !p-0', p.default ? 'opacity-0' : 'opacity-100')}
                            onClick={() => handleUpdatePriority(p.id, 'default')}
                          >
                            Make default
                          </Button>
                          <Button
                            className='!p-0'
                            onClick={() => {
                              setEditValue({ id: p.id, key: p.name, color: p.color, icon: p.icon })
                              setEditModalOpen(true)
                            }}
                          >
                            <PencilIcon className='w-4 h-4 text-slate-500 hover:text-gray-800' />
                          </Button>
                          <Button
                            className='!p-0'
                            onClick={() => {
                              setDeleteId(p.id)
                              setDeleteModalOpen(true)
                            }}
                          >
                            <ThrashBinIcon className='w-4 h-4 fill-slate-500 hover:fill-gray-800' />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </SortableListItem>
                ))}
              </DnDSortableList>
            </div>
          </TabPanel>
          <TabPanel tab={tabs[1]} active={activeTab}>
            <TranslationsTable translations={translations} />
          </TabPanel>
        </Tabs>
      </div>
      {addModalOpen && (
        <AddPriorityModal
          open={addModalOpen}
          setOpen={setAddModalOpen}
          onAdd={handleAddPriority}
          isLoading={isAdding}
          onCancel={() => setAddModalOpen(false)}
          icons={icons}
          colors={colors}
          setColors={setColors}
        />
      )}
      {editModalOpen && editValue && (
        <EditPriorityModal
          open={editModalOpen}
          setOpen={setEditModalOpen}
          value={editValue}
          onColorChange={(color) => handleUpdatePriority(editValue.id, 'color', { color })}
          onIconChange={(icon) => handleUpdatePriority(editValue.id, 'icon', { icon })}
          onTranslationKeyChange={(key) => handleUpdatePriority(editValue.id, 'name', { name: key })}
          icons={icons}
          colors={colors}
          setColors={setColors}
        />
      )}
      {deleteModalOpen && (
        <DeleteModal
          title={'Delete priority'}
          description={'Are you sure you want to delte this priority?'}
          isLoading={isDeleting}
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          onDeleteConfirmed={() => handleDelete()}
        />
      )}
    </div>
  )
}

export default PrioritiesPage
