export type Priority = {
  value: number
  label: string
}

export function formatPriorityRange(priority: number): Priority {
  switch (priority) {
    case 0:
      return { value: 0, label: 'Lowest' }
    case 25:
      return { value: 25, label: 'Low' }
    case 50:
      return { value: 50, label: 'Medium' }
    case 75:
      return { value: 75, label: 'High' }
    case 100:
      return { value: 100, label: 'Highest' }
    default:
      return { value: 0, label: 'Lowest' }
  }
}

export const getFilteredPriorities = (value: number) =>
  [
    { value: 100, label: 'Highest' },
    { value: 75, label: 'High' },
    { value: 50, label: 'Medium' },
    { value: 25, label: 'Low' },
    { value: 0, label: 'Lowest' },
  ].filter((o) => o.value !== value) || []
