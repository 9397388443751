import React, { FC } from 'react'
import WidgetWrapper from './WidgetWrapper'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { nFormatter } from '../../utils/numberUtils'

type SimpleWidgetProps = {
  className?: string
  label: string
  value: number
  trend?: number
}

const SimpleWidget: FC<SimpleWidgetProps> = ({ className, label, value, trend }) => {
  return (
    <WidgetWrapper className={`flex flex-col justify-between gap-y-4 font-medium ${className}`}>
      <p>{label}</p>
      <div className='flex gap-x-16 items-baseline'>
        <p className='text-5xl'>{nFormatter(value, 1)}</p>
        {!!trend && (
          <div className={`flex gap-x-1 items-center ${trend > 0 ? 'text-[#1BD7AA]' : 'text-[#E2266A]'}`}>
            {trend > 0 ? (
              <ChevronUpIcon className='w-4 h-4 stroke-2' />
            ) : (
              <ChevronDownIcon className='w-4 h-4 stroke-2' />
            )}
            <p>{Math.abs(trend).toFixed(0)}%</p>
          </div>
        )}
      </div>
    </WidgetWrapper>
  )
}

export default SimpleWidget
