import { Button, Input } from '@sistemiv/s-components'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type VersionInfo = {
  id: string
  name: string
  code: string
  latestVersionNumber: number
  deploymentPackage: string
}

type AddNewVersionOverviewProps = {
  onCancel?(): void
  onAdd(body: any): void
  versionInfo: VersionInfo
}

const AddNewVersionOverview: FC<AddNewVersionOverviewProps> = ({ versionInfo, onAdd, onCancel }) => {
  const [versionTag, setVersionTag] = useState('')
  const [currentVersionSelected, setCurrentVersionSelected] = useState(false)
  const [addLoading, setAddLoading] = useState(false)
  const { t } = useTranslation()

  const handleAdd = () => {
    setAddLoading(true)
    const body = {
      id: versionInfo.id,
      addingVersion: true,
      versionName: versionTag,
      currentVersionSelected: currentVersionSelected,
      // TODO: remove sendbox and testing
      sandboxSelected: false,
      testingSelected: false,
      base64File: versionInfo.deploymentPackage,
    }

    onAdd?.(body)
  }

  return (
    <div className='pt-5 space-y-6'>
      <div className='py-2 px-3 border-l-4 border-sky-500 bg-sky-200 text-sky-500'>
        {t('AddProcessVersionModal.newVersionNotification')}
      </div>
      <p className='font-semibold'>
        {versionInfo.code} - {versionInfo.name}
      </p>
      <div className='flex items-center gap-x-3'>
        <p className='flex-none text-sm'>V.{versionInfo.latestVersionNumber + 1}</p>
        <Input
          type='text'
          id='version_tag'
          removePencil
          mainClasses='!mt-0'
          placeholder={t('AddProcessVersionModal.name') as string}
          value={versionTag}
          onChange={(e) => setVersionTag(e.target.value)}
          classes='!rounded-md !border-solid border border-gray-300 focus:border-sky-100'
        />
      </div>
      <Input
        type='checkbox'
        id='currentVersion'
        label={t('AddProcessVersionModal.setAsCurrent') as string}
        value={currentVersionSelected}
        checked={currentVersionSelected}
        onChange={(e) => setCurrentVersionSelected(e.target.checked)}
      />
      <div className='flex items-center justify-end gap-x-3'>
        <Button className='hover:bg-gray-100 active:text-blue-500' onClick={() => onCancel?.()}>
          {t('Common.cancel')}
        </Button>
        <Button
          className='text-white bg-blue-500 disabled:bg-slate-200 disabled:text-slate-400'
          loading={addLoading}
          onClick={handleAdd}
        >
          {t('Common.add')}
        </Button>
      </div>
    </div>
  )
}

export default AddNewVersionOverview
