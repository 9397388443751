import { XMarkIcon } from '@heroicons/react/24/outline'
import { Input, RangePicker, MultiSelectSearch, Select, Option, EditInput } from '@sistemiv/s-components'
import React, { FC, useState, useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useFiltersOperations } from '../../../../../repositories/settings/processes/filters/fiter-operations.repository'
import { useFiltersFieldValues } from '../../../../../repositories/settings/processes/filters/fiter-values.repository'
import UserService from '../../../../../services/UserService'
import { imageBase } from '../../../../../services/http-common'
import { AssigneeCandidateType } from '../../../../../models/ProcessInstance'

const FilterColumn: FC<{
  value: any
  type: string
  isEdit?: boolean
  onRemove?(value: any): void
  onUpdate?(value: any): void
}> = ({ value, type, isEdit, onRemove, onUpdate }) => {
  const { org } = useParams()
  const [searchParams] = useSearchParams()
  const [groups, setGroups] = useState<any>(null)
  const [orgNodes, setOrgNodes] = useState<any>(null)
  const [usersInOrg, setUsersInOrg] = useState<any>(null)
  const [assigneeOptions, setAssigneeOptions] = useState<any>([])
  const { data: operations = [] } = useFiltersOperations({
    organization: org,
    key: searchParams.get('key') ?? undefined,
    type,
  })
  const { data: values } = useFiltersFieldValues({
    organization: org,
    key: searchParams.get('key') ?? undefined,
    id: value.field,
  })

  useEffect(() => {
    if (!value.field.includes('Assignee')) return
    if (!org) return
    UserService.listAllGroups(org).then((res) => {
      setGroups(res)
    })
    UserService.listAllOrgNodes(org).then((res) => {
      setOrgNodes(res)
    })
    UserService.listAllUsersInOrg(org).then((res) => {
      setUsersInOrg(res)
    })
  }, [org, value])

  useEffect(() => {
    setAssigneeOptions([
      ...(groups?.results?.map((r) => ({
        type: 'b64_value',
        id: r.id,
        value: r.name,
        icon: r.icon,
        tab: 'Groups',
      })) ?? []),
      ...(orgNodes?.results?.map((r) => ({
        type: 'value',
        id: r.id,
        value: r.name,
        tab: 'Org chart',
      })) ?? []),
      ...(usersInOrg?.users?.map((r) => ({
        type: 'circled_value',
        id: r.id,
        value: r.name,
        icon: `${imageBase}/${r.objectId}/76`,
        tab: 'Users',
      })) ?? []),
    ])
  }, [groups, orgNodes, usersInOrg])

  const matcher = /^(?:\d+d (?:\d+)h (?:\d+)m|\d+d (?:(?:\d+)h|(?:\d+)m)|(?:\d+)h (?:\d+)m|\d+d|(?:\d+)h|(?:\d+)m)$/

  return (
    <div key={value?.field} className='w-full flex flex-col gap-4'>
      <div className='px-3 py-0.5 w-fit rounded-full border-gray-400 border bg-gray-200 flex gap-2 items-center'>
        <small className='text-gray-600'>{value?.field}</small>
        <button
          className='w-4 h-4 hover:cursor-pointer text-gray-500 hover:text-gray-600'
          onClick={() => onRemove?.(value)}
        >
          <XMarkIcon />
        </button>
      </div>
      <div className='grid grid-cols-2 gap-y-2 gap-x-3'>
        <>
          <Select value={value.operation ?? undefined} onChange={(e) => onUpdate?.({ ...value, operation: e })}>
            {operations
              ?.filter((item) => item !== value.operation)
              ?.map((item, index) => (
                <Option key={`size-${index}`} value={item}>
                  <div data-testid={`page-size-${item}`}>{item}</div>
                </Option>
              ))}
          </Select>
          {value.operation &&
            (value?.field.includes('DueDate') ? (
              isEdit ? (
                <EditInput
                  className={`peer !border !border-solid !border-gray-300 text-sm !py-2.5 ${
                    value.value && value.value !== '' && !matcher.test(value.value)
                      ? 'focus:!border-red-400 focus:!ring-red-400'
                      : 'focus:!border-sky-100'
                  } relative`}
                  placeholder='2h 30m'
                  value={value.value}
                  onSave={(e) => {
                    onUpdate?.({
                      ...value,
                      value: e,
                    })
                  }}
                />
              ) : (
                <Input
                  id='time input'
                  type='text'
                  removePencil
                  mainClasses='peer !m-0'
                  classes={`peer !border !border-solid !border-gray-300 text-sm !py-2.5 ${
                    value.value && value.value !== '' && !matcher.test(value.value)
                      ? 'focus:!border-red-400 focus:!ring-red-400'
                      : 'focus:!border-sky-100'
                  } relative`}
                  placeholder='2h 30m'
                  value={value.value}
                  onChange={(e) => {
                    onUpdate?.({ ...value, value: e.target.value })
                  }}
                />
              )
            ) : value?.field?.toLowerCase().includes('created') ? (
              <RangePicker
                className='border border-gray-300 rounded'
                startDate={value.value?.[0]}
                endDate={value.value?.[1]}
                onRangeChange={(startDate, endDate) => {
                  onUpdate?.({ ...value, value: [startDate, endDate] })
                }}
              />
            ) : value?.field?.toLowerCase().includes('priority') ? (
              <Input
                classes='!py-2 !rounded'
                type='priority'
                id={value?.field}
                value={value.value?.[0]}
                onChange={(e) => onUpdate?.({ ...value, value: [e] })}
                selectOptions={[
                  { value: 100, label: 'Highest' },
                  { value: 75, label: 'High' },
                  { value: 50, label: 'Medium' },
                  { value: 25, label: 'Low' },
                  { value: 0, label: 'Lowest' },
                ].filter((o) => o.value !== value.value)}
              />
            ) : value?.field?.toLowerCase().includes('assignee') ? (
              <MultiSelectSearch
                className={`${value.value?.length > 0 ? '!py-2' : '!py-2.5'}`}
                assignee
                options={assigneeOptions}
                value={assigneeOptions.filter((o) => value.value?.includes(o.id))}
                onChange={(e) => {
                  onUpdate?.({ ...value, value: e?.map((v) => v.id) })
                  onUpdate?.({
                    field: 'activities.assigneeType',
                    operation: 'eql',
                    value:
                      e?.[0]?.tab === 'Users'
                        ? AssigneeCandidateType.User
                        : e?.[0]?.tab === 'Groups'
                        ? AssigneeCandidateType.Group
                        : e?.[0]?.tab === 'Org chart'
                        ? AssigneeCandidateType.OrganizationNode
                        : '',
                  })
                }}
              />
            ) : value?.field?.toLowerCase().includes('activity') ? (
              <MultiSelectSearch
                className={`${value.value?.length > 0 ? '!py-2' : '!py-2.5'}`}
                options={
                  values?.values
                    ?.map((option) => ({
                      type: 'solid',
                      value: option.name,
                      id: option.id,
                      solidColor: '#DEE1FC',
                    }))
                    ?.filter((value, index, self) => {
                      return index === self.findIndex((o) => o.id === value.id)
                    }) ?? []
                }
                value={
                  values?.values
                    ?.map((option) => ({
                      type: 'solid',
                      value: option.name,
                      id: option.id,
                      solidColor: '#DEE1FC',
                    }))
                    ?.filter((value, index, self) => {
                      return index === self.findIndex((o) => o.id === value.id)
                    })
                    ?.filter((o) => value.value?.includes(o.id)) ?? []
                }
                onChange={(e) => onUpdate?.({ ...value, value: e?.map((v) => v.id) })}
              />
            ) : value?.field?.toLowerCase().includes('resolution') ? (
              <MultiSelectSearch
                className={`${value.value?.length > 0 ? '!py-2' : '!py-2.5'}`}
                options={
                  values?.values
                    ?.map((option) => ({
                      type: 'border',
                      value: option.name,
                      id: option.id,
                      borderColor: '#637890',
                    }))
                    ?.filter((value, index, self) => {
                      return index === self.findIndex((o) => o.id === value.id)
                    }) ?? []
                }
                value={
                  values?.values
                    ?.map((option) => ({
                      type: 'border',
                      value: option.name,
                      id: option.id,
                      borderColor: '#637890',
                    }))
                    ?.filter((value, index, self) => {
                      return index === self.findIndex((o) => o.id === value.id)
                    })
                    ?.filter((o) => value.value?.includes(o.id)) ?? []
                }
                onChange={(e) => onUpdate?.({ ...value, value: e?.map((v) => v.id) })}
              />
            ) : isEdit ? (
              <EditInput
                value={value.value}
                onSave={(e) =>
                  onUpdate?.({
                    ...value,
                    value: e,
                  })
                }
                className='!m-0 border border-gray-300 rounded'
              />
            ) : (
              <Input
                value={value.value}
                onChange={(e) => onUpdate?.({ ...value, value: e.target.value })}
                type='text'
                id={value.field}
                mainClasses='!m-0 border border-gray-300 rounded'
                removePencil
              />
            ))}
        </>
      </div>
    </div>
  )
}

export default FilterColumn
