import React, { FC } from 'react'

type ResolutionProps = { resolution: string }

const Resolution: FC<ResolutionProps> = ({ resolution }) => {
  return (
    <div
      className='text-[color:var(--status-last,#00C595)] text-[13px] not-italic font-normal leading-[normal] tracking-[-0.39px] border border-[color:var(--status-last,#00C595)] rounded-[4px] px-[6px] py-[2px]  capitalize
'
    >
      {resolution}
    </div>
  )
}

export default Resolution
