import React, { FC } from 'react'
import lockIcon from '../assets/icons/lock-icon.png'
import { Button, EmptyPage } from '@sistemiv/s-components'
import { useNavigate } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'

const NotAuthorized: FC = () => {
  const { instance } = useMsal()
  const navigate = useNavigate()

  return (
    <EmptyPage imageUrl={lockIcon} imageAlt={'Not authorized'} message={"You're not authorized to access this page"}>
      <Button className='text-sky-500 text-lg hover:bg-gray-100 transition-all' onClick={() => navigate(-1)}>
        Go Back
      </Button>
      <Button
        className='text-sky-500 text-lg hover:bg-gray-100 transition-all'
        onClick={() => instance.logoutRedirect()}
      >
        Log in from another account
      </Button>
    </EmptyPage>
  )
}

export default NotAuthorized
