import React, { FC, PropsWithChildren } from 'react'
import { PropsWithClassName } from '@sistemiv/s-components/dist/esm/types/types'
import classNames from '../../../classNames'

const GlobalSettingsHeader: FC<PropsWithChildren<PropsWithClassName<{ title: string }>>> = ({
  title,
  className,
  children,
}) => {
  return (
    <div className={classNames(className ?? '', 'px-6 py-4 flex items-center justify-start')}>
      <div>
        <p className='px-1 py-2 text-sm text-gray-400'>S-Process</p>
        <p className='text-xl'>{title}</p>
      </div>
      <div className='h-full'>{children}</div>
    </div>
  )
}

export default GlobalSettingsHeader
