import { useQuery } from '@tanstack/react-query'
import UserService from '../../services/UserService'
import { UserProfile } from '../../models/User'
type UserOptions = {
  userId?: string
  enabled?: boolean
}
export const useProfile = ({ userId, enabled }: UserOptions) => {
  return useQuery<UserProfile>({
    queryKey: ['user', userId],
    queryFn: () => UserService.getUserProfile(userId!),
    enabled: !!userId && enabled,
  })
}
