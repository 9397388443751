import React, { FC } from 'react'
import HistoryItem from './HistoryItem'
import { useListAllUsersInOrg } from '../../../repositories/settings/processes/processes/list-all-users-in-org.repository'
import { SpinnerIcon } from '@sistemiv/s-components'
import { useProcessInstanceHistory } from '../../../repositories'
import { SignalRContext } from '../../../pages/Dashboard'
import { useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useListAllGroupsInOrg } from '../../../repositories/settings/processes/processes/list-all-groups-in-org.repository'
import { useListAllNodesInOrg } from '../../../repositories/settings/processes/processes/list-all-nodes-in-org.repository'

export type ProcessInstanceHistoryProps = {
  sortOrder: string
}

const HistoryTab: FC<ProcessInstanceHistoryProps> = ({ sortOrder }) => {
  const { data: instanceHistory, isLoading: isHistoryLoading } = useProcessInstanceHistory(sortOrder)
  const { data: usersInOrg, isLoading } = useListAllUsersInOrg()
  const { data: groups, isLoading: isGroupsLoading } = useListAllGroupsInOrg()
  const { data: nodes, isLoading: isNodesLoading } = useListAllNodesInOrg()
  const { org, id } = useParams()
  const queryClient = useQueryClient()

  SignalRContext.useSignalREffect(
    'sync',
    //@ts-ignore
    (eventName, data) => {
      if (
        ['UserTaskEndedEvent', 'UserTaskStartedEvent', 'UserTaskAssigneeChangedEvent'].includes(eventName) &&
        id === data.ProcessInstanceId
      ) {
        queryClient.invalidateQueries({ queryKey: ['process-instance-history', org, id, sortOrder] })
      }
    },
    [],
  )

  return (
    <div data-testid='history-tab' className='h-full overflow-auto info-tab-scroll-bar'>
      {isLoading || isHistoryLoading || isGroupsLoading || isNodesLoading ? (
        <div className='w-full flex justify-center pt-5'>
          <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
        </div>
      ) : (
        <div className='flex flex-col w-full justify-start items-center'>
          {instanceHistory &&
            instanceHistory?.map((item, index) => {
              return <HistoryItem key={index} item={item} users={usersInOrg.users} groups={groups} nodes={nodes} />
            })}
        </div>
      )}
    </div>
  )
}

export default HistoryTab
