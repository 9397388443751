import { useQuery } from '@tanstack/react-query'
import FiltersService from '../../../../services/Filters.service'

type FiltersFieldValuesType = {
  organization?: string
  key?: string
  id?: string
  enabled?: boolean
}

export const useFiltersFieldValues = ({ organization, key, id }: FiltersFieldValuesType) => {
  return useQuery({
    queryKey: ['filters-operations', organization, key, id],
    queryFn: () => FiltersService.getFieldValues(organization!, key!, id!, { page: 0, size: 20 }),
    enabled:
      !!key &&
      !!organization &&
      !!id &&
      ((id?.includes('Activity') && !id?.includes('DueDate')) || id?.includes('Resolution')) &&
      !id.includes('Assignee'),
  })
}
