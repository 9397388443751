import { useQuery } from '@tanstack/react-query'
import LinkTypesService from '../../../../services/LinkTypes.service'

type LinkTypesOptions = {
  org?: string
}

export const useLinkTypes = ({ org }: LinkTypesOptions) => {
  return useQuery<any>({
    queryKey: ['link-types', org],
    queryFn: () => LinkTypesService.list(org),
    enabled: !!org,
  })
}
