import { useMutation, useQueryClient } from '@tanstack/react-query'
import WebhooksService from '../../../../../services/Webhooks.service'

type UpdateWebhookPayload = {
  organization: string
  id: string
  value: string[]
}

export const useAddWebhookAccess = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, UpdateWebhookPayload>({
    mutationKey: ['webhook-add-access'],
    mutationFn: (payload) => WebhooksService.updateWebhookAccess(payload.id, payload.organization, payload.value),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['webhooks-access'] })
    },
  })
}
