import React, { FC } from 'react'
import WidgetWrapper from './WidgetWrapper'
import correctIcon from '../../assets/icons/correct-icon.svg'

const SuccessWidget: FC<{ title: string; message: string }> = ({ title, message }) => {
  return (
    <WidgetWrapper className='flex flex-col gap-y-4 bg-white'>
      <p className='text-slate-500 font-medium'>{title}</p>
      <div className='bg-green-200 flex items-center gap-x-3 px-3 py-2 rounded-md'>
        <img src={correctIcon} />
        <p>{message}</p>
      </div>
    </WidgetWrapper>
  )
}

export default SuccessWidget
