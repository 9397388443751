import {
  ChartData,
  Chart as ChartJS,
  TimeSeriesScale,
  LineElement,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
} from 'chart.js'
import React, { FC } from 'react'
import { Line } from 'react-chartjs-2'
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm'

ChartJS.register(TimeSeriesScale, LineElement, LinearScale, PointElement, Legend, Tooltip)

const TimeSeriesChart: FC<{ data: ChartData<'line'> }> = ({ data }) => {
  return (
    <Line
      height={'300px'}
      data={data}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'timeseries',
            time: {
              unit: 'day',
            },
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            align: 'end',
            labels: {
              boxHeight: 0,
              padding: 20,
            },
          },
        },
      }}
    />
  )
}

export default TimeSeriesChart
