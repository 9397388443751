import React, { FC, useState } from 'react'
import { ProcessDefinitionVersion } from '../../../../models/ProcessDefinitionSettings'
import { Switch } from '@headlessui/react'
import { PencilIcon } from '@heroicons/react/24/solid'
import { Button, EditProcessVersionModal, SpinnerIcon } from '@sistemiv/s-components'
import { useNavigate, useParams } from 'react-router-dom'
import ProcessDefinitionsSettings from '../../../../services/ProcessDefinitionsSettings'
import { useProcessDefault } from '../../../../repositories/settings/processes/processes'
import { useTranslation } from 'react-i18next'

const ProcessItemVersion: FC<{
  processId: string
  processKey: string
  version: ProcessDefinitionVersion
  versions: ProcessDefinitionVersion[]
  disableActivate: boolean
  clone?: boolean
  depth: number
  refreshData?(): void
}> = ({ processId, processKey, version, versions, clone, disableActivate, depth, refreshData }) => {
  const { org } = useParams()
  const navigate = useNavigate()
  const [changingState, setChangingState] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const { mutateAsync: processDefaultMutation } = useProcessDefault()
  const { t } = useTranslation()
  const handleChangeVersionStatus = () => {
    if (!org) return
    setChangingState(true)
    ProcessDefinitionsSettings.changeVersionStatus(org, processId, version.id, !version.active)
      .then(() => {
        refreshData?.()
        setChangingState(false)
      })
      .catch(() => {
        setChangingState(false)
      })
  }
  const handleUpdate = (field: string, value: any) => {
    if (!org) return
    ProcessDefinitionsSettings.editProcessVersion(org, processId, version.id, { tag: value }).then(() => {
      refreshData?.()
    })
  }

  const setAsCurrent = () => {
    processDefaultMutation({ processId: processId, versionId: version.id }).then(() => refreshData?.())
  }
  return (
    <div className={`flex group items-center hover:bg-sky-50 py-2 pr-3 pl-28 ${clone ? 'opacity-0' : 'opacity-100'}`}>
      <div className='grow grid grid-cols-2'>
        <div
          style={{ paddingLeft: `${(depth + 1) * 29}px` }}
          className={`${version.active ? 'text-gray-800' : 'text-gray-400'} flex items-center gap-x-3`}
        >
          <p>V.{version.versionNumber}</p>
          <p className='px-2 bg-[#E0F4FF] rounded-sm'>{version.versionTag}</p>
          {version.current && <span className='text-blue-500 text-sm'>{t('Settings.currentVersion')}</span>}
        </div>

        <div className='flex relative items-center justify-end align-end gap-x-5'>
          {/* TODO: will be implemented when API is ready */}
          {!version.current && !disableActivate && (
            <Button
              className='!p-0 text-blue-500 font-medium opacity-0 group-hover:opacity-100'
              dataTestId={`process-version-${version.versionNumber}-setAsCurrent`}
              onClick={() => setAsCurrent()}
            >
              {t('Settings.setAsCurrent')}
            </Button>
          )}

          <div
            className='group/switch flex items-center justify-center gap-x-2'
            data-testid={`process-version-${version.versionNumber}-activate`}
          >
            {changingState && <SpinnerIcon className='w-3 h-3 text-blue-500' />}
            <Switch
              disabled={version.current || changingState || disableActivate}
              checked={version.active}
              onChange={handleChangeVersionStatus}
              className={`border ${
                version.active ? 'border-blue-500' : 'border-gray-400'
              } relative inline-flex h-[12px] w-[24px] shrink-0 cursor-pointer rounded-full border-2 transition-colors 
                  duration-50 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white disabled:opacity-50
                  focus-visible:ring-opacity-75 disabled:cursor-default`}
            >
              <span className='sr-only'>Use setting</span>
              <span
                aria-hidden='true'
                className={`${version.active ? 'translate-x-3' : 'translate-x-0'}
                                pointer-events-none inline-block h-[8px] w-[8px] transform rounded-full border ${
                                  version.active ? 'bg-blue-500' : 'bg-gray-400'
                                } shadow-lg ring-0 transition duration-50 ease-in-out`}
              />
            </Switch>
            {!changingState && !version.current && (
              <span className='absolute w-max top-full mt-1.5 scale-0 rounded bg-slate-500 p-2 text-xs z-20 text-white group-hover/switch:scale-100'>
                {version.active ? t('Settings.deactivateVersion') : t('Settings.activateVersion')}
              </span>
            )}
          </div>
          <div className='flex items-center justify-end align-end gap-x-5 opacity-0 group-hover:opacity-100'>
            <Button
              className='!p-0'
              tooltip={t('Settings.editVersion') as string}
              dataTestId={`process-version-${version.versionNumber}-edit`}
              onClick={() => {
                setEditModalOpen(true)
              }}
            >
              <PencilIcon className='w-4 h-4 text-slate-500 hover:text-gray-800' />
            </Button>
            {/* <Button className='!p-0 group' tooltip='Export version' disabled={true}>
              <ExportProcessIcon className='w-4 h-4 fill-slate-500 hover:fill-gray-800 group-disabled:fill-slate-500 group-disabled:opacity-50' />
            </Button> */}

            {/* <Button disabled={true} tooltip='Remove version' className={`!p-0 group `}>
              <ThrashBinIcon
                className={`w-4 h-4 fill-slate-500 hover:fill-gray-800 group-disabled:!opacity-50 group-disabled:!fill-slate-500`}
              />
            </Button> */}

            <Button
              className='!p-0 text-blue-500 font-medium'
              dataTestId={`process-version-${version.versionNumber}-config`}
              onClick={() =>
                navigate(
                  `/${org}/settings/flow?process=${processId}&key=${processKey}&version=${
                    versions.find((_version) => _version.versionNumber === version.versionNumber)?.id
                  }&versionId=`,
                )
              }
            >
              {t('Settings.config')}
            </Button>
          </div>
        </div>
      </div>
      {editModalOpen && (
        <EditProcessVersionModal
          open={editModalOpen}
          setOpen={setEditModalOpen}
          version={version}
          onUpdate={handleUpdate}
        />
      )}
    </div>
  )
}

export default ProcessItemVersion
