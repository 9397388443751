import React, { FC } from 'react'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import ModalDialog from '../../../../../ModalDialog'
import { EditInput } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'

type RenameSectionModalProps = {
  open: boolean
  setOpen: DispatchSetAction<boolean>
  renameVariable?: boolean
  value?: string
  onSave?: (value: any) => void
}

const RenameSectionModal: FC<RenameSectionModalProps> = ({ value, open, renameVariable, setOpen, onSave }) => {
  const { t } = useTranslation()
  return (
    <ModalDialog isOpen={open} setOpen={setOpen}>
      <Dialog.Title as='h3' className='leading-6 text-lg flex justify-between'>
        {t('Settings.variables.renameModalTitle', { modalType: renameVariable ? 'variable' : 'section' })}
        <button onClick={() => setOpen(false)}>
          <XMarkIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </Dialog.Title>

      <div className='mt-5'>
        <EditInput value={value} onSave={onSave} placeholder={t('Settings.variables.name') as string} />
      </div>
    </ModalDialog>
  )
}

export default RenameSectionModal
