import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ProcessDefinitionsSettings from '../../../../../services/ProcessDefinitionsSettings'

export const useAddNewProcess = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()

  return useMutation<any, Error, { body }>({
    mutationKey: ['add-new-process'],
    mutationFn: (payload) =>
      payload.body.addingVersion && payload.body.id !== ''
        ? ProcessDefinitionsSettings.addProcessVersion(org!, payload.body.id, {
            versionTag: payload.body.versionName,
            deploymentPackage: payload.body.base64File,
            makeCurrent: payload.body.currentVersionSelected,
          })
        : payload.body.escalationAssignee && ProcessDefinitionsSettings.create(org!, payload.body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['process-versions'] })
    },
  })
}
