import { useQuery, keepPreviousData } from '@tanstack/react-query'
import ProcessInstancesService from '../../services/ProcessInstances.service'
import { useParams } from 'react-router-dom'

type ProcessInstancesOptions = {
  params?: any
  enabled?: boolean
  page: number
  size: number
}

export const useProcessInstances = ({ params, enabled = true, page, size }: ProcessInstancesOptions) => {
  const { org, type } = useParams()
  return useQuery<any>({
    queryKey: ['process-instances', org, type, { page, size, ...params }],
    queryFn: () => ProcessInstancesService.list(org!, type!, page, size, params),
    placeholderData: keepPreviousData,
    staleTime: 0,
    enabled: !!org && !!type && enabled,
  })
}
