import { useQuery } from '@tanstack/react-query'
import VariablesService from '../../../../services/Variables.service'

type VariablesScopeOptions = {
  organization?: string
  key?: string
  id?: string
  params?: any
}

export const useVariablesByScope = ({ organization, key, id, params }: VariablesScopeOptions) => {
  return useQuery<string[]>({
    queryKey: ['variables', organization, key, id, { params }],
    queryFn: () => VariablesService.getByScope(organization!, key!, id!, params),
    enabled: !!organization && !!key && !!id,
  })
}
