import { useMutation, useQueryClient } from '@tanstack/react-query'
import PrioritiesService from '../../../../../services/Priorities.service'
import { useParams } from 'react-router-dom'

type DeletePriorityOptions = {
  priorityId: string
}

export const useDeletePriority = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()
  return useMutation<any, Error, DeletePriorityOptions>({
    mutationKey: ['deletePriority'],
    mutationFn: (payload) => PrioritiesService.delete(payload.priorityId, org),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['priorities', org] })
    },
  })
}
