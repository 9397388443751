import { useMutation, useQueryClient } from '@tanstack/react-query'
import ProcessDefinitionsSettings from '../../../../../services/ProcessDefinitionsSettings'

type ChangeAllowBulk = {
  organization: string
  versionId: string
  taskId: string
  value: boolean
}

export const useChangeAllowBulk = () => {
  const queryClient = useQueryClient()
  return useMutation<any, any, ChangeAllowBulk>({
    mutationKey: ['change-allow-bulk'],
    mutationFn: ({ organization, versionId, taskId, value }) =>
      ProcessDefinitionsSettings.changeBulkAction(organization, versionId, taskId, value),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user-tasks-settings'] })
    },
  })
}
