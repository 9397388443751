import { useMutation, useQueryClient } from '@tanstack/react-query'
import TasksService from '../../../services/Tasks.service'
import { useParams } from 'react-router-dom'

type TaskDeescalateOptions = {
  id: string
  body: any
}

export const useTaskDeescalate = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()
  return useMutation<any, Error, TaskDeescalateOptions>({
    mutationKey: ['deescalate-task'],
    mutationFn: (payload) => TasksService.deescalateTask(org!, payload.id, payload.body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] })
    },
  })
}
