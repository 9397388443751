import { axiosMgmtInstance } from './http-common'

class LinkTypesService {
  async list(org?: string) {
    if (!org) return
    return (await axiosMgmtInstance.get(`/${org}/link-types`)).data
  }

  async delete(id: string, org?: string) {
    if (!org) return
    return (await axiosMgmtInstance.delete(`/${org}/link-type/${id}`)).data
  }

  async add(link: { linkTypeTo: string; linkTypeFrom: string }, org?: string) {
    if (!org) return
    return (await axiosMgmtInstance.post(`/${org}/link-types`, link)).data
  }

  async update(id: string, field: string, body: { [key: string]: string | number }, org?: string) {
    if (!org) return
    return (await axiosMgmtInstance.post(`/${org}/link-types/${id}/${field}`, body)).data
  }
}
export default new LinkTypesService()
