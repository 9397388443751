import { useQuery } from '@tanstack/react-query'
import ProcessDefinitionsSettings from '../../../../services/ProcessDefinitionsSettings'
import { ProcessDefinitionResponse, ProcessDefinition } from '../../../../models/ProcessDefinitionSettings'
import { ProcessSchema } from '../../../../models/ProcessSchema'

type ProcessFlatType = {
  org?: string
}

export const useProcessFlat = ({ org }: ProcessFlatType) => {
  return useQuery<ProcessDefinition[]>({
    queryKey: ['process-definition-versions', org],
    queryFn: async () => {
      const data = await ProcessDefinitionsSettings.list(org!, { versions: true })
      const flattenedItems = flatten(data)
      return flattenedItems.filter(({ type }) => type === 'ProcessItem') ?? []
    },
    enabled: !!org,
    staleTime: 0,
  })
}

const flatten = (array?: ProcessDefinitionResponse[]): any => {
  return array
    ? array.reduce<ProcessSchema[]>((acc, item) => {
        return [...acc, { ...item }, ...flatten('leaves' in item ? item.leaves : [])]
      }, [])
    : []
}
