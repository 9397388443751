import { useParams } from 'react-router-dom'
import TasksService from '../../services/Tasks.service'
import { useQuery } from '@tanstack/react-query'

export const useTaskDetails = (idSelected: string) => {
  const { org } = useParams()
  return useQuery<any>({
    queryKey: ['tasks-details', org, idSelected],
    queryFn: () => {
      console.log('run query ' + idSelected)
      return idSelected && TasksService.getTaskDetail(org!, idSelected)
    },
    enabled: !!org && !!idSelected && idSelected !== '' && idSelected?.length > 3,
    staleTime: 0,
  })
}
