import { Combobox, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/outline'
import { DataField } from '@sistemiv/s-components'
import { TableField } from '@sistemiv/s-components/dist/esm/components/table/Table'
import React, { FC, Fragment, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

type AccessComboboxProps = {
  onChange: (value: any) => void
  options: TableField[]
  inputPlaceholder?: string
  placeholder?: string
  topMenu?: boolean
}

const AccessCombobox: FC<AccessComboboxProps> = ({ onChange, options, placeholder, inputPlaceholder, topMenu }) => {
  const [query, setQuery] = useState('')
  const { t } = useTranslation()
  const comboboxButtonRef = useRef<HTMLButtonElement>(null)
  const comboboxInputRef = useRef<HTMLInputElement>(null)

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) =>
          option.value.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')),
        )

  return (
    <Combobox onChange={onChange}>
      {({ open }) => (
        <div className='relative'>
          <div className='relative w-full cursor-default rounded-md bg-white text-left'>
            {!open && (
              <div
                className='w-full cursor-pointer border border-gray-300 rounded-md py-2 pl-8 pr-3 text-gray-900 leading-5'
                onClick={() => {
                  if (!open) {
                    comboboxButtonRef?.current?.click()
                  }
                }}
              >
                <div className='text-gray-400'>{placeholder}</div>
              </div>
            )}
            {open && (
              <Combobox.Input
                ref={comboboxInputRef}
                onChange={(e) => setQuery(e.target.value)}
                displayValue={(value: any) => {
                  return open ? '' : value
                }}
                placeholder={inputPlaceholder}
                className={`w-full border-gray-300 rounded-md py-2 text-gray-900 leading-5 ${
                  open
                    ? 'ring-2 focus:ring-2 ring-sky-100 !border-sky-100 focus:border-sky-100 focus:ring-sky-100 pl-9 pr-3'
                    : 'pr-3 pl-9'
                }`}
              />
            )}
            <span className={`absolute inset-y-0 left-0 items-center pl-2 ${open ? 'flex' : 'hidden'}`}>
              <MagnifyingGlassIcon className='w-4 h-4 text-gray-400' />
            </span>
            <Combobox.Button
              onClick={() => {
                comboboxInputRef.current?.focus()
              }}
              className={`absolute inset-y-0 left-0 items-center pl-2 ${open ? 'hidden' : 'flex'}`}
              ref={comboboxButtonRef}
            >
              <PlusIcon className='w-4 h-4 text-gray-400' />
            </Combobox.Button>
          </div>

          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options
              className={`absolute z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${
                topMenu ? 'mb-1 bottom-full' : 'mt-1 top-full'
              }`}
            >
              {filteredOptions.length === 0 && query !== '' ? (
                <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                  {t('Common.nothingFound')}
                </div>
              ) : (
                filteredOptions.map((option, index) => (
                  <Combobox.Option
                    key={`option-${index}`}
                    value={option}
                    className='w-full cursor-pointer py-2 px-4 hover:bg-slate-100'
                  >
                    <DataField field={option} />
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      )}
    </Combobox>
  )
}

export default AccessCombobox
