import dayjs from 'dayjs'

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/

const datePickerDateFormat = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/

function isDateFormat(value: any): boolean {
  return value && typeof value === 'string' && datePickerDateFormat.test(value)
}

function isIsoDateString(value: any): boolean {
  return value && typeof value === 'string' && isoDateFormat.test(value)
}

export function handleDates(body: any) {
  if (!body || typeof body !== 'object') return body

  for (const key of Object.keys(body)) {
    const value = body[key]
    if (isIsoDateString(value) || isDateFormat(value) || dayjs(value).isValid()) body[key] = dayjs(value).toDate()
    else if (typeof value === 'object') handleDates(value)
  }
}

export const relativeDueTime = (date?: Date) => {
  const dayjsDate = dayjs(date)
  const now = dayjs()
  if (!date) return ''
  return Math.abs(dayjsDate.diff(now, 'year')) > 0
    ? `${Math.abs(dayjsDate.diff(now, 'year'))}y`
    : Math.abs(dayjsDate.diff(now, 'month')) > 0
    ? `${Math.abs(dayjsDate.diff(now, 'month'))}mo`
    : Math.abs(dayjsDate.diff(now, 'week')) > 0
    ? `${Math.abs(dayjsDate.diff(now, 'week'))}w`
    : Math.abs(dayjsDate.diff(now, 'day')) > 0
    ? `${Math.abs(dayjsDate.diff(now, 'day'))}d`
    : `${Math.abs(dayjsDate.diff(now, 'hour'))}h`
}

export const calculateTimePassed = (startedDateString: string, completedDateString?: string): string => {
  const now = completedDateString ? dayjs(completedDateString) : dayjs()
  const targetDate = dayjs(startedDateString)
  const diffDuration = now.diff(targetDate).toFixed(0)
  const minutes = new Number(diffDuration).valueOf() / 60000
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const formattedTimeRemaining = `${days > 0 ? `${days}d` : ''} ${hours > 0 ? `${hours % 24}h` : ''} ${
    minutes > 0 ? `${(minutes % 60).toFixed(0)}m` : ''
  }`
  return formattedTimeRemaining
}

export const calculateTimeRemaining = (dateString: string, completedDateString?: string): string => {
  const now = dayjs()
  const targetDate = dayjs(dateString)
  const diffDuration = completedDateString ? targetDate.diff(dayjs(completedDateString)) : targetDate.diff(now)
  const absoluteDiffDuration = Math.abs(diffDuration)
  const minutes = absoluteDiffDuration / 60000
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const formattedTimeRemaining = `${diffDuration < 0 ? '-' : ''}${days > 0 ? `${days}d` : ''} ${
    hours > 0 ? `${hours % 24}h` : ''
  } ${minutes > 0 ? `${(minutes % 60).toFixed(0)}m` : ''}`
  return formattedTimeRemaining
}
