import React, { FC, PropsWithChildren } from 'react'

type InfoTabFieldWrapperProps = {
  label: string
  className?: string
}

const InfoTabFieldWrapper: FC<PropsWithChildren<InfoTabFieldWrapperProps>> = ({ children, label, className }) => {
  return (
    <div className='flex flex-col items-start gap-1.5 self-stretch px-0 py-1.5'>
      <h1 className='text-[#8087A0] text-sm'>{label}</h1>
      <div className={className}>{children}</div>
    </div>
  )
}

export default InfoTabFieldWrapper
