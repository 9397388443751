import { Base64SVG, SideMenuItem } from '@sistemiv/s-components'
import React, { FC } from 'react'
import classNames from '../../classNames'

import { useNavigate, useParams } from 'react-router-dom'
import { ProcessSchemaSideMenu } from '../../models/ProcessSchema'

const SideMenuProcessItem: FC<{ process: ProcessSchemaSideMenu; isSideOpen: boolean }> = ({ process, isSideOpen }) => {
  const { org, nav, type } = useParams()
  const navigate = useNavigate()
  return (
    <SideMenuItem
      classes={classNames(
        '[&_svg>*]:active:fill-white active:bg-blue-500 active:text-white leading-5',
        isSideOpen ? 'items-center gap-x-3 px-9 py-3.5' : 'justify-center items-center py-3.5',
        nav === 'process-instance' && type === process.processDefinitionKey
          ? 'bg-blue-500 text-white hover:!bg-blue-500 hover:!text-white'
          : '',
      )}
      onClick={() => navigate(`/${org}/process-instance/${process.processDefinitionKey}`)}
    >
      <Base64SVG
        base64image={process.icon}
        className={classNames(
          '[&>svg]:w-5 [&>svg]:h-5 flex-none',
          nav === 'process-instance' && type === process.processDefinitionKey
            ? '[&>svg>*]:fill-white [&>svg>*]:hover:fill-white'
            : '[&>svg>*]:fill-black ',
        )}
      />

      <p className='whitespace-nowrap overflow-hidden text-ellipsis'>{isSideOpen && process.name}</p>
    </SideMenuItem>
  )
}

export default SideMenuProcessItem
