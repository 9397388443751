import { useParams } from 'react-router-dom'
import TasksService from '../../services/Tasks.service'
import { useQuery } from '@tanstack/react-query'

export const useTasksAll = () => {
  const { org } = useParams()
  return useQuery<any>({
    queryKey: ['tasks-all', org],
    queryFn: () => TasksService.listAllForSearch(org!),
    enabled: !!org,
  })
}
