import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import WebhooksService from '../../../../services/Webhooks.service'

type IncidentsOptions = {
  page: number
  size: number
  nameSearch?: string
  eventSearch?: string
  dataSearch?: string
  errorSearch?: string
}

export const useIncidents = ({ page, size, nameSearch, dataSearch, eventSearch, errorSearch }: IncidentsOptions) => {
  const { org } = useParams()
  return useQuery<any>({
    queryKey: ['incidents', org, { page, size, nameSearch, eventSearch, dataSearch, errorSearch }],
    queryFn: () => WebhooksService.listIncidents(org!, page, size, nameSearch, eventSearch, dataSearch, errorSearch),
    placeholderData: keepPreviousData,
    enabled: !!org,
  })
}
