import { useMutation, useQueryClient } from '@tanstack/react-query'
import PrioritiesService from '../../../../../services/Priorities.service'
import { useParams } from 'react-router-dom'

type AddPriorityOptions = {
  name: string
  color: string
  icon: string
}

export const useAddPriority = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()
  return useMutation<any, Error, AddPriorityOptions>({
    mutationKey: ['addPriority'],
    mutationFn: (payload) => PrioritiesService.add(payload, org),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['priorities', org] })
    },
  })
}
