import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import ProcessDefinitionsSettings from '../../../../services/ProcessDefinitionsSettings'

export const useListUsersInOrg = (itemId: string) => {
  const { org } = useParams()
  return useQuery({
    queryKey: ['list-users-in-org', itemId],
    queryFn: () => ProcessDefinitionsSettings.getProcessAccess(org!, itemId),
    enabled: !!org && !!itemId,
  })
}
