import { useMutation, useQueryClient } from '@tanstack/react-query'
import ProcessDefinitionsSettings from '../../../../../services/ProcessDefinitionsSettings'

type ChangeAssigneeCandidatePayload = {
  organization: string
  versionId: string
  taskId: string
  assigneeType: string
  assigneeId?: string
}

export const useChangeAssigneeCandidate = () => {
  const queryClient = useQueryClient()
  return useMutation<any, any, ChangeAssigneeCandidatePayload>({
    mutationKey: ['change-mutation-candidate'],
    mutationFn: ({ organization, versionId, taskId, assigneeType, assigneeId }) =>
      ProcessDefinitionsSettings.changeAssigneeCandidate(organization, versionId, taskId, assigneeType, assigneeId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user-tasks-settings'] })
    },
  })
}
