import { useMutation, useQueryClient } from '@tanstack/react-query'
import LinkTypesService from '../../../../../services/LinkTypes.service'
import { useParams } from 'react-router-dom'

type UpdateLinkTypeOptions = {
  id: string
  field: string
  body: { [key: string]: string | number }
}

export const useUpdateLinkType = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()
  return useMutation<any, Error, UpdateLinkTypeOptions>({
    mutationKey: ['updateLinkType'],
    mutationFn: (payload) => LinkTypesService.update(payload.id, payload.field, payload.body, org),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['link-types', org] })
    },
  })
}
