//import { FieldT } from '@sistemiv/s-components/dist/esm/components/table-fields-filter/TableFieldsFilter'
import { /*ProcessTableHeaders*/ ProcessTableRow } from '@sistemiv/s-components/dist/esm/components/table/TableProcess'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { createContext, FC, PropsWithChildren, useState } from 'react'
import { ProcessField } from '../models/ProcessSchema'

export const defaultHeaders = [
  { accessor: 'code', label: 'Code', searchType: 'combobox' },
  { accessor: 'resolution', label: 'Resolution', searchType: 'select' },
  { accessor: 'priority', label: 'Priority', searchType: 'select' },
  { accessor: 'dueDate', label: 'Due date', searchType: 'rangePicker' },
  { accessor: 'assignee', label: 'Assignee', searchType: 'assignee' },
  { accessor: 'parentCode', label: 'Parent Code', searchType: 'combobox' },
  { accessor: 'currentTaskAssignee', label: 'Task Assignee', searchType: 'assignee' },
  { accessor: 'currentTaskDueDate', label: 'Task Due Date', searchType: 'rangePicker' },
  { accessor: 'currentTaskPriority', label: 'Task Priority', searchType: 'select' },
  { accessor: 'activity', label: 'Activity', searchType: 'select' },
  { accessor: 'activityType', label: 'Activity Type', searchType: 'select' },
]

export interface ProcessInstancesData {
  total: number
  data: ProcessTableRow[]
}

interface ProcessInstancesContextValues {
  data: ProcessInstancesData
  setData: DispatchSetAction<ProcessInstancesData>
  fetchingProcessInstances: boolean
  setFetchingProcessInstances: DispatchSetAction<boolean>
  page: number
  setPage: DispatchSetAction<number>
  size: number
  setSize: DispatchSetAction<number>
  headerFilters: any
  setHeaderFilters: DispatchSetAction<any>
  fieldFilters: string[]
  setFieldFilters: DispatchSetAction<string[]>
  //headers: ProcessTableHeaders[]
  //setHeaders: DispatchSetAction<ProcessTableHeaders[]>
  //selectedFields: FieldT[]
  //setSelectedFields: DispatchSetAction<FieldT[]>
  processFields: ProcessField[]
  setProcessFields: DispatchSetAction<ProcessField[]>
}

const defaultValues: ProcessInstancesContextValues = {
  data: { total: 0, data: [] },
  setData: () => undefined,
  fetchingProcessInstances: false,
  setFetchingProcessInstances: () => undefined,
  page: 1,
  setPage: () => undefined,
  size: 20,
  setSize: () => undefined,
  headerFilters: {},
  setHeaderFilters: () => undefined,
  fieldFilters: [],
  setFieldFilters: () => undefined,
  //headers: [],
  //setHeaders: () => undefined,
  //selectedFields: [],
  //setSelectedFields: () => undefined,
  processFields: [],
  setProcessFields: () => undefined,
}

export const ProcessInstancesContext = createContext<ProcessInstancesContextValues>(defaultValues)

const ProcessInstancesContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [data, setData] = useState<ProcessInstancesData>({ total: 0, data: [] })
  const [fetchingProcessInstances, setFetchingProcessInstances] = useState(false)
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [headerFilters, setHeaderFilters] = useState<any>({})
  const [fieldFilters, setFieldFilters] = useState<string[]>([])
  //const [headers, setHeaders] = useState<ProcessTableHeaders[]>(defaultHeaders)
  const [processFields, setProcessFields] = useState<ProcessField[]>([])
  /*const [selectedFields, setSelectedFields] = useState<FieldT[]>(
    defaultHeaders.map((h) => {
      return { accessor: h.accessor, name: h.label }
    }),
  )*/

  return (
    <ProcessInstancesContext.Provider
      value={{
        data,
        setData,
        fetchingProcessInstances,
        setFetchingProcessInstances,
        page,
        setPage,
        size,
        setSize,
        headerFilters,
        setHeaderFilters,
        fieldFilters,
        setFieldFilters,
        //headers,
        //setHeaders,
        //selectedFields,
        //setSelectedFields,
        processFields,
        setProcessFields,
      }}
    >
      {children}
    </ProcessInstancesContext.Provider>
  )
}

export default ProcessInstancesContextProvider
