import { useMsal } from '@azure/msal-react'
import React, { FC, useEffect } from 'react'
import { loginRequest } from '../services/AuthConfig'
import { InteractionRequiredAuthError } from '@azure/msal-browser'

const Logout: FC = () => {
  const { instance } = useMsal()
  useEffect(() => {
    instance
      .ssoSilent({ ...loginRequest, account: instance.getActiveAccount() ?? undefined })
      .then(({ idToken }) => {
        instance.logoutRedirect({
          idTokenHint: idToken,
          account: instance.getActiveAccount(),
        })
      })
      .catch((err) => {
        if (err instanceof InteractionRequiredAuthError) {
          document.cookie = `refreshProcess=true; path=/; max-age=3; domain=.sistemiv.com`
          window.close()
        }
      })
  }, [instance])
  return <div>Logout</div>
}

export default Logout
