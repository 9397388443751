import React, { FC, useCallback, useEffect, useState } from 'react'
import { SelectOption } from '../UserTaskDetails'
import { Input, Option, Select, SortIcon, SortIconFlipped } from '@sistemiv/s-components'
import classNames from '../../../classNames'
import debounce from 'lodash/debounce'

export type Assignee = {
  name: string
  id: string
  photoUrl: string
}

type TaskFilterProps = {
  sortOptions: SelectOption[]
  sortValue: SelectOption
  onSortValueChange?: (value: SelectOption) => void
  searchValue?: string
  onSearch: (value: string) => void
  sortOrder: boolean
  onSortOrderChange?: (order: boolean) => void
}

const TaskFilter: FC<TaskFilterProps> = ({
  searchValue,
  onSearch,
  onSortValueChange,
  onSortOrderChange,
  sortOptions,
  sortValue,
  sortOrder,
}) => {
  const [value, setValue] = useState(searchValue ?? '')
  const handleOnChange = (e) => {
    setValue(e.target.value)
    debouncedHandleSearch(e.target.value)
  }
  // eslint-disable-next-line
  const debouncedHandleSearch = useCallback(debounce(onSearch, 300), [])

  useEffect(() => {
    searchValue && setValue(searchValue)
  }, [searchValue])

  return (
    <div className='flex flex-col justify-start items-center w-full gap-2' data-testid='task-filter-wrap'>
      <Input
        id='search'
        type='search'
        placeholder='Search'
        value={value}
        onChange={(e) => handleOnChange(e)}
        classes2='!border !border-solid !border-gray-300'
      />
      <div className='flex justify-start gap-x-4 items-center w-full'>
        <Select
          value={sortValue}
          onChange={(e) =>
            onSortValueChange && onSortValueChange(sortOptions.find((o) => o.value === e) ?? sortOptions[0])
          }
        >
          {sortOptions.map((option) => (
            <div data-testid='task-filter-option' key={option.value}>
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            </div>
          ))}
        </Select>
        <button onClick={() => onSortOrderChange && onSortOrderChange(!sortOrder)} data-testid='task-filter-sort'>
          {sortOrder ? (
            <SortIconFlipped className={classNames('w-4 h-4 fill-gray-400')} />
          ) : (
            <SortIcon className={classNames('w-4 h-4 fill-gray-400')} />
          )}
        </button>
      </div>
    </div>
  )
}

export default TaskFilter
