import React, { FC } from 'react'
import { useProfile } from '../../repositories/user/user-profile.repository'
import { ImgWrapper } from '@sistemiv/s-components'
import { imageBase } from '../../services/http-common'

const UserImageWrapper: FC<{ userId: string; size: 32 | 76 | 108 }> = ({ userId, size }) => {
  const { data: profile } = useProfile({ userId: userId, enabled: !!userId })

  return (
    <ImgWrapper
      className='rounded-full w-7 h-7'
      src={`${imageBase}/${userId}/${size}`}
      onErrorComponent={
        <p className='w-7 h-7 rounded-full border border-gray-300 text-white font-semibold bg-[#7B87AE] flex items-center justify-center text-lg'>
          {profile?.data?.firstName?.charAt(0)?.toUpperCase()}
        </p>
      }
    />
  )
}

export default UserImageWrapper
