import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import WebhooksService from '../../../../../services/Webhooks.service'

export const useDeleteWebhook = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()

  return useMutation<any, Error, { id: string }>({
    mutationKey: ['delete-webhook'],
    mutationFn: (payload) => WebhooksService.deleteWebhook(org!, payload.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['webhooks'] })
    },
  })
}
