import { Button } from '@sistemiv/s-components'
import React, { FC } from 'react'
import BackArrow from '../../../assets/icons/BackArrow'

type ProcessInstanceInfoTabClosedProps = {
  onClick: () => void
}

const ProcessInstanceInfoTabClosed: FC<ProcessInstanceInfoTabClosedProps> = ({ onClick }) => {
  return (
    <div className='flex px-[0.375rem] overflow-hidden items-start justify-between  py-0 border-l border-gray-300 overflow-y-auto max-h-[calc(100vh_-_8.30rem)] info-tab-scroll-bar'>
      <Button className='flex flex-col !p-0 !pt-2' onClick={onClick}>
        <BackArrow />
      </Button>
    </div>
  )
}

export default ProcessInstanceInfoTabClosed
