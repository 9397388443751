import { Button, DeleteModal, Input, ThrashBinIcon, ZipFileIcon } from '@sistemiv/s-components'
import React, { FC, useState } from 'react'
import { formatBytes } from '../../../../utils/byteUtils'
import { useTranslation } from 'react-i18next'

type ImportFileProps = {
  onCancel?: () => void
  onContinue?: (files: File[]) => void
}

const ImportFile: FC<ImportFileProps> = ({ onContinue, onCancel }) => {
  const [files, setFile] = useState<File[]>([])
  const [deletingFile, setDeletingFile] = useState<File | null>(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [continueLoading, setContinueLoading] = useState(false)
  const { t } = useTranslation()

  const handleDeleteClicked = (file: File) => {
    setDeletingFile(file)
    setDeleteOpen(true)
  }

  const handleFileDelete = () => {
    setFile((v) => {
      const newArray = [...v]
      return newArray.filter((f) => f.name.toLowerCase() !== deletingFile?.name.toLowerCase())
    })
    setDeleteOpen(false)
  }
  return (
    <div className='space-y-6'>
      {files.length === 0 && <Input id='file' type='file' onChange={setFile} />}
      <div className='mt-3 space-y-3'>
        {files.map((file, index) => (
          <div key={`file-${index}`} className='flex text-sm text-slate-600 items-center justify-between'>
            <div className='flex gap-x-3 items-center'>
              <ZipFileIcon className='w-6 h-6' />
              <p>
                {file.name} - {formatBytes(file.size, 0)}
              </p>
            </div>
            <button
              className='px-4'
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleDeleteClicked(file)
              }}
            >
              <ThrashBinIcon className='w-5 h-5 fill-gray-300' />
            </button>
          </div>
        ))}
      </div>
      <div className='flex items-center justify-end gap-x-3'>
        <Button className='hover:bg-gray-100 active:text-blue-500' onClick={onCancel}>
          {t('Common.cancel')}
        </Button>
        <Button
          className='text-white bg-blue-500 disabled:bg-slate-200 disabled:text-slate-400'
          onClick={() => {
            setContinueLoading(true)
            onContinue?.(files)
          }}
          disabled={files.length === 0}
          loading={continueLoading}
        >
          {t('Common.continue')}
        </Button>
      </div>
      <DeleteModal
        open={deleteOpen}
        setOpen={setDeleteOpen}
        title={t('AddProcessModal.deleteFile')}
        description={t('AddProcessModal.deleteFileDescription')}
        onDeleteConfirmed={handleFileDelete}
      />
    </div>
  )
}

export default ImportFile
