import { useParams } from 'react-router-dom'
import TasksService from '../../services/Tasks.service'
import { useInfiniteQuery } from '@tanstack/react-query'

type TaskPaginationOptions = {
  page?: number
  size?: number
  search?: string
  order?: string
  sort?: string
}

const defaultPaginationOptions: TaskPaginationOptions = {
  size: 16,
  search: '',
  order: 'Priority',
  sort: 'asc',
}

export const useTasksPagination = (payload: TaskPaginationOptions = {}) => {
  const { org } = useParams()
  const mergedPayload = { ...defaultPaginationOptions, ...payload }
  return useInfiniteQuery<any>({
    queryKey: [
      'tasks-paginated',
      org,
      mergedPayload.size,
      mergedPayload.order,
      mergedPayload.page,
      mergedPayload.search,
      mergedPayload.sort,
    ],
    queryFn: ({ pageParam = 0 }) => TasksService.list(org!, { ...mergedPayload, page: pageParam }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      if (lastPage.results.length === 0) {
        return undefined
      }
      return lastPage.page + 1
    },
    staleTime: 0,
    enabled: !!org,
  })
}
