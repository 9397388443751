import {
  Button,
  DeleteModal,
  MoveToIcon,
  SideMenuParentArrowIcon,
  SortableTree,
  SortableTreeItem,
  SpinnerIcon,
  ThrashBinIcon,
} from '@sistemiv/s-components'
import React, { FC, useEffect, useMemo, useState } from 'react'
import SelectableHeader from '../SelectableHeader'
import { EyeIcon, EyeSlashIcon, FolderIcon, PlusIcon } from '@heroicons/react/24/outline'
import { EyeIcon as EyeIconSolid, PencilIcon } from '@heroicons/react/24/solid'
import { TreeItem } from '@sistemiv/s-components/dist/esm/types/types'
import { setProperty } from '@sistemiv/s-components/dist/esm/treeUtils'
import { useParams, useSearchParams } from 'react-router-dom'
import { Variable } from '../../../../models/Variables'
import { useVariables } from '../../../../repositories'
import { useAddVariables } from '../../../../repositories/settings/processes/variables/mutations/add-variables.repository'
import { useDeleteVariable } from '../../../../repositories/settings/processes/variables/mutations/delete-variables.repository'
import { useChangeVariableTreeItemPosition } from '../../../../repositories/settings/processes/variables/mutations/change-variable-tree-item-pos.respository'
import { useAddVariableSection } from '../../../../repositories/settings/processes/variables/mutations/add-section.mutation'
import { useChangeVariableVisibility } from '../../../../repositories/settings/processes/variables/mutations/change-variable-visibility-repository'
import { useDeleteVariableSection } from '../../../../repositories/settings/processes/variables/mutations/delete-section.repository'
import { useChangeVariableSectionName } from '../../../../repositories/settings/processes/variables/mutations/change-section-name.repository'
import MoveToModal from './variables/MoveToModal'
import NewSectionModal from './variables/section-modals/NewSectionModal'
import RenameSectionModal from './variables/section-modals/RenameSectionModal'
import { useTranslation } from 'react-i18next'
import { useProcessFields } from '../../../../repositories/settings/processes/processes/process-fields.repository'
import { useMsal } from '@azure/msal-react'

const VariablesTab: FC = () => {
  const { org } = useParams()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  // const tabs = [t('Settings.tabs.configuration')]
  // const [activeTab, setActiveTab] = useState(tabs[0])
  const [createSectionOpen, setCreateSectionOpen] = useState<boolean>(false)
  const [renameSectionOpen, setRenameSectionOpen] = useState<boolean>(false)
  const [editSectionOpen, setEditSectionOpen] = useState<boolean>(false)
  const [editingSection, setEditingSection] = useState<any>(undefined)
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false)
  const [isVariablesAdding, setIsVariablesAdding] = useState(false)
  const [moveToOpen, setMoveToOpen] = useState(false)
  const { instance } = useMsal()
  const userId = useMemo(() => instance.getActiveAccount()?.localAccountId, [instance])

  const {
    data: variables = [],
    isLoading: loading,
    isFetching: fetching,
  } = useVariables({
    organization: org,
    key: searchParams.get('key') ?? undefined,
    variableOption: 'variables',
  })
  const { data: knownVariables = [] } = useVariables({
    organization: org,
    key: searchParams.get('key') ?? undefined,
    variableOption: 'known-variables',
  })

  const { data: fields = [], refetch } = useProcessFields({ organization: org, key: searchParams.get('key')! })

  const { mutate: addVariables, isPending: addingVariables } = useAddVariables()
  const { mutate: addVariableSection, isPending: addingSection } = useAddVariableSection()
  const { mutate: deleteVariable, isPending: deletingVariable } = useDeleteVariable()
  const { mutate: changeVariableTreeItemPos, isPending: changingPosition } = useChangeVariableTreeItemPosition()
  const { mutate: changeVariableVisibility, isPending: changingVisibility } = useChangeVariableVisibility()
  const { mutate: deleteVariableSection, isPending: deletingSection } = useDeleteVariableSection()
  const { mutate: changeVariableSectionName, isPending: changingSectionName } = useChangeVariableSectionName()

  const pending =
    addingVariables ||
    addingSection ||
    deletingVariable ||
    changingPosition ||
    changingVisibility ||
    deletingSection ||
    changingSectionName

  const [treeItems, setTreeItems] = useState<TreeItem[]>(
    variables.map((v) => ({
      id: v.id,
      name: v.name,
      position: v.position,
      type: v.type,
      visible: v.isVisible,
      collapsed: true,
      children: v.children?.map((child) => ({ id: child.id, children: [], leaf: true })) ?? [],
      leaf: v.itemType === 'ProcessVariableField',
    })),
  )

  const handleVariableRemoving = async (id) => {
    deleteVariable({ variableId: id })
  }
  const handleSectionRemoving = (sectionId) => {
    deleteVariableSection(
      { sectionId: sectionId },
      {
        onSuccess: () => {
          setDeleteOpen(false)
        },
      },
    )
  }

  const handleVisibilitySwitch = (id, visibility) => {
    changeVariableVisibility({ variableId: id, body: { isVisible: visibility } })
  }

  const handleSectionRenaming = (sectionId: string, value: string) => {
    changeVariableSectionName({ sectionId: sectionId, body: { name: value } })
  }

  const addVariablesToGrid = async (variables: any[]) => {
    console.log(fields)
    console.log(variables)
    const { data: freshData } = await refetch()
    const commonElements = freshData?.filter((item1) => variables.some((item2) => item1.id === item2.id))
    const storedData = localStorage.getItem(searchParams.get('key')! + org + userId)
    if (storedData && commonElements && commonElements.length > 0) {
      const parsedData = JSON.parse(storedData)
      console.log(parsedData)
      localStorage.setItem(
        searchParams.get('key')! + org + userId,
        JSON.stringify({ ...parsedData, fields: [...parsedData.fields, ...commonElements] }),
      )
    }

    console.log(commonElements)
  }

  useEffect(() => {
    if (variables.length) {
      setTreeItems((oldVariables) =>
        variables.map((v) => ({
          id: v.id,
          name: v.name,
          position: v.position,
          type: v.type,
          visible: v.isVisible,
          collapsed: oldVariables.find(({ id }) => v.id === id)?.collapsed,
          children: v.children?.map((child) => ({ id: child.id, children: [], leaf: true })) ?? [],
          leaf: v.itemType === 'ProcessVariableField',
        })),
      )
      setEditingSection((old) => {
        if (old) {
          return find(variables, old.id)
        }

        return old
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables])

  const handleCollapse = (id: string) => {
    setTreeItems((items) =>
      setProperty(items, id, 'collapsed', (value) => {
        return !value
      }),
    )
  }

  const find = (array: any, id: string): any | undefined => {
    let result = undefined
    array.some((o) => (result = o.id === id ? o : find(o.children || [], id)))
    return result
  }
  const findParent = (array: any, id: string, currentParent = null) => {
    for (const item of array) {
      if (item.id === id) {
        return currentParent
      }
      if ('children' in item) {
        const parent = findParent(item.children, id, item)
        if (parent) {
          return parent
        }
      }
    }
  }
  const handleReorder = async (activeId: string, overId: string, parentId: string | null) => {
    const key = searchParams.get('key')
    if (!org || !key) return
    const activeProcess = find(variables, activeId)
    const activeParent = findParent(variables, activeId)
    const overProcess = find(variables, overId)
    const overParent = findParent(variables, overId)
    if (activeParent) {
      if (activeId === overId && activeParent.id === parentId) {
        return
      }
    } else {
      if (activeId === overId && !parentId) {
        return
      }
    }
    if (overProcess) {
      let newPosition = overProcess.position
      console.log(overProcess)
      console.log(activeProcess)
      console.log(activeParent)
      console.log(overParent)
      console.log(parentId)
      if (!overParent) {
        if (parentId) {
          if (overId === parentId) {
            newPosition = 0
          } else {
            const parentProcess = find(variables, parentId)
            newPosition = parentProcess.children ? parentProcess.children.length : 0
          }
        } else {
          if (activeParent) {
            if (activeParent.id === overProcess.id) {
              newPosition = overProcess.position
            } else {
              if (activeParent.position < overProcess.position) {
                newPosition = overProcess.position + 1
              }
            }
          }
        }
      } else {
        if (activeId === overId) {
          newPosition = activeParent.position + 1
        } else if (parentId) {
          const currPosition = activeParent ? activeParent.position : activeProcess.position
          if (currPosition < overParent.position) {
            newPosition = overProcess.position + 1
          }
        } else if (!parentId) {
          if (activeParent) {
            newPosition = overParent.position + 1
          } else {
            newPosition = overParent.position
          }
        }
      }

      changeVariableTreeItemPos({
        sectionId: activeId,
        body: {
          position: newPosition,
          parentId: parentId,
        },
      })
    }
  }

  return (
    <div className='overflow-hidden h-full flex flex-col'>
      <SelectableHeader
        className='border-b border-gray-300 flex-none'
        title={t('Settings.variables.variables')}
        hideVersions={true}
      >
        <div className='ml-16 flex h-full items-end gap-x-3'>
          <Button
            className='text-blue-500 flex items-center gap-x-3 border border-blue-500'
            onClick={() => {
              setIsVariablesAdding(false)
              setCreateSectionOpen(true)
            }}
          >
            <PlusIcon className='w-4 h-4' />
            {t('Settings.variables.section')}
          </Button>

          <Button
            className='text-blue-500 flex items-center gap-x-3 border border-blue-500'
            // onClick={() => setCreateModalOpen(true)}
            onClick={() => {
              setIsVariablesAdding(true)
              setCreateSectionOpen(true)
            }}
          >
            <PlusIcon className='w-4 h-4' />
            {t('Settings.variables.variable')}
          </Button>
        </div>
      </SelectableHeader>
      <div className='p-8 overflow-auto grow'>
        {/* <Tabs active={activeTab} tabs={tabs} onClick={setActiveTab}>
          <TabPanel tab={tabs[0]} active={activeTab}> */}
        <div className='w-full max-w-3xl relative'>
          {loading && (
            <div className='flex justify-center top-1 inset-x-auto absolute  w-full'>
              <SpinnerIcon className='w-8 h-8 text-sky-500' />
            </div>
          )}
          <div className={`${pending || fetching ? 'opacity-50 animate-pulse' : 'opacity-100'}`}>
            <SortableTree
              items={treeItems}
              setItems={setTreeItems}
              onReorder={handleReorder}
              renderElement={(id, depth, treeChildren, collapsed, clone) => {
                const variable = find(variables, id)
                if (!variable) return null
                const item = variable as Variable
                return (
                  <SortableTreeItem
                    key={id}
                    id={id}
                    depth={depth}
                    childCount={treeChildren.length}
                    onCollapse={() => handleCollapse(id)}
                    collapsed={collapsed}
                    clone={clone}
                  >
                    {item.itemType === 'ProcessVariableFieldSection' ? (
                      <div className='grid grid-cols-2 gap-x-20'>
                        <div className='flex items-center gap-x-3 '>
                          {treeChildren.length && treeChildren.length > 0 ? (
                            <button onClick={() => handleCollapse?.(item.id)}>
                              <SideMenuParentArrowIcon
                                className={`w-3 h-3 fill-black ${collapsed ? 'rotate-0' : 'rotate-90'}`}
                              />
                            </button>
                          ) : null}
                          <FolderIcon className='text-slate-400 w-7 h-7' />
                          <span className='whitespace-nowrap text-ellipsis overflow-hidden'>{item.name}</span>
                        </div>
                        <div className='flex items-center justify-end align-end gap-x-5 opacity-0 group-hover:opacity-100'>
                          <Button
                            className='!p-0 text-blue-500'
                            onClick={() => {
                              setEditingSection(item)
                              setEditSectionOpen(true)
                            }}
                          >
                            Add variables
                          </Button>
                          <Button
                            className='!p-0'
                            tooltip={t('Settings.variables.editSection') as string}
                            onClick={() => {
                              setEditingSection(item)
                              setRenameSectionOpen(true)
                            }}
                          >
                            <PencilIcon className='w-4 h-4 text-slate-500 hover:text-gray-800' />
                          </Button>

                          <Button
                            className='!p-0'
                            tooltip={t('Settings.variables.removeSection') as string}
                            onClick={() => {
                              setEditingSection(item)
                              setDeleteOpen(true)
                            }}
                          >
                            <ThrashBinIcon className='w-4 h-4 fill-slate-500 hover:fill-gray-800' />
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className='grid grid-cols-[1fr_2fr] gap-x-10'>
                        <div className={`${depth > 0 ? 'pl-7' : ''} truncate`}>{item.name}</div>
                        <div className='grid grid-cols-3 gap-x-10'>
                          <div className='text-slate-300'>{item?.type ?? 'N/A'}</div>
                          <div className='text-slate-300'>
                            {!item.type || !['Json', 'File', 'Table'].includes(item.type) ? (
                              !item?.isVisible ? (
                                <Button
                                  className='!p-0'
                                  tooltip={t('Settings.variables.notShown') as string}
                                  onClick={() => handleVisibilitySwitch(item.id, true)}
                                >
                                  <EyeIcon className='w-5 h-5 text-slate-500' />
                                </Button>
                              ) : (
                                <Button
                                  className='!p-0'
                                  tooltip={t('Settings.variables.shown') as string}
                                  onClick={() => handleVisibilitySwitch(item.id, false)}
                                >
                                  <EyeIconSolid className='w-5 h-5 text-slate-500' />
                                </Button>
                              )
                            ) : (
                              <Button
                                className='!p-0 pointer-events-none'
                                tooltip={t('Settings.variables.cantShow') as string}
                              >
                                <EyeSlashIcon className='w-5 h-5 text-slate-500' />
                              </Button>
                            )}
                          </div>
                          <div className='flex items-center justify-end align-end gap-x-5 opacity-0 group-hover:opacity-100'>
                            <Button
                              className='!p-0 group'
                              tooltip={t('Settings.variables.moveToSection') as string}
                              onClick={() => {
                                setEditingSection(find(variables, item.id))
                                setMoveToOpen(true)
                              }}
                            >
                              <MoveToIcon className='w-4 h-4 text-slate-500 hover:text-gray-800 group-disabled:text-slate-500 group-disabled:opacity-50' />
                            </Button>
                            <Button
                              className='!p-0 group'
                              tooltip={t('Settings.variables.runtimeRemove') as string}
                              onClick={() => handleVariableRemoving?.(item.id)}
                            >
                              <ThrashBinIcon className='w-4 h-4 fill-slate-500 hover:fill-gray-800 group-disabled:fill-slate-500 group-disabled:opacity-50' />
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </SortableTreeItem>
                )
              }}
            />
          </div>
        </div>
        {/* </TabPanel>
        </Tabs> */}
      </div>

      {createSectionOpen && (
        <NewSectionModal
          open={createSectionOpen}
          setOpen={setCreateSectionOpen}
          variables={
            isVariablesAdding
              ? knownVariables.filter((kv) => {
                  if (find(variables, kv.id)) {
                    return false
                  }

                  return true
                })
              : knownVariables.map((kv) => {
                  const v = find(variables, kv.id)
                  return { ...kv, parentId: v?.parentId }
                })
          }
          newVariables={isVariablesAdding}
          creating={addingVariables || addingSection}
          onCancel={() => setCreateSectionOpen(false)}
          onCreate={(sectionName, chosenVariables) => {
            if (isVariablesAdding) {
              addVariables(
                { sectionId: sectionName === '' ? null : sectionName, variables: chosenVariables },
                {
                  onSuccess: () => {
                    setCreateSectionOpen(false)
                    addVariablesToGrid(chosenVariables)
                  },
                },
              )
            } else {
              addVariableSection(
                {
                  body: { name: sectionName, variables: chosenVariables },
                },
                {
                  onSuccess: () => {
                    setCreateSectionOpen(false)
                  },
                },
              )
            }
          }}
        />
      )}

      {editSectionOpen && editingSection && (
        <NewSectionModal
          open={editSectionOpen}
          name={editingSection.name}
          setOpen={setEditSectionOpen}
          creating={changingPosition}
          variables={knownVariables
            .map((kv) => {
              const v = find(variables, kv.id)
              return { ...kv, parentId: v?.parentId }
            })
            .filter((v) => v.parentId !== editingSection.id)}
          onCancel={() => setEditSectionOpen(false)}
          onCreate={(_, chosenVariables) => {
            addVariables(
              { sectionId: editingSection.id, variables: chosenVariables },
              {
                onSuccess: () => {
                  setEditSectionOpen(false)
                },
              },
            )
          }}
        />
      )}

      {renameSectionOpen && editingSection && (
        <RenameSectionModal
          open={renameSectionOpen}
          setOpen={setRenameSectionOpen}
          value={editingSection.name}
          onSave={(value) => {
            handleSectionRenaming(editingSection.id, value)
          }}
        />
      )}

      {deleteOpen && (
        <DeleteModal
          open={deleteOpen}
          setOpen={setDeleteOpen}
          title={t('Settings.variables.deleteSection')}
          description={t('Settings.variables.deleteSectionMsg')}
          onDeleteConfirmed={() => handleSectionRemoving(editingSection.id)}
        />
      )}

      {moveToOpen && (
        <MoveToModal
          open={moveToOpen}
          setOpen={setMoveToOpen}
          movingVariable={editingSection}
          sections={variables.filter((v) => v.itemType === 'ProcessVariableFieldSection')}
          onSelect={(sectionId) => {
            const section = sectionId && find(variables, sectionId)
            handleReorder(
              editingSection.id,
              section
                ? section.children?.length
                  ? section.children?.[section.children?.length - 1].id
                  : section.id
                : variables?.[variables.length - 1].id,
              sectionId ?? null,
            )
            setMoveToOpen(false)
          }}
        />
      )}
    </div>
  )
}

export default VariablesTab
