import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import WebhooksService from '../../../../services/Webhooks.service'

type WebhooksOptions = {
  id: string
  page: number
  size: number
}

export const useWebhooksAccess = ({ page, size, id }: WebhooksOptions) => {
  const { org } = useParams()
  return useQuery<any>({
    queryKey: ['webhooks-access', org, id, { page, size }],
    queryFn: () => WebhooksService.listAccess(org!, id, page, size),
    placeholderData: keepPreviousData,
    enabled: !!org,
  })
}
