import { useQuery } from '@tanstack/react-query'
import UserService from '../../services/UserService'
import { Application } from '../../models/User'
import { useParams } from 'react-router-dom'

export const useGetApplications = () => {
  const { org } = useParams()
  return useQuery<Application[]>({
    queryKey: ['applications', org],
    queryFn: () => UserService.listApplications(org!),
    enabled: !!org,
    staleTime: Infinity,
  })
}
