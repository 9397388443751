import React, { FC, useState } from 'react'
import GlobalSettingsHeader from '../GlobalSettingsHeader'
import { Input } from '@sistemiv/s-components'
import classNames from '../../../../classNames'

const PermissionsPage: FC = () => {
  const headers = ['', 'Assignee', 'Creator', 'Watcher']
  const [data, setData] = useState<any[]>([
    {
      action: 'Process instance assignee changed',
      Assignee: true,
      Creator: true,
      Watcher: true,
    },
    {
      action: 'Process instance created',
      Assignee: true,
      Creator: true,
      Watcher: true,
    },
    {
      action: 'Process instance status changed',
      Assignee: true,
      Creator: true,
      Watcher: true,
    },
    {
      action: 'Process instance commented',
      Assignee: true,
      Creator: true,
      Watcher: true,
    },
  ])

  return (
    <div className='h-full flex flex-col overflow-hidden'>
      <GlobalSettingsHeader title='Notifications' className='border-b border-gray-300 flex-none' />
      <div className='px-8 py-6 text-sm grow overflow-auto w-full max-w-3xl'>
        <table className='w-full'>
          <thead>
            <tr className='border-b border-gray-300'>
              {headers.map((header, index) => (
                <th
                  key={`header-${index}`}
                  className={classNames(
                    'text-slate-500 text-left font-normal px-3 py-2 select-none',
                    header !== '' ? 'max-w-[6.25rem]' : '',
                  )}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          {data.map((entry, sectionIndex) => (
            <tbody key={`entry-${sectionIndex}`} className=''>
              <tr>
                <td className='px-3 py-3 text-slate-500 text-left font-normal select-none'>{entry.action}</td>
                {headers
                  .filter((el) => el != '')
                  .map((header, index) => (
                    <td
                      key={`${entry.section}-row-${sectionIndex}-cell-${index}`}
                      className={classNames('px-3 py-3', header === '' ? '' : 'max-w-[6.25rem]')}
                    >
                      {header !== '' && entry[header] !== undefined && (
                        <Input
                          mainClasses='!m-0'
                          id={'check'}
                          type={'checkbox'}
                          checked={data[sectionIndex][header]}
                          onChange={(e) => {
                            setData((old) => {
                              const newArray = [...old]
                              newArray[sectionIndex][header] = e.target.checked
                              return newArray
                            })
                          }}
                        />
                      )}
                    </td>
                  ))}
              </tr>
            </tbody>
          ))}
        </table>
      </div>
    </div>
  )
}

export default PermissionsPage
