import { DataField, Input, Option, Select, SpinnerIcon, TabPanel, Tabs } from '@sistemiv/s-components'
import React, { FC, Fragment, useEffect, useState } from 'react'
import classNames from '../../../../classNames'
import { Combobox, Transition } from '@headlessui/react'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useParams, useSearchParams } from 'react-router-dom'
import { UserTask } from '../../../../models/ProcessDefinitionSettings'
import UserService from '../../../../services/UserService'
import { useUserTaskSettings } from '../../../../repositories/settings/processes/user-tasks/user-tasks-get.repository'
import { useChangeAssigneeCandidate } from '../../../../repositories/settings/processes/user-tasks/mutations/change-assignee-candidate.mutation'
import { useChangeAllowBulk } from '../../../../repositories/settings/processes/user-tasks/mutations/change-allow-bulk.mutation'
import { useQueryClient } from '@tanstack/react-query'
import { TableField } from '@sistemiv/s-components/dist/esm/components/table/Table'
import { imageBase } from '../../../../services/http-common'
import { useTranslation } from 'react-i18next'

const UserTasksTab: FC = () => {
  const { t } = useTranslation()
  const { org } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [orgs, setOrgs] = useState([])
  const [groups, setGroups] = useState([])
  const [users, setUsers] = useState([])
  const queryClient = useQueryClient()

  const {
    data: tasks,
    isLoading,
    isFetching,
  } = useUserTaskSettings({ organization: org, versionId: searchParams.get('version') ?? undefined })

  const { mutate: updateCandidate, isPending: isChangingCandidate } = useChangeAssigneeCandidate()
  const { mutate: updateAllowBulk, isPending: isChangingBulk } = useChangeAllowBulk()

  const loading = isLoading || isFetching || isChangingCandidate || isChangingBulk

  const defaultAssigneeValues = {
    InstanceStarter: t('Settings.userTasks.defaultAssigneeValues.InstanceStarter'),
    InstanceStarterNode: t('Settings.userTasks.defaultAssigneeValues.InstanceStarterNode'),
    SelectedIdentificationEntity: t('Settings.userTasks.defaultAssigneeValues.SelectedIdentificationEntity'),
  }

  useEffect(() => {
    if (!org) return
    UserService.listAllUsersInOrg(org).then((res) => {
      setUsers(
        res.users.map((item) => ({
          type: 'circled_value',
          id: item.objectId,
          value: item.name,
          icon: `${imageBase}/${item.objectId}/76`,
          tab: 'Users',
        })),
      )
    })
    UserService.listAllGroups(org).then((res) => {
      setGroups(
        res.results.map((item) => ({
          type: 'b64_value',
          id: item.id,
          value: item.name,
          icon: item.icon,
          tab: 'Groups',
        })),
      )
    })
    UserService.listAllOrgNodes(org).then((res) => {
      setOrgs(res.results.map((item) => ({ type: 'value', id: item.id, value: item.name, tab: 'Org chart' })))
    })
  }, [org, searchParams, setSearchParams])

  const body = (
    <div className='relative'>
      <div className='relative w-full max-w-3xl'>
        {loading && (
          <div className='flex justify-center top-1 inset-x-auto absolute w-full'>
            <SpinnerIcon className='w-8 h-8 text-sky-500' />
          </div>
        )}
        <table className={`${loading ? 'opacity-50' : 'opacity-100'} table-auto w-full text-sm`}>
          <thead>
            <tr className='border-b border-gray-300'>
              <th className='text-left text-slate-500 font-normal px-2 py-1'>{t('Settings.userTasks.task')}</th>
              <th className='text-left text-slate-500 font-normal px-2 py-1'>
                {t('Settings.userTasks.allowBulkComplete')}
              </th>
              <th className='text-left text-slate-500 font-normal px-2 py-1'>
                {t('Settings.userTasks.defaultAssignees')}
              </th>
            </tr>
          </thead>
          <tbody>
            {tasks &&
              tasks?.userTasks?.map((row: UserTask) => {
                return (
                  <tr key={row.id} className='border-b border-gray-300'>
                    <td className='px-2 py-1'>{row.taskName}</td>
                    <td className='px-2 py-1'>
                      <Input
                        type='checkbox'
                        tooltip={row.hasForm ? (t('Settings.userTasks.taskHasForm') as string) : ''}
                        id='check'
                        mainClasses='w-fit'
                        classes='!mr-0'
                        checked={row.allowBulkComplete}
                        disabled={row.hasForm}
                        onChange={(e) => {
                          const versionId = searchParams.get('version')
                          if (!org || !versionId) return
                          updateAllowBulk({ organization: org, versionId, taskId: row.id, value: e.target.checked })
                        }}
                      />
                    </td>
                    <td className='px-2 py-1'>
                      <div
                        className={classNames(
                          row.assigneeCandidateType === 'SelectedIdentificationEntity'
                            ? 'grid grid-cols-2 gap-x-1.5 w-full'
                            : 'flex items-center gap-x-3 w-full',
                        )}
                      >
                        <Select
                          value={
                            defaultAssigneeValues[
                              ['User', 'Group', 'OrganizationNode'].includes(row.assigneeCandidateType)
                                ? 'SelectedIdentificationEntity'
                                : row.assigneeCandidateType
                            ]
                          }
                          onChange={(value) => {
                            if (value !== 'SelectedIdentificationEntity') {
                              const versionId = searchParams.get('version')
                              if (!org || !versionId) return
                              updateCandidate({ organization: org, versionId, taskId: row.id, assigneeType: value })
                            } else {
                              queryClient.setQueryData(
                                ['user-tasks-settings', org, searchParams.get('version')],
                                (old: any) => {
                                  console.log(old)
                                  return {
                                    ...old,
                                    userTasks: old.userTasks?.map((v) => {
                                      if (v.id === row.id) {
                                        return {
                                          ...v,
                                          assigneeCandidateType: value,
                                        }
                                      }
                                      return v
                                    }),
                                  }
                                },
                              )
                            }
                          }}
                          wrapperClasses='w-full'
                        >
                          {!tasks.startableByWebhook && (
                            <Option value={'InstanceStarter'}>
                              <p className='whitespace-nowrap select-none'>
                                {defaultAssigneeValues['InstanceStarter']}
                              </p>
                            </Option>
                          )}
                          <Option value={'instanceStarterNode'}>
                            <p className='whitespace-nowrap select-none'>
                              {defaultAssigneeValues['InstanceStarterNode']}
                            </p>
                          </Option>
                          <Option value={'SelectedIdentificationEntity'}>
                            <p className='whitespace-nowrap select-none'>
                              {defaultAssigneeValues['SelectedIdentificationEntity']}
                            </p>
                          </Option>
                        </Select>
                        {['User', 'Group', 'OrganizationNode', 'SelectedIdentificationEntity'].includes(
                          row.assigneeCandidateType,
                        ) && (
                          <AssigneeCombobox
                            options={[...users, ...groups, ...orgs]}
                            selected={[...users, ...groups, ...orgs].find((o: any) => o.id === row.assigneeCandidateId)}
                            onChange={(value) => {
                              if (value !== 'SelectedIdentificationEntity') {
                                const versionId = searchParams.get('version')
                                if (!org || !versionId) return
                                updateCandidate({
                                  organization: org,
                                  versionId,
                                  taskId: row.id,
                                  assigneeType:
                                    value.tab === 'Users'
                                      ? 'User'
                                      : value.tab === 'Groups'
                                      ? 'Group'
                                      : 'OrganizationNode',
                                  assigneeId: value.id,
                                })
                              }
                            }}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </div>
  )

  return (
    <div className='overflow-hidden h-full flex flex-col'>
      <div className='p-8 overflow-auto grow'>{body}</div>
    </div>
  )
}

const AssigneeCombobox: FC<{
  options: TableField[]
  selected?: TableField
  onChange?: (assignee: any) => void
}> = ({ options, selected, onChange }) => {
  const tabs = ['Users', 'Groups', 'Org chart']
  const [selectedTab, setSelectedTab] = useState(selected?.tab ?? 'Users')
  const [query, setQuery] = useState('')
  const { t } = useTranslation()

  const filteredOptions =
    query === ''
      ? options
      : options.filter((o) =>
          o.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')),
        )

  return (
    <Combobox
      onChange={(value) => {
        if (onChange) {
          onChange(value)
        }
      }}
    >
      <div className='relative w-full'>
        <div className='relative rounded-md border border-gray-300 w-full h-full'>
          <div className={`flex items-center gap-x-3 w-full py-1.5 pr-9 pl-3 whitespace-nowrap overflow-hidden h-full`}>
            {selected ? (
              <DataField field={selected} className='[&>div>svg]:w-6 [&>div>svg]:h-6' />
            ) : (
              <p className='text-gray-400'>{t('Settings.userTasks.defaultAssignee')}</p>
            )}
          </div>
          <Combobox.Button className='absolute inset-y-0 flex items-center justify-center right-0 pr-2'>
            <ChevronDownIcon className='w-5 h-5 text-gray-700' />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options className='absolute mt-1 py-1 bg-white max-h-60 min-w-32 w-max overflow-auto z-10  rounded-md shadow-xl ring-1 ring-black ring-opacity-5'>
            <div className='w-full relative border-b border-gray-300'>
              <Combobox.Input
                displayValue={(value) => (value ? '' : '')}
                placeholder='Search'
                onChange={(e) => setQuery(e.target.value)}
                className='relative pl-8 pr-3 bg-white border-none focus:border-none focus:ring-0 leading-5'
              />
              <span className='absolute flex items-center justify-center inset-y-0 left-0 pl-2'>
                <MagnifyingGlassIcon className='w-4 h-4 text-gray-400' />
              </span>
            </div>
            <Tabs active={selectedTab} tabs={tabs} onClick={setSelectedTab} className='px-3 [&+div]:!pt-2'>
              <TabPanel active={selectedTab} tab='Users'>
                {filteredOptions
                  .filter((o) => o.tab === 'Users')
                  .map((o) => (
                    <Combobox.Option key={o.id} value={o} className='hover:bg-slate-100 cursor-pointer select-none'>
                      <div
                        className={classNames(
                          'flex items-center gap-x-3 py-2 px-3',
                          selected?.id === o.id ? 'opacity-100' : 'opacity-70',
                        )}
                      >
                        <DataField field={o} />
                      </div>
                    </Combobox.Option>
                  ))}
              </TabPanel>
              <TabPanel active={selectedTab} tab='Groups'>
                {filteredOptions
                  .filter((o) => o.tab === 'Groups')
                  .map((o) => (
                    <Combobox.Option key={o.id} value={o} className='hover:bg-slate-100 cursor-pointer select-none'>
                      <div
                        className={classNames(
                          'flex items-center gap-x-3 py-2 px-3',
                          selected?.id === o.id ? 'opacity-100' : 'opacity-70',
                        )}
                      >
                        <DataField field={o} className='[&>div>svg]:w-5 [&>div>svg]:h-5' />
                      </div>
                    </Combobox.Option>
                  ))}
              </TabPanel>
              <TabPanel active={selectedTab} tab='Org chart'>
                {filteredOptions
                  .filter((o) => o.tab === 'Org chart')
                  .map((o) => (
                    <Combobox.Option key={o.id} value={o} className='hover:bg-slate-100 cursor-pointer select-none'>
                      <div
                        className={classNames(
                          'flex items-center gap-x-3 py-2 px-3',
                          selected?.id === o.id ? 'opacity-100' : 'opacity-70',
                        )}
                      >
                        <DataField field={o} />
                      </div>
                    </Combobox.Option>
                  ))}
              </TabPanel>
            </Tabs>
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
}

export default UserTasksTab
