import { Button, SpinnerIcon } from '@sistemiv/s-components'
import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { UserTasksContext } from '../../context/UserTasksContextProvider'
import { useTaskComplete, useTaskStartFrom } from '../../repositories'
import { useTaskEscalate } from '../../repositories/tasks/mutations/task-escalate.mutation'
import { useNavigate, useParams } from 'react-router'
import FormViewer from '../../extensions/form/FormViewer'
import { useTranslation } from 'react-i18next'
import { useProcessInstanceVariables } from '../../repositories/process-instances/process-instance-variables.repository'
import { useSearchParams } from 'react-router-dom'
import { useTaskDeescalate } from '../../repositories/tasks/mutations/task-deescalate.mutation'

const TaskForm: FC<{ selectedTask: any; setTaskList: any }> = ({ selectedTask, setTaskList }) => {
  const { org } = useParams()
  const [searchParams] = useSearchParams()
  const { selectedId, setSelectedId } = useContext(UserTasksContext)
  const formRef = useRef<any>(null)
  const navigate = useNavigate()
  const { t } = useTranslation()
  // const { data: task, isLoading: isTaskLoading } = useTaskDetails(selectedId)
  const { data: variablesData, isLoading: isVariablesLoading } = useProcessInstanceVariables(
    selectedTask?.processInstanceId ?? '',
    selectedId,
  )
  const { mutateAsync: taskCompleteMutation, isPending: isTaskCompleting } = useTaskComplete()
  const { mutate: taskEscalate, isPending: isTaskEscalating } = useTaskEscalate()
  const { mutate: taskDeescalate, isPending: isTaskDeescalating } = useTaskDeescalate()
  const {
    data: formFields,
    isLoading: isFormLoading,
    isFetching: isFormFetching,
  } = useTaskStartFrom({ id: selectedId })
  const [savedTaskData, setSavedTaskData] = useState<any>(null)

  const displayTaskData = () => {
    const taskData = getTasksDataFromLocalStorage()
    if (taskData) {
      setSavedTaskData(taskData.find((item) => item.taskId === selectedId))
    }
  }
  useEffect(() => {
    if (selectedId && searchParams.get('id')) {
      displayTaskData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId])

  // useEffect(() => {
  //   if (selectedId)
  //     navigate({
  //       pathname: `/${org}/tasks`,
  //       search: `?id=${selectedId}`,
  //     })
  // }, [selectedId, navigate, org])

  const handleComplete = async (data: any) => {
    if (isTaskCompleting) return
    const form = {}
    if (formFields) {
      const formKeys = formFields.components.filter((f) => f.type.includes('file')).map((f) => f.key)
      Object.keys(data).forEach((oldKey) => {
        if (formKeys.includes(oldKey)) {
          try {
            const oldValue = JSON.parse(data[oldKey])
            form[oldKey] = {
              value: oldValue?.[0]?.id,
              valueInfo: {
                fileName: oldValue?.[0]?.fileName,
                mimeType: oldValue?.[0]?.mimeType,
                encoding: oldValue?.[0]?.encoding,
              },
            }
          } catch {
            console.log('skip')
          }
        } else {
          form[oldKey] = { value: data[oldKey] }
        }
      })
    }
    await taskCompleteMutation(
      { id: selectedId, body: form },
      {
        onSuccess: () => {
          setTaskList((old) => old.filter((task) => task.id !== selectedId))
          setSelectedId('')
          navigate(`/${org}/tasks`)
        },
      },
    )
  }
  const getTasksDataFromLocalStorage = () => {
    if (localStorage.tasksData) {
      return JSON.parse(localStorage.tasksData)
    }

    return undefined
  }

  const saveTaskDataToLocalStorage = (newTaskData: any) => {
    let tasksData = getTasksDataFromLocalStorage()
    newTaskData = { data: newTaskData, taskId: selectedId }
    if (tasksData) {
      if (tasksData.length > 0) {
        tasksData = tasksData.filter((item) => item.taskId !== selectedId)
        localStorage.tasksData = JSON.stringify([...tasksData, newTaskData])
      } else {
        localStorage.tasksData = JSON.stringify([newTaskData])
      }
    } else {
      localStorage.tasksData = JSON.stringify([newTaskData])
    }

    displayTaskData()
  }

  const deleteTaskDataFromLocalStorge = () => {
    let taskData = getTasksDataFromLocalStorage()

    if (taskData) {
      taskData = taskData.filter((item) => item.taskId !== selectedId)
      localStorage.tasksData = JSON.stringify([...taskData])
    }
  }

  if (isFormLoading || isVariablesLoading)
    return (
      <div className='w-full flex justify-center pt-5'>
        <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
      </div>
    )

  const handleDeescalate = (data: any) => {
    taskDeescalate({ id: selectedId, body: data }, { onSuccess: () => navigate(`/${org}/tasks`) })
  }

  return (
    <div>
      {selectedTask && selectedTask.description && (
        <div className='grid grid-cols-2 mb-2'>
          <p className='text-gray-400'>{t('UserTasks.description')}</p>
          <p>{selectedTask.description}</p>
        </div>
      )}
      <div>
        {formFields && !isTaskCompleting && (
          <FormViewer
            ref={formRef}
            data={savedTaskData?.data ? { ...savedTaskData?.data, ...variablesData } : variablesData}
            readOnly={
              !selectedTask?.assigneeId ||
              (selectedTask?.assigneeId && !['User', 'InstanceStarter'].includes(selectedTask?.assigneeType))
            }
            schema={{
              ...formFields,
              components: formFields.components.map((f) => {
                if (f.type.includes('file')) {
                  return {
                    ...f,
                    organization: org,
                  }
                }
                if (f.type.includes('dynamiclist')) {
                  return {
                    ...f,
                    components: f.components.map((component) => {
                      if (component.type.includes('file')) {
                        return {
                          ...component,
                          organization: org,
                        }
                      } else return component
                    }),
                  }
                }
                return f
              }),
            }}
            onSubmit={(data) => {
              handleComplete(data)
              deleteTaskDataFromLocalStorge()
            }}
            onDeescalate={handleDeescalate}
            onSave={(data) => {
              saveTaskDataToLocalStorage(data)
            }}
          />
        )}
      </div>
      {selectedTask?.assigneeId && ['User', 'InstanceStarter'].includes(selectedTask?.assigneeType) && (
        <div className='flex items-center gap-x-4 mt-8'>
          <Button
            onClick={() => {
              formRef.current?.saveForm()
            }}
            className='border border-blue-500 text-blue-500 flex items-center gap-x-2'
          >
            {t('UserTasks.save')}
          </Button>
          <Button
            className={
              !selectedTask?.assigneeId || isTaskCompleting ? ' text-slate-400 bg-slate-100' : 'text-white bg-sky-500'
            }
            onClick={() => {
              if (!formFields) {
                handleComplete({})
                return
              }
              formRef.current?.submitForm()
            }}
            loading={isTaskCompleting}
            disabled={!selectedTask?.assigneeId || isTaskCompleting || isFormLoading || isFormFetching}
          >
            {t('UserTasks.complete')}
          </Button>
          {selectedTask?.assigneeId != selectedTask.escalationAssigneeId && !selectedTask.isEscalated && (
            <Button
              className='text-sky-500 cursor-pointer'
              loading={isTaskEscalating}
              onClick={() => taskEscalate({ id: selectedId }, { onSuccess: () => navigate(`/${org}/tasks`) })}
            >
              {t('UserTasks.escalate')}
            </Button>
          )}
          {selectedTask?.assigneeId === selectedTask.escalationAssigneeId && selectedTask.isEscalated && (
            <Button
              className='text-sky-500 cursor-pointer'
              loading={isTaskDeescalating}
              onClick={
                () => formRef.current?.deescalateTask()
                // taskDeescalate({ id: selectedId, body: savedTaskData }, { onSuccess: () => navigate(`/${org}/tasks`) })
              }
            >
              {t('UserTasks.deescalate')}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default TaskForm
